import styles from './index.module.sass';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';

export function AddButton(props: IAddButton) {
  return (
    <div
      className=''
      onClick={
        props.onClick ||
        (() => {
          return;
        })
      }>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="168"
        height="168"
        viewBox="0 0 168 168"
        fill="none">
        <path
          d="M84 63V105M105 84H63M147 84C147 118.794 118.794 147 84 147C49.2061 147 21 118.794 21 84C21 49.2061 49.2061 21 84 21C118.794 21 147 49.2061 147 84Z"
          stroke="#66B28A"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

interface IAddButton {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
