import { LabelCollapse } from '@/shoppingCart/components/shoppingCart/components/labelCollapse/label-collapse';
import { Collapse, CollapseProps, FormInstance } from 'antd';
import { ContactsForm } from './contacts-form.components';
import { IUserProfile } from '@/shared';
interface IProps {
  profile: IUserProfile;
  form: FormInstance<any>;
  activeKeys: {
    orderContacts: boolean;
  };
  setActiveKeys: React.Dispatch<
    React.SetStateAction<{
      orderContacts: boolean;
      legalInfo: boolean;
      deliveryInfo: boolean;
    }>
  >;
}
export const OrderContacts = ({
  profile,
  form,
  activeKeys,
  setActiveKeys,
}: IProps) => {
  const collapseItems: CollapseProps['items'] = [
    {
      key: 1,
      label: <LabelCollapse title={'Контактные данные'} />,
      children: (
        <div className="flex gap-[10px]">
          <ContactsForm form={form} profile={profile} />
        </div>
      ),
    },
  ];
  const collapseButton = (isActive: boolean | undefined) => (
    <div
      style={{
        color: '#007E3C',
        position: 'relative',
        right: '20px',
        top: '20px',
      }}>
      {isActive ? 'Свернуть' : 'Развернуть'}
    </div>
  );
  return (
    <Collapse
      style={{
        boxSizing: 'border-box',
        borderRadius: '24px',
      }}
      onChange={(key) =>
        setActiveKeys((prev) => ({ ...prev, orderContacts: !!key[1] || false }))
      }
      activeKey={[activeKeys.orderContacts ? 1 : -1]}
      expandIcon={(props) => collapseButton(props!.isActive)}
      className="custom-collapse"
      expandIconPosition="end"
      items={collapseItems}
    />
  );
};
