import { TEST_URL } from '@/api/config/api.config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface AuthResponse {
  isValid: boolean;
  email: string;
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: TEST_URL,
    credentials: 'include',
  }),
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    auth: builder.query<AuthResponse, string>({
      query: (session) => ({
        url: '/auth/auth',
        headers: { 'Guest-Uuid': session },
      }),
      providesTags: (result, error, session) => [{ type: 'Auth', id: session }],
    }),
    checkAuthUser: builder.mutation<AuthResponse, string>({
      query: (session) => ({
        url: '/auth/validate_token',
        method: 'PATCH',
        mode: 'cors',
      }),
      invalidatesTags: ['Auth'],
    }),
    makeAuthUser: builder.mutation<AuthResponse, string>({
      query: (session) => ({
        url: '/auth/auth',
        method: 'POST',
        mode: 'cors',
        headers: { 'Guest-Uuid': session },
      }),
      invalidatesTags: ['Auth'],
    }),
  }),
});

export const {
  useAuthQuery,
  useCheckAuthUserMutation,
  useMakeAuthUserMutation,
} = authApi;
