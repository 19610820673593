import { Modal } from 'antd';
import ExpertModeImg from '@/configurator/images/expert-mode.jpg'
import DefaultModeImg from '@/configurator/images/default-mode.jpg'
import { Button } from '../shared';
import { useNavigate } from 'react-router-dom';

export const ModeSelectModal = ({ visible, setVisible, activePickerId}: IModeSelectModal) => {
  const navigate = useNavigate();
  return (
    <Modal
      width={1760}
      title="Выбирайте необходимые опции для сельхоз техники в соответствии с вашими агропроцессами"
      onCancel={() => setVisible(false)}
      open={visible}
      className=""
      footer={[]}>
        <div className='modal__inner flex'>
            <div className='modal__col w-1/2 p-[32px] flex flex-col gap-[25px]'>
                <h4 className='text-[#292E3A] text-[20px] font-bold'>Стандартный режим <span className='text-[16px] font-normal'>(для тех, кто впервые подбирает опции)</span></h4>
                <img className='rounded-xl' src={DefaultModeImg} alt="mode-img"/>
                <Button onClick={() => { activePickerId > 0 && navigate(`/feed/${activePickerId}?mode=default`) }} className='inline-block max-w-[318px] px-[32px] py-[16px]'>перейти в стандартный режим</Button>
            </div>
            <div className='modal__col w-1/2 p-[32px] flex flex-col gap-[25px]'>
                <h4 className='text-[#292E3A] text-[20px] font-bold'>Режим эксперта <span className='text-[16px] font-normal'>(если Вы знаете какие опции Вы хотите добавить)</span></h4>
                <img className='rounded-xl' src={ExpertModeImg} alt="mode-img"/>
                <Button onClick={() => { activePickerId > 0 && navigate(`/feed/${activePickerId}?mode=expert`) }} className='inline-block max-w-[318px] px-[32px] py-[16px]'>перейти в режим эксперта</Button>
            </div>
        </div>
      </Modal>
  );
};

interface IModeSelectModal {
    visible: boolean
    setVisible: (val: boolean) => void; 
    activePickerId: number,
}
