export const SquaresPlus = ({
  stroke = '#292E3A',
  className,
}: {
  stroke?: string;
  className?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    className={className}>
    <path
      d="M13.5 17.375H16.875M16.875 17.375H20.25M16.875 17.375V14M16.875 17.375V20.75M6 11H8.25C9.49264 11 10.5 9.99264 10.5 8.75V6.5C10.5 5.25736 9.49264 4.25 8.25 4.25H6C4.75736 4.25 3.75 5.25736 3.75 6.5V8.75C3.75 9.99264 4.75736 11 6 11ZM6 20.75H8.25C9.49264 20.75 10.5 19.7426 10.5 18.5V16.25C10.5 15.0074 9.49264 14 8.25 14H6C4.75736 14 3.75 15.0074 3.75 16.25V18.5C3.75 19.7426 4.75736 20.75 6 20.75ZM15.75 11H18C19.2426 11 20.25 9.99264 20.25 8.75V6.5C20.25 5.25736 19.2426 4.25 18 4.25H15.75C14.5074 4.25 13.5 5.25736 13.5 6.5V8.75C13.5 9.99264 14.5074 11 15.75 11Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
