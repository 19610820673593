import { ThemeConfig } from 'antd';
import {
  BG_BASE_COLOR,
  BG_CONTAINER_COLOR,
  PRIMARY_COLOR,
  FONT_SIZE,
  TEXT_COLOR,
  TEXT_SECONDARY_COLOR,
  TEXT_DESCRIPTION_COLOR,
  PADDING_CONTENT_HORIZONTAL,
  PADDING_CONTENT_VERTICAL,
  BORDER_RADIUS,
  BORDER_COLOR,
  BORDER_SECONDARY_COLOR,
} from '@/common/config/variables.config';

export const ANTD_CONFIG_PROVIDER_THEME: ThemeConfig = {
  token: {
    colorPrimary: PRIMARY_COLOR,
    colorBgBase: BG_BASE_COLOR,
    colorBgContainer: BG_CONTAINER_COLOR,

    fontSize: FONT_SIZE,

    colorText: TEXT_COLOR,
    colorTextSecondary: TEXT_SECONDARY_COLOR,
    colorTextDescription: TEXT_DESCRIPTION_COLOR,

    paddingContentHorizontal: PADDING_CONTENT_HORIZONTAL,
    paddingContentVertical: PADDING_CONTENT_VERTICAL,

    borderRadius: BORDER_RADIUS,
    colorBorder: BORDER_COLOR,
    colorBorderSecondary: BORDER_SECONDARY_COLOR,
  },
  components: {
    Table: {
      headerBg: 'transparent',
      rowHoverBg: '#000'
    },
  },
};
