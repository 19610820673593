import { Card, notification } from 'antd';
import cn from 'classnames';
// import styles from '@/configurator/components/techniks/AddTech/index.module.sass';
import styles from './index.module.sass';
import { AddButton } from '@/configurator/components/techniks/AddButton';
import { catalogDialogState } from '@/configurator/model';
import { ISelectedBunker } from '@/configurator/ts/catalog.types';
import { SelectedBunkerCard } from '@/configurator/components/techniks/SelectedBunkerCard';
import { useEffect } from 'react';
import {
  useRemoveBunkerMutation,
  useUpdateQuantityMutation,
} from '@/services/picker.service';
import { DiContainer } from '@/di/di-container';
import { ClientStorageService } from '@/common/services/client-storage.service';
const clientStorageService = DiContainer.get(ClientStorageService);

export function CatalogCard({
  technique,
  removeFoo,
  refetch,
  pickerLoading,
  displaySelectModeFn,
  setActivePickerId,
  setActiveCard,
  activeCard,
  idx,
}: ICatalogCard) {
  const [updateQuantity, { isError, isLoading: isSetCountLoading }] =
    useUpdateQuantityMutation();
    
  const [
    removeBunker,
    { isError: isRemoveBunkerError, isLoading: isRemoveBunkerLoading },
  ] = useRemoveBunkerMutation();

  useEffect(() => {
    isError ||
      (isRemoveBunkerError &&
        notification.warning({ message: 'Упс! Что-то пошло не так!' }));
  }, [isError, isRemoveBunkerError]);

  const changeCount = async (val: number) => {
    if (technique) {
      if (val <= 0) {
        await removeBunker({
          id: technique.id,
          session: clientStorageService.getUUID(),
        });

        refetch && (await refetch());
        return;
      }
      await updateQuantity({
        id: technique.id,
        quantity: val,
        session: clientStorageService.getUUID(),
      });
      refetch && (await refetch());
    }
  };

  return (
    <>
      {technique ? (
        <Card className="border-[2px] border-[#DFE6FA]">
          <SelectedBunkerCard
            idx={idx}
            technique={technique}
            uniqueId={technique.id}
            count={technique.quantity}
            setCount={changeCount}
            countLoading={isSetCountLoading}
            bunker={technique.bunker}
            removeFoo={removeFoo}
            image={technique.bunker_image_url}
            isInCart={technique.in_shopping_cart}
            configured={technique.configured}
            canConfigured={technique.can_configured}
            displaySelectModeFn={displaySelectModeFn}
            setActivePickerId={setActivePickerId}
          />
        </Card>
      ) : (
        <Card className={cn(styles.root, styles.flex, styles.center)}>
          <div
            onClick={() => catalogDialogState(true)}
            className={
              cn(styles.flex, styles.center) + ' transition active:opacity-75'
            }>
            <AddButton />
            <div className={styles.addText + ' select-none'}>
              Добавить технику
            </div>
          </div>
        </Card>
      )}
    </>
  );
}

interface ICatalogCard {
  technique?: ISelectedBunker;
  removeFoo?: (id: number, uuid: string) => void;
  refetch?: () => void;
  updateQuantityFoo?: (id: number, quantity: number, uuid: string) => void;
  pickerLoading?: boolean;
  displaySelectModeFn?: () => void;
  setActivePickerId?: (val: number) => void;
  activeCard?: number;
  setActiveCard?: (val: number) => void;
  idx: number;
}
