import { DefaultLayout } from '@/common/components/DefaultLayout';
import { PrimaryLayoutHeader } from '@/common/components/PrimaryLayout';
import { Feed } from '@/feed/components/feed';
import { Layout } from 'antd';

export const FeedView = () => {
  return (
    <Layout.Content
      className="container min-h-full"
      // style={{ maxWidth: '1680px', width: '100%', margin: '0 auto' }}
    >
      <Feed />
    </Layout.Content>
  );
};
