import { injectable } from 'inversify';
import 'reflect-metadata';

import { IGlobalModalProps } from '@/modals/ts';
import { closeModal, openModal } from '@/modals/store/global-modal.store';

@injectable()
export class ModalService {
  open<Props extends IGlobalModalProps>(
    component: (props: Props) => JSX.Element,
    props: Omit<Props, 'open' | 'onCancel'>
  ) {
    // @ts-ignore
    openModal({ component, props });
  }

  close() {
    closeModal();
  }
}
