import { IUserLegal } from '@/shared';
import { Collapse, FormInstance } from 'antd';
import getCollapseItems from './getCollapseItems';

type IProps = {
  legal: IUserLegal;
  form: FormInstance<any>;
  activeKeys: {
    legalInfo: boolean;
  };
  setActiveKeys: React.Dispatch<
    React.SetStateAction<{
      orderContacts: boolean;
      legalInfo: boolean;
      deliveryInfo: boolean;
    }>
  >;
};

export const LegalInformation = ({
  legal,
  form,
  activeKeys,
  setActiveKeys,
}: IProps) => {
  const collapseItems = getCollapseItems({ legal, form });

  const collapseButton = (isActive: boolean | undefined) => (
    <div
      style={{
        color: '#007E3C',
        position: 'relative',
        right: '20px',
        top: '20px',
      }}>
      {isActive ? 'Свернуть' : 'Развернуть'}
    </div>
  );
  return (
    <Collapse
      style={{
        boxSizing: 'border-box',
        borderRadius: '24px',
        border: '1px solid #AAB3CB',
      }}
      onChange={(key) =>
        setActiveKeys((prev) => ({ ...prev, legalInfo: !!key[1] || false }))
      }
      activeKey={[activeKeys.legalInfo ? 1 : -1]}
      expandIcon={(props) => collapseButton(props!.isActive)}
      expandIconPosition="end"
      bordered={false}
      defaultActiveKey={[1]}
      className="legal-collapse"
      items={collapseItems}
    />
  );
};
