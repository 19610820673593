import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BASE_URL,
  TEST_URL,
  // DEFAULT_HEADERS,
  // DEFAULT_TIMEOUT,
} from '@/api/config/api.config';
import { RootState } from './store';

export const pickerApi = createApi({
  reducerPath: 'pickerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: TEST_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      const isAuthorized = (getState() as RootState).auth.isAuthorized;
      if (isAuthorized) {
        headers.delete('Guest-Uuid');
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPickerDetails: builder.query({
      query: (session) => ({
        url: '/pickers',
        headers: { 'Guest-Uuid': session },
      }),
    }),
    addBunker: builder.mutation({
      query: ({ bunker_id, quantity, session }) => ({
        url: '/pickers/add',
        headers: { 'Guest-Uuid': session },
        method: 'POST',
        body: {
          data: {
            bunker_id,
            quantity,
          },
        },
      }),
    }),
    bulkAddBunker: builder.mutation({
      query: ({ body, session }) => ({
        url: '/pickers/bulk_add',
        headers: { 'Guest-Uuid': session },
        method: 'POST',
        body,
      }),
    }),
    removeBunker: builder.mutation({
      query: ({ id, session }) => ({
        url: `/pickers/${id}/remove`,
        headers: { 'Guest-Uuid': session },
        method: 'DELETE',
      }),
    }),
    updateQuantity: builder.mutation({
      query: ({ id, quantity, session }) => ({
        url: `/pickers/${id}/update_quantity`,
        headers: { 'Guest-Uuid': session },
        body: {
          data: { quantity },
        },
        method: 'PATCH',
      }),
    }),
    setConfiguration: builder.mutation({
      query: ({
        session,
        data,
      }: {
        session: string;
        data: {
          picker_option_id: number;
          configuration_item_id: number;
          enabled: boolean;
        }[];
      }) => ({
        url: `/configuration_steps/apply`,
        headers: { 'Guest-Uuid': session },
        method: 'POST',
        body: {
          data: data.map((d) => ({
            ...d,
            price_as_main_criteria:
              localStorage.getItem('moneyMoney') === 'true',
          })),
        },
      }),
    }),
    markBunkerAsConfigured: builder.mutation({
      query: ({ id, configured, session }) => ({
        url: `/pickers/${id}/configured`,
        headers: { 'Guest-Uuid': session },
        body: {
          data: { configured },
        },
        method: 'PATCH',
      }),
    }),
    applyConfiguration: builder.mutation({
      query: ({ session, id }) => ({
        url: `/pickers/${id}/find_modifications`,
        headers: { 'Guest-Uuid': session },
        method: 'PATCH',
      }),
    }),
  }),
});

export const {
  useAddBunkerMutation,
  useGetPickerDetailsQuery,
  useBulkAddBunkerMutation,
  useRemoveBunkerMutation,
  useUpdateQuantityMutation,
  useSetConfigurationMutation,
  useMarkBunkerAsConfiguredMutation,
  useApplyConfigurationMutation,
} = pickerApi;
