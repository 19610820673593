import { IShoppingCartItem } from '@/shared';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OrderState {
  orderItems: IShoppingCartItem[] | [];
}

const initialState: OrderState = {
  orderItems: JSON.parse(localStorage.getItem('order') || '[]'),
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderItems(state, action: PayloadAction<IShoppingCartItem[]>) {
      state.orderItems = action.payload;
      localStorage.setItem('order', JSON.stringify(state.orderItems));
    },
    clearOrderItems(state) {
      state.orderItems = [];
      localStorage.setItem('order', JSON.stringify([]));
    },
  },
});

export const { setOrderItems, clearOrderItems } = orderSlice.actions;
export default orderSlice.reducer;
