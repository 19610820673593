import { ClientStorageService } from '@/common/services/client-storage.service';
import { ICurrentConfigItem, IOptionsData } from '../ts';
import { IInitialStepState } from '../model';
import { Modal } from 'antd';

export const handleApplyConfig = async ({
  setMode,
  configItems,
  currentConfig,
  optionsData,
  dataSource,
  clientStorageService,
  setConfiguration,
  stepData,
  setSelectedStep,
  setCurrentStep,
  setActiveConfigItem,
  closeFn,
  activeConfigItem,
  refetch,
  getNextStepItems,
}: IHandleApplyConfig) => {
  // подготовить данные
  let dataToSend: any[] = [];
  if (
    (configItems.data.items[configItems.data.items.length - 1] as any) ===
    currentConfig()
  ) {
    // если данная категория последняя, берем всю матрицу для применения
    const result = dataSource.map((item: any) => {
      const selectedKeys = Object.keys(item).filter((key) =>
        key.endsWith('val')
      );
      const selectedObject = selectedKeys.reduce((obj: any, key: string) => {
        obj[key.replaceAll('_val', '')] = item[key];
        return obj;
      }, {});

      return Object.keys(selectedObject).map((key, i) => ({
        picker_option_id: item.picker_option_id,
        configuration_item_id: optionsData?.data?.items?.find(
          (subItem: any) => subItem.name === key
        )?.configuration_item_id,
        enabled: selectedObject[key],
      }));
    });
    dataToSend = result.flat();
  } else {
    dataSource.map((item: any, i: number) => {
      dataToSend.push({
        picker_option_id: item.picker_option_id,
        configuration_item_id: currentConfig()?.configuration_item_id,
        enabled: dataSource[i][currentConfig()?.name + '_val'],
      });
    });
  }

  // применить опции
  if (dataToSend.length !== 0 && configItems?.data?.items?.length !== 0) {
    // если категория пуста
    const finalRes: any = await setConfiguration({
      session: clientStorageService.getUUID(),
      data: dataToSend,
    });
    if ('error' in finalRes) return;
  } else {
    if (stepData.current_step < stepData.steps_count) {
      setSelectedStep(stepData.selected_step + 1);
      setCurrentStep(stepData.current_step + 1);
      setActiveConfigItem(1);
      return;
    }
  }

  // обработать ошибки - (в useEffect прописал)
  // переключить на след опцию, если таковой нет, перейти на след раздел
  if (configItems?.data?.items?.length !== activeConfigItem) {
    setActiveConfigItem(Number(activeConfigItem) + 1);
  } else {
    if (stepData.current_step < stepData.steps_count) {
      const { data } = await getNextStepItems(stepData.current_step + 1);
      if (data?.data?.items.length === 0) {
        // если в следующем шаге конфигруации нет опций
        Modal.confirm({
          title: 'Подтвердите действие',
          content:
            'Следующий шаг конфигурации содержит ранее выбранные опции, хотите пропустить его?',
          okText: 'да',
          cancelText: 'нет',
          onOk: () => {
            setSelectedStep(stepData.selected_step + 2);
            setCurrentStep(stepData.current_step + 2);
            setActiveConfigItem(1);
          },
        });
        return;
      }
      setSelectedStep(stepData.selected_step + 1);
      setCurrentStep(stepData.current_step + 1);
      setActiveConfigItem(1);
    } else if (
      stepData.current_step === stepData.steps_count &&
      stepData.selected_step === stepData.steps_count
    ) {
      Modal.confirm({
        title: 'Подтвердите действие',
        content:
          'Далее будет открыто окно с результатом вашего выбора, где вы сможете уточнить ваш выбор перед созданием заказа.',
        okText: 'продолжить',
        cancelText: 'отмена',
        onOk: async () => {
          setMode('expert');
          setActiveConfigItem(1);
          setSelectedStep(1);
          setCurrentStep(1);
          refetch && (await refetch());
          return;
        },
      });
    }
  }

  // закрыть модалку
  closeFn();

  // обновить данные
  refetch();
  return;
};

interface IHandleApplyConfig {
  setMode: (value: 'expert' | 'default') => void;
  configItems: IOptionsData;
  currentConfig: () => ICurrentConfigItem;
  optionsData: IOptionsData;
  dataSource: Array<any>;
  clientStorageService: ClientStorageService;
  setConfiguration: (arg: any) => Promise<any>;
  stepData: IInitialStepState;
  setSelectedStep: (val: number) => void;
  setCurrentStep: (val: number) => void;
  setActiveConfigItem: (val: number) => void;
  closeFn: () => void;
  activeConfigItem: number;
  refetch: () => Promise<unknown>;
  getNextStepItems: (id: number) => Promise<any>;
}
