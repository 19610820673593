import { FormInstance } from 'antd';
import { useWatch } from 'antd/es/form/Form';

const useFormValues = (form: FormInstance) => {
  const formAddressLine = useWatch('address_line', form);
  const formCity = useWatch('city', form);
  const formRegion = useWatch('region', form);
  const formPhone = useWatch('phone', form);
  const formEmail = useWatch('email', form);
  const formFio = useWatch('fio', form);
  const deliveryBy = useWatch('deliveryBy', {
    form,
    preserve: true,
  });

  return {
    city: formCity,
    address_line: formAddressLine,
    region: formRegion,
    deliveryBy,
    phone: formPhone,
    email: formEmail,
    fio: formFio,
  };
};

export { useFormValues }
