import { TEST_URL } from '@/api/config/api.config';
import { IUserAddress } from '@/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface ISuccessResponse {
  success: boolean;
}

interface IAddressesResponse {
  data: { id: string } & IUserAddress['attributes'];
}

interface IAddressRequest {
  session: string;
  id: string;
  legalId: string;
}
interface IAddAddressRequest {
  session: string;
  data: {
    data: IUserAddress['attributes'];
  };
  legalId: string;
}

interface IUpdateAddressRequest {
  session: string;
  data: {
    data: IUserAddress['attributes'];
  };
  id: string;
  legalId: string;
}

export const addressApi = createApi({
  reducerPath: 'addressApi',
  baseQuery: fetchBaseQuery({ baseUrl: TEST_URL, credentials: 'include' }),
  tagTypes: ['Address'],
  endpoints: (builder) => ({
    createNewAddress: builder.mutation<IAddressesResponse, IAddAddressRequest>({
      query: ({ session, data, legalId }) => ({
        url: '/addresses',
        headers: { 'Guest-Uuid': session },
        credentials: 'include',
        method: 'POST',
        params: {
          customerable_id: legalId,
          customerable_type: 'Legal',
        },
        body: data,
      }),
    }),
    updateAddress: builder.mutation<IAddressesResponse, IUpdateAddressRequest>({
      query: ({ session, data, id, legalId }) => ({
        url: `/addresses/${id}`,
        headers: { 'Guest-Uuid': session },
        credentials: 'include',
        method: 'PATCH',
        params: {
          customerable_id: legalId,
          customerable_type: 'Legal',
        },
        body: data,
      }),
    }),
    deleteAddress: builder.mutation<ISuccessResponse, IAddressRequest>({
      query: ({ session, id, legalId }) => ({
        url: `/addresses/${id}`,
        headers: { 'Guest-Uuid': session },
        credentials: 'include',
        params: {
          customerable_id: legalId,
          customerable_type: 'Legal',
        },
        method: 'DELETE',
      }),
    }),
    saveCurrentAddress: builder.mutation<ISuccessResponse, IAddressRequest>({
      query: ({ session, id, legalId }) => ({
        url: `/addresses/${id}/save_as_current`,
        headers: { 'Guest-Uuid': session },
        params: {
          customerable_id: legalId,
          customerable_type: 'Legal',
        },
        credentials: 'include',
        method: 'PATCH',
      }),
    }),
  }),
});

export const {
  useCreateNewAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation,
  useSaveCurrentAddressMutation,
} = addressApi;
