import { ClientStorageService } from '@/common/services/client-storage.service';
import { ICurrentConfigItem, IOptionItem, IOptionsData } from '../ts';
import { IInitialStepState } from '../model';
import { Modal } from 'antd';
import { NavigateFunction } from 'react-router-dom';
import { IDataToSendValue } from '../ts/picker.types';
import { ILastApplyResponse, Suggestion } from '@/configurator/ts';

export const handleApplyExpert = async ({
  switchToNextStep,
  step,
  configItems,
  optionsData,
  dataSource,
  clientStorageService,
  setConfiguration,
  stepData,
  setSelectedStep,
  setCurrentStep,
  setActiveConfigItem,
  closeFn,
  refetch,
  getNextStepItems,
  markBunkerAsConfigured,
  applyConfiguration,
  pickerId,
  navigate,
  setSuggestions,
  save,
}: IHandleApplyExpert) => {
  let dataToSend: IDataToSendValue[] = [];
  const result = dataSource.map((item) => {
    const selectedKeys = Object.keys(item).filter((key) => key.endsWith('val'));
    const selectedObject = selectedKeys.reduce((obj: any, key: string) => {
      obj[key.replaceAll('_val', '')] = item[key];
      return obj;
    }, {});

    return Object.keys(selectedObject).map((key, i) => ({
      picker_option_id: item.picker_option_id,
      configuration_item_id: optionsData?.data?.items?.find(
        (subItem: IOptionItem) => subItem.name === key
      )?.configuration_item_id,
      enabled: selectedObject[key],
    }));
  });
  dataToSend = result.flat();

  const handleFinishConfiguration = async () => {
    setSuggestions([]);
    await applyConfiguration({
      id: pickerId,
      session: clientStorageService.getUUID(),
    });
    await markBunkerAsConfigured({
      id: pickerId,
      configured: true,
      session: clientStorageService.getUUID(),
    }).then((res) => {
      if (res.data.status === 'success') {
        navigate('/configurator');
      }
    });
  };

  // применить опции
  if (dataToSend.length !== 0 && configItems?.data?.items?.length !== 0) {
    if (save) {
      dataToSend.forEach((item) => (item.last = true));
    }

    const finalRes: ILastApplyResponse = await setConfiguration({
      session: clientStorageService.getUUID(),
      data: dataToSend,
    });

    if (finalRes?.data?.data[0]?.suggestions?.length) {
      setSuggestions(finalRes.data.data[0].suggestions);
      return;
    }

    if ('error' in finalRes) return;
  } else {
    if (stepData.current_step < stepData.steps_count && switchToNextStep) {
      setSelectedStep(stepData.selected_step + 1);
      setCurrentStep(stepData.current_step + 1);
      setActiveConfigItem(1);
      return;
    }
  }

  // обработать ошибки - (в useEffect прописал)
  if (stepData.current_step < stepData.steps_count && !save) {
    const { data } = await getNextStepItems(
      step !== null && step >= 0 ? step + 1 : stepData.current_step + 1
    );
    if (data?.data?.items.length === 0) {
      // если в следующем шаге конфигруации нет опций
      Modal.confirm({
        title: 'Подтвердите действие',
        content:
          'Следующий шаг конфигурации содержит ранее выбранные опции, хотите пропустить его?',
        okText: 'да',
        cancelText: 'нет',
        onOk: () => {
          setSelectedStep(stepData.selected_step + 2);
          setCurrentStep(stepData.current_step + 2);
          setActiveConfigItem(1);
        },
      });
      return;
    }
    if (!save && switchToNextStep) {
      setSelectedStep(stepData.selected_step + 1);
      setCurrentStep(stepData.current_step + 1);
      setActiveConfigItem(1);
    }
  }

  if (save) {
    Modal.confirm({
      title: 'Подтвердите действие',
      content: 'Хотите закончить и сохранить конфигурацию?',
      okText: 'да',
      cancelText: 'нет',
      onOk: handleFinishConfiguration,
    });
  }

  // закрыть модалку
  closeFn();

  // обновить данные
  refetch();
  return;
};

interface IHandleApplyExpert {
  switchToNextStep?: boolean;
  step: number | null;
  pickerId: string | number | undefined;
  configItems: IOptionsData;
  currentConfig: () => ICurrentConfigItem;
  optionsData: IOptionsData;
  dataSource: Record<string, any>[];
  clientStorageService: ClientStorageService;
  setConfiguration: (arg: any) => Promise<any>;
  markBunkerAsConfigured: (arg: any) => Promise<any>;
  applyConfiguration: (arg: any) => Promise<any>;
  stepData: IInitialStepState;
  setSelectedStep: (val: number) => void;
  setCurrentStep: (val: number) => void;
  setActiveConfigItem: (val: number) => void;
  closeFn: () => void;
  activeConfigItem: number;
  refetch: () => Promise<unknown>;
  getNextStepItems: (id: number) => Promise<any>;
  navigate: NavigateFunction;
  setSuggestions: (val: Suggestion[]) => void;
  save?: boolean;
}
