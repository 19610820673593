import { Modal, notification } from 'antd';
import { IStep, StepsBar } from '../steps-bar';
import { MatrixTable } from './components';
import { Button } from '@/configurator/components/shared';
import {
  IInitialStepState,
  setCurrentStep,
  setSelectedStep,
} from '@/feed/model';
import {
  useGetConfigurationStepsQuery,
  useGetNextStepItemsMutation,
} from '@/services/configurator.service';
import { DiContainer } from '@/di/di-container';
import { ClientStorageService } from '@/common/services/client-storage.service';
import { ISelectedBunker } from '@/configurator/ts/catalog.types';
import { useSetConfigurationMutation } from '@/services/picker.service';
import { useEffect, useState } from 'react';
import { ICurrentConfigItem, IOptionsData } from '@/feed/ts';
import { handleApplyConfig } from '@/feed/functions';

const clientStorageService = DiContainer.get(ClientStorageService);

export const MatrixModal = ({
  id,
  stepData,
  open,
  closeFn,
  steps,
  currentItem,
  currentConfig,
  activeConfigItem,
  setActiveConfigItem,
  optionsData,
  setMode,
}: IMatrixModal) => {
  const [setConfiguration, { isError, isSuccess }] =
    useSetConfigurationMutation();
  const [optionPrice, setOptionPrice] = useState(0);
  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const [dataSource, setDataSource] = useState<any[]>([]);

  useEffect(() => {
    const optionCost = currentConfig()?.current_cost;
    setSelectedItemsCount(
      dataSource
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((item: any) => item[currentConfig()?.name + '_val'])
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .reduce((acc: number, item: any) => (item === true ? acc + 1 : acc), 0)
    );

    setOptionPrice(selectedItemsCount * optionCost);
  }, [currentConfig, dataSource, selectedItemsCount]);

  const {
    data: configItems,
    refetch,
    isLoading,
  } = useGetConfigurationStepsQuery({
    session: clientStorageService.getUUID(),
    category_id: stepData?.selected_step,
    picker_id: id,
  });

  const [getConfigItemsFoo] = useGetNextStepItemsMutation();

  const getNextStepItems = async (catId: number) => {
    const res = await getConfigItemsFoo({
      session: clientStorageService.getUUID(),
      category_id: catId,
      picker_id: id,
    });
    return res;
  };

  const handleApply = () =>
    handleApplyConfig({
      setMode,
      configItems,
      currentConfig,
      optionsData,
      dataSource,
      clientStorageService,
      setConfiguration,
      stepData,
      setSelectedStep,
      setCurrentStep,
      setActiveConfigItem,
      closeFn,
      activeConfigItem,
      refetch,
      getNextStepItems,
    });

  useEffect(() => {
    if (isError) {
      notification.error({
        message: 'Применение опции не удалось, попробуйте позже',
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Данные внесены успешно',
      });
    }
  }, [isSuccess]);

  const setStep = async (i: number) => {
    setActiveConfigItem(1);
    setSelectedStep(i + 1);
    await refetch();
  };

  return (
    <Modal
      closable={true}
      open={open}
      footer={null}
      onCancel={closeFn}
      width={1440}>
      <StepsBar
        onChange={setStep}
        className="mt-[25px] mb-[15px] border-solid"
        steps={steps}
        mode="default"
        refetch={refetch}
      />
      <MatrixTable
        allowExpertLevel={false}
        data={configItems}
        stepData={stepData}
        currentItem={currentItem}
        currentConfig={currentConfig}
        refetch={refetch}
        isApplyOptionLoading={isLoading}
        dataSource={dataSource}
        setDataSource={setDataSource as (newVal: unknown) => void}
        optionsData={optionsData}
        activeConfigItem={activeConfigItem}
        setActiveConfigItem={setActiveConfigItem}
      />
      <div className="modal__controls flex items-center justify-end gap-[25px]">
        <div className="controls__col self-start">
          <span className="block text-[14px] font-light h-[40px]">
            количество выбранной техники
            <br />
            (в рамках данной опции):
          </span>
          <span className="block text-[18px] font-semibold">
            {selectedItemsCount} шт
          </span>
        </div>
        <div className="controls__col self-start">
          <span className="block text-[14px] font-light h-[40px]">
            итого за данную опцию:
          </span>
          <span className="block text-[18px] font-semibold">
            {isNaN(optionPrice) ? '0' : optionPrice.toLocaleString('RU-ru')} ₽
          </span>
        </div>
        <div className="controls__col">
          <Button
            className="bg-[#66B28A]/[.9999] active:bg-[#66B28A]/[.8]"
            // eslint-disable-next-line react/no-children-prop
            children={`${stepData.current_step === stepData.steps_count ? 'сохранить конфигурацию' : 'продолжить'}`}
            onClick={() => handleApply()}
          />
        </div>
      </div>
    </Modal>
  );
};

interface IMatrixModal {
  open: boolean;
  setMode: (value: 'expert' | 'default') => void;
  closeFn: () => void;
  id?: number | string;
  stepData: IInitialStepState;
  steps: IStep[];
  currentItem: ISelectedBunker | null;
  currentConfig: () => ICurrentConfigItem;
  activeConfigItem: number;
  setActiveConfigItem: (value: React.SetStateAction<number>) => void;
  optionsData: IOptionsData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSource: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDataSource: (val: any) => void;
}
