import { Card, Modal } from 'antd';
import { Button } from '@shared/index';
import {
  $sourceDialogModal,
  catalogDialogState,
  closeSourceModal,
} from '@/configurator/model';
import { useStore } from 'effector-react';
import styles from './index.module.sass';
import { default as ManualImage } from './images/manual.png';
import TactsImage from './images/TACTS.png';

export function SourceDialog() {
  const modalState = useStore<boolean>($sourceDialogModal);

  const goManual = () => {
    sessionStorage.setItem('sourceDialog', 'true');
    catalogDialogState(true);
    closeSourceModal();
  };
  //TODO: карточки передалть на компонент SourceType
  const handleCancel = () => {
    closeSourceModal();
  };

  return (
    <div className="source-dialog-modal">
      <Modal
        width={'70%'}
        closable={false}
        title={
          <p className="font-bold text-[24px]">
            Вы знаете какую модель выбрать?
          </p>
        }
        open={modalState}
        onCancel={handleCancel}
        className="source-dialog-modal"
        footer={[]}>
        <div className={styles.content} style={{ flexDirection: 'row' }}>
          <Card
            className={styles.card}
            style={{
              minHeight: '65vh',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
            cover={<img src={ManualImage} />}>
            <div className={styles.body}>
              <b className={styles.bold}>Каталог техники ООО “Лилиани”</b> (если Вы
              знаете требуемую модель сельскохозяйственной техники)
            </div>
            <div className={styles.simpleText}>
              Каталог включает в себя весь перечень сельскохозяйственной
              техники, предлагаемой нашей компанией,с возможностью выбора
              базовой модели и дальнейшей ее конфигурации, персонально для ваших
              агропроцессов.
            </div>
            <Button className={styles.button} onClick={goManual}>
              Перейти в каталог техники
            </Button>
          </Card>
          <Card
            className={styles.card}
            style={{
              minHeight: '65vh',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
            cover={<img src={TactsImage} />}>
            <div className={styles.body}>
              <b className={styles.bold}>Сервис “Подборщик сельскохозяйственной техники”</b> (для
              тех, кому нужна помощь с выбором)
            </div>
            <div className={styles.simpleText}>
              Сервис “Подборщик” помогает подобрать сельскохозяйственную технику
              ООО “Лилиани” с учетом ваших задач и парка уже имеющейся у вас
              сельскохозяйственной техники.
            </div>
            <Button
              className={styles.button}
              onClick={() => {
                window.location.assign('http://selection.liliani.ru/tacts/1');
              }}>
              Перейти в сервис &quot;Подборщик&quot;
            </Button>
          </Card>
        </div>
      </Modal>
    </div>
  );
}
