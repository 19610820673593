import { useStore } from 'effector-react';
import { StepsItem } from './components';
import { $stepStore } from '@/feed/model';

export const StepsBar = ({
  steps,
  className,
  onChange,
  mode,
}: {
  steps: IStep[];
  className?: string;
  onChange?: (i: number) => void;
  mode: 'expert' | 'default';
  refetch?: () => void;
}) => {
  const stepData = useStore($stepStore);
  return (
    <ul
      className={`flex rounded-lg overflow-hidden border border-[#EEF2FD] bg-white h-[73px] ${className}`}>
      {steps?.map((item, i) => (
        <StepsItem
          item={item}
          i={i}
          key={i}
          steps={steps}
          onChange={onChange}
          stepData={stepData}
          mode={mode}
        />
      ))}
    </ul>
  );
};

export interface IStep {
  active?: boolean;
  label: string;
  state: 'completed' | 'current' | 'next';
}
