export const TotalItem = ({ title, img }: ITotalItem) => {
    return (<div className="total__item max-w-[240px]">
        <span className="font-semibold block mb-[10px]">{ title }</span>
        <img className="max-w-full" src={img}/>
    </div>);
  };

  interface ITotalItem {
    title: string,
    img: string
  }

