import { Layout, LayoutProps } from 'antd';

export function DefaultLayout(props: IDefaultLayoutProps) {
  const { children } = props;

  return (
    // <Div100vh>
    <Layout className="bg-[#EEF2FD] min-h-full">{children}</Layout>
    // </Div100vh>
  );
}

export type IDefaultLayoutProps = LayoutProps;
