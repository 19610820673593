import { IUserLegal } from '@/shared/types';
import { Form, Row, Col, Input, FormInstance } from 'antd';

interface IProps {
  legal?: IUserLegal;
  form: FormInstance<any>;
}
const LegalForm = ({ form, legal }: IProps) => {
  return (
    <Form
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 24 }}
      layout="horizontal">
      <Row gutter={[16, 0]}>
        <Col className="form-input" span={12}>
          <Form.Item
            labelCol={{ span: 24 }}
            name="name"
            initialValue={legal?.attributes?.name}
            required={false}
            validateDebounce={1000}
            rules={[
              {
                whitespace: true,
                type: 'string',
                required: true,
                message: 'Наименование организации обязательное поле',
              },
            ]}
            label="Наименование организации">
            <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
          </Form.Item>
        </Col>
        <Col className="form-input" span={12}>
          <Form.Item
            name="actual_address"
            initialValue={legal?.attributes?.actual_address}
            required={false}
            labelCol={{ span: 24 }}
            validateDebounce={1000}
            rules={[
              {
                whitespace: true,
                type: 'string',
                required: true,
                message: 'Почтовый адрес обязательное поле',
              },
            ]}
            label="Почтовый адрес">
            <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
          </Form.Item>
        </Col>
        <Col className="form-input" span={12}>
          <Form.Item
            name="ogrn"
            initialValue={legal?.attributes?.ogrn}
            required={false}
            labelCol={{ span: 24 }}
            validateDebounce={1000}
            rules={[
              {
                required: true,
                message: 'ОГРН обязательное поле',
              },
              {
                type: 'integer',
                transform: (value) => Number(value),
                message: 'ОГРН должен содержать только цифры',
              },
              {
                min: 13,
                max: 13,
                message: 'ОГРН должен содержать 13 цифр',
              },
              {
                whitespace: false,
                message: 'ОГРН не должен содержать пробелы',
              },
            ]}
            label="ОГРН">
            <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
          </Form.Item>
        </Col>
        <Col className="form-input" span={12}>
          <Form.Item
            name="inn"
            initialValue={legal?.attributes?.inn}
            required={false}
            labelCol={{ span: 24 }}
            validateDebounce={1000}
            rules={[
              {
                required: true,
                message: 'ИНН обязательное поле',
              },
              {
                type: 'integer',
                transform: (value) => Number(value),
                message: 'ИНН должен содержать только цифры',
              },
              {
                min: 12,
                max: 12,
                message: 'ИНН должен содержать 12 цифр',
              },
              {
                whitespace: false,
                message: 'ИНН не должен содержать пробелы',
              },
            ]}
            label="ИНН">
            <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
          </Form.Item>
        </Col>
        <Col className="form-input" span={12}>
          <Form.Item
            name="bik"
            initialValue={legal?.attributes?.bik}
            required={false}
            labelCol={{ span: 24 }}
            validateDebounce={1000}
            rules={[
              {
                required: true,
                message: 'БИК обязательное поле',
              },
              {
                type: 'integer',
                transform: (value) => Number(value),
                message: 'БИК должен содержать только цифры',
              },
              {
                min: 9,
                max: 9,
                message: 'БИК должен содержать 9 цифр',
              },
              {
                whitespace: false,
                message: 'БИК не должен содержать пробелы',
              },
            ]}
            label="БИК">
            <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
          </Form.Item>
        </Col>
        <Col className="form-input" span={12}>
          <Form.Item
            name="kpp"
            initialValue={legal?.attributes?.kpp}
            required={false}
            labelCol={{ span: 24 }}
            validateDebounce={1000}
            rules={[
              {
                required: true,
                message: 'КПП обязательное поле',
              },
              {
                type: 'integer',
                transform: (value) => Number(value),
                message: 'КПП должен содержать только цифры',
              },
              {
                min: 9,
                max: 9,
                message: 'КПП должен содержать 9 цифр',
              },
              {
                whitespace: false,
                message: 'КПП не должен содержать пробелы',
              },
            ]}
            label="КПП">
            <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
          </Form.Item>
        </Col>
        <Col className="form-input" span={12}>
          <Form.Item
            name="corr_account_number"
            initialValue={legal?.attributes?.corr_account_number}
            required={false}
            labelCol={{ span: 24 }}
            validateDebounce={1000}
            rules={[
              {
                required: true,
                message: 'К/С обязательное поле',
              },
              {
                type: 'integer',
                transform: (value) => Number(value),
                message: 'К/С должен содержать только цифры',
              },
              {
                min: 20,
                max: 20,
                message: 'К/С должен содержать 20 цифр',
              },
              {
                whitespace: false,
                message: 'К/С не должен содержать пробелы',
              },
            ]}
            label="К/С">
            <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
          </Form.Item>
        </Col>
        <Col className="form-input" span={12}>
          <Form.Item
            name="okpo"
            initialValue={legal?.attributes?.okpo}
            required={false}
            labelCol={{ span: 24 }}
            validateDebounce={1000}
            rules={[
              {
                required: true,
                message: 'ОКПО обязательное поле',
              },
              {
                type: 'integer',
                transform: (value) => Number(value),
                message: 'ОКПО должен содержать только цифры',
              },
              {
                min: 8,
                max: 10,
                message: 'ОКПО должен содержать 8 или 10 цифр',
              },
              {
                whitespace: false,
                message: 'ОКПО не должен содержать пробелы',
              },
            ]}
            label="ОКПО">
            <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="account_number"
        initialValue={legal?.attributes?.account_number}
        required={false}
        validateDebounce={1000}
        rules={[
          {
            required: true,
            message: 'Р/С обязательное поле',
          },
          {
            type: 'integer',
            transform: (value) => Number(value),
            message: 'Р/С должен содержать только цифры',
          },
          {
            min: 20,
            max: 20,
            message: 'Р/С должен содержать 20 цифр',
          },
          {
            whitespace: true,
            message: 'Р/С не должен содержать пробелы',
          },
        ]}
        labelCol={{ span: 24 }}
        label="Р/С">
        <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
      </Form.Item>
    </Form>
  );
};

export default LegalForm;
