import { Col, Form, FormInstance, Input, Row } from 'antd';
import './contacts-form.components.scss';
import { IUserProfile, emailRegexp, phoneNumberRegexp } from '@/shared';
import { MaskedInput } from 'antd-mask-input';
interface IProps {
  profile: IUserProfile;
  form: FormInstance<any>;
}

export const ContactsForm = ({ profile, form }: IProps) => {
  return (
    <Form
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 24 }}
      layout="horizontal">
      <Row gutter={[16, 16]}>
        <Col className="form-input" span={12}>
          <Form.Item
            initialValue={profile.data.attributes.phone || ''}
            labelCol={{ span: 24 }}
            name="phone"
            validateDebounce={1000}
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
                min: 11,
                transform: (value: string) => value.replace(/\D/g, ''),
              },
            ]}
            required={false}
            label="Контактный телефон">
            <MaskedInput
              mask={'+7(000)000-00-00'}
              className="form-input-text bg-[#EEF2FD] h-[56px]"
            />
          </Form.Item>
        </Col>
        <Col className="form-input" span={12}>
          <Form.Item
            initialValue={profile.data.attributes.email || ''}
            name="email"
            validateDebounce={1000}
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
                pattern: emailRegexp,
              },
            ]}
            required={false}
            labelCol={{ span: 24 }}
            label="e-mail">
            <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
          </Form.Item>
        </Col>
        <Col className="form-input" span={12}>
          <Form.Item
            initialValue={profile.data.attributes.company || ''}
            labelCol={{ span: 24 }}
            validateDebounce={1000}
            rules={[{ required: true, message: 'Обязательное поле' }]}
            required={false}
            name="fio"
            label="ФИО">
            <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
