import { ReactNode } from 'react';
import gryzovik from './gryzovik.png';
import declined from './declined.svg';
const orderStatus = [
  { status: 'заказ доставлен', date: '11.01.2023', icon: 'check' },
  { status: 'заказ отгружен', date: '02.01.2023', icon: 'truck' },
  { status: 'заказ готов к отгрузке', date: '27.12.2022', icon: 'check' },
  { status: 'заказ оплачен', date: '27.12.2022', icon: 'check' },
  { status: 'заказ принят', date: '25.12.2022', icon: 'check' },
  { status: 'заказ оформлен', date: '25.12.2022', icon: 'check' },
];
const createdOrderStatus = [
  { status: 'заказ оформлен', date: '25.12.2022', icon: 'check' },
];

interface IProps {
  status: string;
}

const icons: Record<string, ReactNode> = {
  truck: <img className="icon-gryz" src={gryzovik}></img>,
  check: <span className="icon-check">&#10003;</span>,
  declined: <img className="icon-declined" src={declined}></img>,
};

export const OrderStatus = ({ status }: IProps) => {
  let statusList;

  if (status !== 'created') {
    statusList = createdOrderStatus;
  } else {
    statusList = orderStatus;
  }
  return (
    <div className="order-status-container flex">
      <div className="flex flex-col ">
        <div className="test"></div>
        <div
          style={{
            border: '1px solid rgba(223, 230, 250, 1)',
            height: '100%',
            width: '1px',
            marginTop: '-8px',
            marginLeft: '3px',
          }}></div>
      </div>
      <div className="status-list pt-8">
        {statusList.map((item, index) => (
          <div className="status-item" key={index}>
            <div>{icons[item.icon] || icons.check}</div>
            <div className="details">
              <div className="status">{item.status}</div>
              <div className="date">{item.date}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
