import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BASE_URL,
  TEST_URL,
  // DEFAULT_HEADERS,
  // DEFAULT_TIMEOUT,
} from '@/api/config/api.config';
import { IOrder } from '@/shared';

export interface IOrdersResponse {
  data: IOrder[];
}

export const ordersListApi = createApi({
  reducerPath: 'ordersListApi',
  baseQuery: fetchBaseQuery({ baseUrl: TEST_URL }),
  endpoints: (builder) => ({
    getOrders: builder.query<IOrdersResponse, string>({
      query: (session) => ({
        url: '/orders',
        headers: { 'Guest-Uuid': session },
        credentials: 'include',
      }),
    }),
  }),
});

export const { useGetOrdersQuery } = ordersListApi;
