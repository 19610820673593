import { useState } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '@/services/store';

type Props = {
  titleAuth: string;
  titleNotAuth: string;
  okAuth: string;
  okNotAuth: string;
  redirectPath?: string;
};

export const useNotAuthModal = ({
  titleAuth,
  titleNotAuth,
  okAuth,
  okNotAuth,
  redirectPath = window.location.href,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isAuthorized } = useSelector((state: RootState) => state.auth);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    if (!isAuthorized) {
      window.location.assign(
        `https://sso.liliani.ru/login?client_id=99bee193-5fa9-4c51-8d79-4b62f2e1f74d&redirect_url=${window.location.origin + redirectPath}`
      );
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return {
    openNotAuthModal: showModal,
    isAuthorized,
    NotAuthModal: () => (
      <Modal
        title={isAuthorized ? titleAuth : titleNotAuth}
        open={isModalOpen}
        onOk={handleOk}
        cancelText="Закрыть"
        okText={isAuthorized ? okAuth : okNotAuth}
        onCancel={handleCancel}>
        <p>
          Для того, чтобы сформировать заказ необходимо войти в аккаунт.
          <br />
        </p>
      </Modal>
    ),
  };
};
