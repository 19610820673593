import { IUserAddress } from '@/shared';
import { Col, Form, Input, Modal, notification } from 'antd';
import { useState } from 'react';

interface IProps {
  onClose: () => void;
  address?: IUserAddress;
  onCreate: (formValues: IUserAddress['attributes']) => void;
  onUpdate: (formValues: IUserAddress['attributes'], id: string) => void;
}

export const AddAddressModal = ({
  onCreate,
  onUpdate,
  onClose,
  address,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onSaveForm = async () => {
    try {
      await form.validateFields();
    } catch (e: any) {
      notification.error({
        message: 'Заполните обязательные поля',
      });
      form.scrollToField(e.errorFields[0].name[0], {
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }
    setLoading(true);
    const formValues = form.getFieldsValue();
    if (address) {
      onUpdate(formValues, address.id);
    } else {
      onCreate(formValues);
    }
  };
  return (
    <Modal
      title="Добавить адрес доставки"
      open
      onOk={onSaveForm}
      okButtonProps={{ loading }}
      cancelText="Закрыть"
      okText="Сохранить"
      onCancel={onClose}>
      <div className="bg-[#ffffff] mt-[12px] p-[16px] rounded-[16px] border-solid border-[2px] border-[#DFE6FA]">
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          layout="horizontal">
          <Col className="form-input" span={12}>
            <Form.Item
              initialValue={address?.attributes?.region}
              labelCol={{ span: 24 }}
              name="region"
              required={false}
              validateDebounce={1000}
              rules={[
                {
                  required: true,
                  message: 'Обязательное поле',
                  type: 'string',
                  whitespace: true,
                },
              ]}
              label="Область">
              <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
            </Form.Item>
          </Col>
          <Col className="form-input" span={12}>
            <Form.Item
              initialValue={address?.attributes?.city}
              labelCol={{ span: 24 }}
              name="city"
              required={false}
              validateDebounce={1000}
              rules={[
                {
                  required: true,
                  message: 'Обязательное поле',
                  type: 'string',
                  whitespace: true,
                },
              ]}
              label="Город">
              <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
            </Form.Item>
          </Col>
          <Col className="form-input" span={12}>
            <Form.Item
              initialValue={address?.attributes?.address_line}
              labelCol={{ span: 24 }}
              name="address_line"
              required={false}
              validateDebounce={1000}
              rules={[
                {
                  required: true,
                  message: 'Обязательное поле',
                  type: 'string',
                  whitespace: true,
                },
              ]}
              label="Адрес доставки">
              <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
            </Form.Item>
          </Col>
          <Col className="form-input" span={12}>
            <Form.Item
              initialValue={address?.attributes?.comments}
              labelCol={{ span: 24 }}
              name="comments"
              required={false}
              validateDebounce={1000}
              rules={[
                {
                  required: false,
                  type: 'string',
                  whitespace: true,
                },
              ]}
              label="Комментарий">
              <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
            </Form.Item>
          </Col>
        </Form>
      </div>
    </Modal>
  );
};
