import { Modal } from 'antd';
import { ReactNode } from 'react';

export const OptionModal = ({
  closeFn,
  title,
  visible,
  children,
  approveFn,
  declineFn,
  buttonLabel,
}: IOptionModal) => {
  return (
    <Modal
      open={visible}
      onCancel={closeFn}
      footer={null}
      title={title}
      width={1440}>
      {children}
      
      <div className="bottom-controls mt-[10px] flex justify-center items-center">
        { declineFn && <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            closeFn();
            declineFn && declineFn();

          }}
          className="p-[16px] inline-block rounded-[10px] hover:text-black border-solid border-[#DFE6FA] border-[2px] mr-[22px] transition active:opacity-50">
          не использовать{' '}
        </a>}
        { approveFn && <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            approveFn && approveFn();
          }}
          className="p-[16px] inline-block rounded-[10px] bg-[#66B28A] text-white hover:text-white transition active:opacity-75">
          использовать {buttonLabel ? buttonLabel.toLowerCase() : title.toLowerCase()}
        </a>}
      </div>
    </Modal>
  );
};

interface IOptionModal {
  closeFn: () => void;
  title: string;
  visible: boolean;
  children: ReactNode;
  approveFn?: () => void;
  declineFn?: () => void;
  buttonLabel?: string;
}
