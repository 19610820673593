import { TEST_URL } from '@/api/config/api.config';
import { IUserLegal } from '@/shared';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from './store';

interface ISuccessResponse {
  success: boolean;
}

interface ICreateLegalsResponse {
  data: { id: string } & IUserLegal['attributes'];
}

interface IUpdateLegalsResponse {
  data: IUserLegal['attributes'];
}

interface ILegalRequest {
  session: string;
  id: string;
}
interface IAddLegalsRequest {
  session: string;
  data: {
    data: IUserLegal['attributes'];
  };
}
interface IUpdateLegalRequest {
  session: string;
  data: IUserLegal;
  id: string;
}

export const legalsApi = createApi({
  reducerPath: 'legalsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: TEST_URL,
    prepareHeaders: (headers, { getState }) => {
      const isAuthorized = (getState() as RootState).auth.isAuthorized;
      if (isAuthorized) {
        headers.delete('Guest-Uuid');
      }
      return headers;
    },
  }),
  tagTypes: ['Legals'],
  endpoints: (builder) => ({
    createNewLegal: builder.mutation<ICreateLegalsResponse, IAddLegalsRequest>({
      query: ({ session, data }) => ({
        url: '/legals',
        headers: { 'Guest-Uuid': session },
        credentials: 'include',
        method: 'POST',
        body: data,
      }),
    }),
    updateLegal: builder.mutation<IUpdateLegalsResponse, IUpdateLegalRequest>({
      query: ({ session, data, id }) => ({
        url: `/legals/${id}`,
        headers: { 'Guest-Uuid': session },
        credentials: 'include',
        method: 'PATCH',
        body: {
          data: data,
        },
      }),
    }),
    deleteLegal: builder.mutation<ISuccessResponse, ILegalRequest>({
      query: ({ session, id }) => ({
        url: `/legals/${id}`,
        headers: { 'Guest-Uuid': session },
        credentials: 'include',
        method: 'DELETE',
      }),
    }),
    saveCurrentLegal: builder.mutation<ISuccessResponse, ILegalRequest>({
      query: ({ session, id }) => ({
        url: `/legals/${id}/save_as_current`,
        headers: { 'Guest-Uuid': session },
        credentials: 'include',
        method: 'PATCH',
      }),
    }),
  }),
});

export const {
  useCreateNewLegalMutation,
  useUpdateLegalMutation,
  useDeleteLegalMutation,
  useSaveCurrentLegalMutation,
} = legalsApi;
