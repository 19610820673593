import React from 'react';
import ReactDOM from 'react-dom/client';
import { attachLogger } from 'effector-logger';
import { App } from './App';
import '@/common/styles/index.styles.sass';
import './assets/index.css';
import { store } from '@/services/store';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

// attachLogger();

Sentry.init({
  dsn: 'https://7a092105cc728684d0efa2d3e22c8028@o4505527900897280.ingest.sentry.io/4506396034203648',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>
// );
