interface IProps {
    bunkerModificationDescription:string;
    priceOfModification?:string;
}

export const BunkerModification = ({bunkerModificationDescription, priceOfModification }: IProps) => {
    return (
       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>{bunkerModificationDescription}</div>
        <div style={{paddingLeft:'10px'}}>{priceOfModification}</div>
       </div>
    );
};