import { LabelCollapse } from '@/shoppingCart/components/shoppingCart/components/labelCollapse/label-collapse';
import {
  Col,
  Collapse,
  CollapseProps,
  Divider,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
} from 'antd';
import './index.scss';
import { IUserAddress } from '@/shared';

interface IProps {
  address: IUserAddress;
  form: FormInstance<any>;
  activeKeys: {
    deliveryInfo: boolean;
  };
  setActiveKeys: React.Dispatch<
    React.SetStateAction<{
      orderContacts: boolean;
      legalInfo: boolean;
      deliveryInfo: boolean;
    }>
  >;
}
export const DeliveryAndReceipt = ({
  address,
  form,
  activeKeys,
  setActiveKeys,
}: IProps) => {
  const collapseItems: CollapseProps['items'] = [
    {
      key: 1,
      label: <LabelCollapse title={'Доставка и  получение'} />,
      children: (
        <div className="flex flex-col gap-[35px]">
          <Radio.Group
            style={{
              width: '100%',
              display: 'flex',
            }}
            defaultValue="a"
            buttonStyle="solid">
            <Radio.Button
              className="radio-button"
              value="a"
              onClick={() => {
                form.setFieldValue('deliveryBy', 'manufacturer');
              }}>
              <div className="radio-button-text">Доставка производителем</div>
            </Radio.Button>
            <Radio.Button
              className="radio-button"
              value="c"
              onClick={() => {
                form.setFieldValue('deliveryBy', 'pickup');
              }}>
              <div className="radio-button-text">Самовывоз</div>
            </Radio.Button>
          </Radio.Group>

          <Divider style={{ margin: '0px' }} />
          <div className="text-s">Адрес доставки</div>
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 24 }}
            layout="horizontal">
            <Row gutter={[16, 0]}>
              <Col className="form-input" span={12}>
                <Form.Item
                  initialValue={address?.attributes?.region}
                  labelCol={{ span: 24 }}
                  name="region"
                  required={false}
                  validateDebounce={1000}
                  rules={[
                    {
                      required: true,
                      message: 'Обязательное поле',
                      type: 'string',
                      whitespace: true,
                    },
                  ]}
                  label="Область">
                  <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
                </Form.Item>
              </Col>
              <Col className="form-input" span={12}>
                <Form.Item
                  initialValue={address?.attributes?.city}
                  labelCol={{ span: 24 }}
                  name="city"
                  required={false}
                  validateDebounce={1000}
                  rules={[
                    {
                      required: true,
                      message: 'Обязательное поле',
                      type: 'string',
                      whitespace: true,
                    },
                  ]}
                  label="Город">
                  <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
                </Form.Item>
              </Col>
              <Col className="form-input" span={12}>
                <Form.Item
                  initialValue={address?.attributes?.address_line}
                  labelCol={{ span: 24 }}
                  name="address_line"
                  required={false}
                  validateDebounce={1000}
                  rules={[
                    {
                      required: true,
                      message: 'Обязательное поле',
                      type: 'string',
                      whitespace: true,
                    },
                  ]}
                  label="Адрес доставки">
                  <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
                </Form.Item>
              </Col>
              <Col className="form-input" span={12}>
                <Form.Item
                  initialValue={address?.attributes?.comments}
                  labelCol={{ span: 24 }}
                  name="comments"
                  required={false}
                  validateDebounce={1000}
                  rules={[
                    {
                      required: false,
                      type: 'string',
                      whitespace: true,
                    },
                  ]}
                  label="Комментарий">
                  <Input className="form-input-text bg-[#EEF2FD] h-[56px]" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Divider style={{ margin: '0px' }} />
          {/* <div className="text-s">Дата доставки</div>
          <div className="flex flex-col gap-[35px]">
            <div className="text-[#7E89A5]">Ближайшая дата доставки</div>
            <DeliveryDate />
          </div> */}
          <div>
            <div>Менеджер вам позвонит для подтверждения даты. </div>
            <div>
              В зависимости от загрузки перевозчика дата может быть изменена.
            </div>
          </div>
        </div>
      ),
    },
  ];
  const collapseButton = (isActive: boolean | undefined) => (
    <div
      style={{
        color: '#007E3C',
        position: 'relative',
        right: '20px',
        top: '20px',
      }}>
      {isActive ? 'Свернуть' : 'Развернуть'}
    </div>
  );
  return (
    <Collapse
      style={{
        boxSizing: 'border-box',
        borderRadius: '24px',
      }}
      onChange={(key) =>
        setActiveKeys((prev) => ({ ...prev, deliveryInfo: !!key[1] || false }))
      }
      activeKey={[activeKeys.deliveryInfo ? 1 : -1]}
      expandIcon={(props) => collapseButton(props!.isActive)}
      defaultActiveKey={[1]}
      className="custom-collapse"
      expandIconPosition="end"
      items={collapseItems}
    />
  );
};
