import { useNavigate } from 'react-router-dom';
import {
  IShoppingCartItem,
  IShoppingCartStep,
} from '@/shared/types/shopping-cart.types';
import { ClientStorageService } from '@/common/services/client-storage.service';
import { DiContainer } from '@/di/di-container';
import {
  useDeleteBunkerFromStackMutation,
  useDeleteStackFromCartMutation,
  useGetShoppingCartDetailsQuery,
} from '@/shoppingCart/services/shopping-cart.service';
import { ModificationCard } from '@/shared';
import { useMarkBunkerAsConfiguredMutation } from '@/services/picker.service';

interface IProps {
  bunker: IShoppingCartStep;
  modification: IShoppingCartItem;
  btnDisabled?: boolean;
}

const clientStorageService = DiContainer.get(ClientStorageService);

export const ChildrenCollapse = ({
  bunker,
  modification,
  btnDisabled,
}: IProps) => {
  const { refetch } = useGetShoppingCartDetailsQuery(
    clientStorageService.getUUID()
  );

  const [markBunkerAsConfigured] = useMarkBunkerAsConfiguredMutation();

  const [deleteBunkerFromStack] = useDeleteBunkerFromStackMutation();

  const [clearSelectedStack] = useDeleteStackFromCartMutation();

  const navigate = useNavigate();

  const onDeleteBunker = () => {
    if (btnDisabled) return;
    if (modification.items.length === 1) {
      clearSelectedStack({
        id: modification.picker_id.toString(),
        session: clientStorageService.getUUID(),
      }).then(() => navigate('/configurator'));
    }
    deleteBunkerFromStack({
      id: bunker.id.toString(),
      session: clientStorageService.getUUID(),
    });
    refetch();
  };

  const onChangeConfigurationClick = async () => {
    if (btnDisabled) return;
    await markBunkerAsConfigured({
      id: modification.picker_id.toString(),
      session: clientStorageService.getUUID(),
      configured: false,
    });
    await clearSelectedStack({
      id: modification.picker_id.toString(),
      session: clientStorageService.getUUID(),
    });
    navigate(`/feed/${modification.picker_id}?mode=expert`);
  };

  const footerExtraElements = (
    <div className="flex space-x-3">
      <div
        onClick={onChangeConfigurationClick}
        className={`font-bold text-[#007E3C] cursor-pointer active:opacity-75 select-none ${btnDisabled && 'opacity-40'}`}>
        изменить конфигурацию
      </div>
      <div
        onClick={onDeleteBunker}
        className={`font-bold text-[#F1898E] cursor-pointer active:opacity-75 select-none ${btnDisabled && 'opacity-40'}`}>
        удалить
      </div>
    </div>
  );

  return (
    <ModificationCard
      bunker={bunker}
      footerExtraOptions={footerExtraElements}
    />
  );
};
