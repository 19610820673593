import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $modal, closeModal } from '@/modals/store/global-modal.store';

export function GlobalModalController() {
  const { component, props } = useStore($modal);
  const [open, setOpen] = useState(false);

  useEffect(() => void setOpen(!!component), [component]);
  const onCancel = () => void closeModal();

  return component && component({ ...props, open, onCancel });
}
