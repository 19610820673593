import { useEffect, useState } from 'react';

import { DiContainer } from '@/di/di-container';
import { ClientStorageService } from '@/common/services/client-storage.service';
import {
  useGetPickerDetailsQuery,
  useRemoveBunkerMutation,
} from '@/services/picker.service';
import { ISelectedBunker } from '@/configurator/ts/catalog.types';

import { ModeSelectModal } from '@/configurator/components/ModeSelectModal/index.component';
import { Col, Row, Spin, notification } from 'antd';
import { PublicOffer } from '@/configurator/components/PublicOffer';
import { SourceDialog } from '@/configurator/components/SourceDialog';
import { Catalog } from '@/configurator/components/Catalog/index.component';
import { CatalogCard } from '@/configurator/components/techniks/CatalogCard';
import { useDeleteStacksFromCartMutation } from '@/shoppingCart/services/shopping-cart.service';

const clientStorageService = DiContainer.get(ClientStorageService);

export function ConfiguratorView() {
  const uuid = clientStorageService.getUUID();
  const {
    data: picker,
    refetch,
    isFetching,
    isLoading,
  } = useGetPickerDetailsQuery(uuid);
  const [removeBunker, { error: removeBunkerError }] =
    useRemoveBunkerMutation();
  const [clearSelectedStacks] = useDeleteStacksFromCartMutation();

  const [selectModeVisible, setSelectModeVisible] = useState(false);
  const [activePickerId, setActivePickerId] = useState(-1);
  const [activeCard, setActiveCard] = useState(-1);

  useEffect(() => {
    if (removeBunkerError) {
      notification.warning({
        message: 'При удалении позиции возникла ошибка!',
      });
    }
  }, [removeBunkerError]);

  const removeFoo = async (id: number, uuid: string) => {
    await clearSelectedStacks({ids: [String(id)], session: uuid});
    await removeBunker({ id, session: uuid });
    await refetch();
  };

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) {
    return (
      <Spin
        className="flex h-full justify-center items-center"
        spinning={true}
        size="large"></Spin>
    );
  }

  return (
    <div className="container">
      <Row gutter={[16, 24]} className="flex-stretch">
        {picker?.data?.map((bunker: ISelectedBunker) => (
          <Col key={bunker.id} className="gutter-row" span={8}>
            <CatalogCard
              idx={bunker.id}
              technique={bunker}
              removeFoo={removeFoo}
              refetch={refetch}
              pickerLoading={isFetching}
              displaySelectModeFn={() => setSelectModeVisible(true)}
              setActivePickerId={setActivePickerId}
              activeCard={activeCard}
              setActiveCard={setActiveCard}
            />
          </Col>
        ))}
        <Col className="gutter-row" span={8}>
          <CatalogCard idx={0} />
        </Col>
      </Row>
      <ModeSelectModal
        visible={selectModeVisible}
        setVisible={setSelectModeVisible}
        activePickerId={activePickerId}
      />
      <PublicOffer />
      <SourceDialog />
      {/* <AttentionDialog /> */}
      <Catalog />
    </div>
  );
}
