enum UserFieldType {
  LEGAL = 'legal',
  ADDRESS = 'address',
  USER = 'user',
}

interface IUserAddress {
  id: string;
  type: UserFieldType.ADDRESS;
  attributes: {
    region: string;
    city: string;
    address_line: string;
    comments: string | null;
    current: boolean;
  };
}

interface IUserLegal {
  id: string;
  type: UserFieldType.LEGAL;
  attributes: {
    name: string;
    actual_address: string;
    ogrn: string;
    inn: string;
    bik: string;
    kpp: string;
    corr_account_number: string;
    okpo: string;
    account_number: string;
    current: boolean;
  };
  relationships: {
    addresses: {
      data: Pick<IUserAddress, 'id' | 'type'>[];
    };
  };
}

interface IUserProfile {
  data: {
    id: string;
    type: UserFieldType.USER;
    attributes: {
      email: string;
      phone: string | null;
      company: string | null;
      uuid: string;
    };
    relationships: {
      addresses: {
        data: Pick<IUserAddress, 'id' | 'type'>[];
      };
      legals: {
        data: Pick<IUserLegal, 'id' | 'type'>[];
      };
    };
  };
  included: (IUserAddress | IUserLegal)[];
}

export { UserFieldType, type IUserAddress, type IUserLegal, type IUserProfile };
