import { Button } from 'antd';
import React, { useRef, useState } from 'react';
import { ArrowUpOutlined } from '@ant-design/icons';

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);
  const rootEl = useRef(document.getElementById('root')!);
  const toggleVisible = () => {
    const scrolled = rootEl.current.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    rootEl.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  rootEl.current.addEventListener('scroll', toggleVisible);

  return (
    <Button
      style={{
        position: 'fixed',
        width: '40px',
        right: '2%',
        borderRadius: '50%',
        bottom: '120px',
        height: '40px',
        zIndex: '1000',
        cursor: 'pointer',
        color: 'green',
        display: visible ? 'block' : 'none',
      }}
      onClick={scrollToTop}>
      <ArrowUpOutlined
        style={{
          fontSize: '20px',
          marginLeft: '-8px',
          marginTop: '3px',
        }}
      />
    </Button>
  );
};

export default ScrollToTopButton;
