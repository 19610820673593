export const OrderIcon = ({
  stroke = '#292E3A',
  className,
}: {
  stroke?: string;
  className?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    className={className}
    fill="none">
    <path
      d="M11.3495 4.33619C11.2848 4.54602 11.25 4.76894 11.25 5C11.25 5.41421 11.5858 5.75 12 5.75H16.5C16.9142 5.75 17.25 5.41421 17.25 5C17.25 4.76894 17.2152 4.54602 17.1505 4.33619M11.3495 4.33619C11.6328 3.41757 12.4884 2.75 13.5 2.75H15C16.0116 2.75 16.8672 3.41757 17.1505 4.33619M11.3495 4.33619C10.9739 4.35858 10.5994 4.38529 10.2261 4.41627C9.09499 4.51015 8.25 5.47324 8.25 6.60822V8.75M17.1505 4.33619C17.5261 4.35858 17.9006 4.38529 18.2739 4.41627C19.405 4.51015 20.25 5.47324 20.25 6.60822V17C20.25 18.2426 19.2426 19.25 18 19.25H15.75M8.25 8.75H4.875C4.25368 8.75 3.75 9.25368 3.75 9.875V21.125C3.75 21.7463 4.25368 22.25 4.875 22.25H14.625C15.2463 22.25 15.75 21.7463 15.75 21.125V19.25M8.25 8.75H14.625C15.2463 8.75 15.75 9.25368 15.75 9.875V19.25M7.5 16.25L9 17.75L12 14"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
