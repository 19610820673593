import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ClientStorageService } from '@/common/services/client-storage.service';
import { DiContainer } from '@/di/di-container';

import { Button, Col, Row, Spin, Steps, Tabs, Typography, notification } from 'antd';
import { PrimaryLayout } from '@/common/components/PrimaryLayout';

import './index.styles.scss';
import { useGetOrdersQuery } from '../services';
import { Order } from '../components';

const clientStorageService = DiContainer.get(ClientStorageService);

export const OrdersListView: FC = () => {
  const session = clientStorageService.getUUID();
  const navigate = useNavigate();
  //   TODO: переделать на выбранные из стора
  const {
    data: orders,
    isLoading,
    isFetching,
    refetch,
  } = useGetOrdersQuery(session);
  const activeOrders = orders?.data.filter(
    (order) => order.status === 'created'
  );
  const ordersHistory = orders?.data.filter(
    (order) => order.status !== 'created'
  );

  useEffect(() => {
    if (orders?.data.length === 0 && !isLoading && !isFetching) {
      notification.warning({
        message:
          'У вас нет активных заказов. Вы будете перенаправлены на страницу конфигурирования.',
      });
      setTimeout(() => navigate('/configurator'), 4000);
    }
  }, [orders, isLoading, isFetching]);

  useEffect(() => {
    refetch();
  }, []);

  const items = [
    {
      key: '1',
      label: 'Активные заказы',
      children: (
        <Row gutter={[32, 32]} style={{ display: 'block' }}>
          <div className="flex-column px-4">
            <div className="flex flex-wrap">
              {activeOrders &&
                activeOrders.length > 0 &&
                activeOrders.map((order) => (
                  <Col key={order.id} span={12}>
                    <Order order={order}></Order>
                  </Col>
                ))}
            </div>
          </div>
        </Row>
      ),
    },
    {
      key: '2',
      label: 'История заказов',
      children: (
        <Row gutter={[32, 32]} style={{ display: 'block' }}>
          <div className="flex-column px-4">
            <div className="flex flex-wrap">
              {ordersHistory &&
                ordersHistory.length > 0 &&
                ordersHistory.map((order) => (
                  <Col key={order.id} span={12}>
                    <Order order={order}></Order>
                  </Col>
                ))}
            </div>
          </div>
        </Row>
      ),
    },
  ];
  return (
    <>
      {isLoading || orders?.data.length === 0 ? (
        <Spin className="centered-spinner" spinning={true} size="large"></Spin>
      ) : (
        <div className="order-list-page overflow-y-visible mx-auto max-w-[1800px] w-[90%] ">
          <Steps
            current={3}
            style={{ marginBottom: '20px', width: '70%', margin: '0 auto' }}
            items={[
              {
                title: (
                  <Typography style={{ color: '#007E3C' }}>
                    Собрать корзину
                  </Typography>
                ),
              },
              {
                title: (
                  <Typography style={{ color: '#007E3C' }}>
                    Оформить заказ
                  </Typography>
                ),
              },
              {
                title: (
                  <Typography style={{ color: '#007E3C' }}>
                    Отследить заказ
                  </Typography>
                ),
              },
            ]}
          />
          <div style={{ position: 'relative' }} className="!min-h-[90vH] flex">
            <Tabs style={{ width: '100%' }} items={items} hideAdd={false} />
            <Button
              style={{ position: 'absolute', top: '20px', right: '20px' }}
              className="my-2 bg-[#007E3C] text-white hover:bg-white"
              onClick={() => navigate('/configurator')}>
              Заказать ещё
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
