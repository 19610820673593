import { Card, Space, notification } from 'antd';
import { IBunkerData } from '@/configurator/ts';
import styles from './index.module.sass';
import fakeImage from '@/common/images/placeholder.png';
import { Counter } from '@/configurator/components/shared/counter';
import {
  useBulkAddBunkerMutation,
  useGetPickerDetailsQuery,
  useRemoveBunkerMutation,
  useUpdateQuantityMutation,
} from '@/services/picker.service';
import { ClientStorageService } from '@/common/services/client-storage.service';
import { DiContainer } from '@/di/di-container';
import { useEffect } from 'react';

const clientStorageService = DiContainer.get(ClientStorageService);

export interface IBunkerCard {
  idx: number;
  id: number;
  pickerId: number;
  model: string;
  image: string;
  capacity: number;
  load: number;
  performance: string;
  data: IBunkerData;
  count: number;
  setCount?: (id: number, count: number) => void;
}

export function BunkerCard(props: IBunkerCard) {
  const { refetch, isFetching } = useGetPickerDetailsQuery(clientStorageService.getUUID());
  const [
    updateQuantity,
    { isLoading: isUpdateQuantityLoading, isError: isUpdateQuantityError },
  ] = useUpdateQuantityMutation();
  const [
    removeBunker,
    { isLoading: isRemoveBunkerLoading, isError: isRemoveBunkerError },
  ] = useRemoveBunkerMutation();
  const [bulkAdd, { isLoading: isBulkAddLoading, isError: isBulkAddError }] =
    useBulkAddBunkerMutation();

  // const [localCount, setLocalCount] = useState(0);
  const { model, capacity, load, performance, data, count, id, pickerId } =
    props;

  const changeCount = async (val: number) => {
    // если picker не существует
    if (val <= 0) {
      await removeBunker({ id: pickerId, session: clientStorageService.getUUID() });
      refetch && await refetch();
      return;
    }
    if (pickerId === 0) {
      await bulkAdd({
        body: {
          data: [
            {
              bunker_id: id,
              quantity: val,
            },
          ],
        },
        session: clientStorageService.getUUID(),
      });
      refetch && await refetch();
      return;
    }

    // если picker существует
    await updateQuantity({
      id: pickerId,
      quantity: val,
      session: clientStorageService.getUUID(),
    });
    refetch && await refetch();
  };

  useEffect(() => {
    if (isBulkAddError || isRemoveBunkerError || isUpdateQuantityError) {
      notification.warning({
        message: 'Ошибка при изменении количества. Попробуйте позже',
      });
    }
  }, [isBulkAddError, isRemoveBunkerError, isUpdateQuantityError]);

  return (
    <Card bordered style={{ border: '3px solid #66B28A' }}>
      <div className={styles.model}>
        {model} 
      </div>
      <Space>
        <div className={styles.left}>
          <img className={styles.catalogImg} src={props.image || fakeImage} />
        </div>
        <div className={styles.right}>
          <div>
            <span className={styles.info}>Геометрический объем бункера</span>
            <p className={styles.data}>
              {capacity} м <sup>3</sup>
            </p>
          </div>
          <div>
            <span className={styles.info}> Грузоподъемность</span>
            <p className={styles.data}>{load} т</p>
          </div>
          <div>
            <span className={styles.info}>
              Производительность выгрузного шнека
            </span>
            <p className={styles.data}>{performance} т/ч</p>
          </div>
        </div>
      </Space>
      <div>
        <p className={styles.description}>
          {
            'Габаритный бункер в модельной линейке компании. За счет своих размеров он может перемещаться по дорогам общего пользования. Это также первая двухсекционная модель, что позволяет использовать ее на севе для загрузки семенами и удобрениями одновременно и впоследствии быстро загружать сеялки.'
          }
        </p>
      </div>

      <Counter
        idx={0}
        minValue={0}
        count={count}
        setCount={changeCount}
        loading={isUpdateQuantityLoading || isRemoveBunkerLoading || isBulkAddLoading}
      />
    </Card>
  );
}
