import { injectable } from 'inversify';
import 'reflect-metadata';

import { notification } from 'antd';
import { ArgsProps } from 'antd/es/notification/interface';
import { DEFAULT_NOTIFICATION_GLOBAL_CONFIG } from '@/notifications/config/index.config';

@injectable()
export class NotificationsService {
  constructor(
    private readonly antdNotification: typeof notification = notification
  ) {
    this.antdNotification.config(DEFAULT_NOTIFICATION_GLOBAL_CONFIG);
  }

  success(args: ArgsProps | string) {
    this.openByType('success', args);
  }
  error(args?: ArgsProps | string) {
    this.openByType(
      'error',
      // @ts-ignore
      typeof args === 'object' || args?.length ? args : 'Произошла ошибка'
    );
  }
  info(args: ArgsProps | string) {
    this.openByType('info', args);
  }
  warning(args: ArgsProps | string) {
    this.openByType('warning', args);
  }

  open(args: ArgsProps) {
    this.antdNotification.open(args);
  }
  destroy(key?: React.Key) {
    this.antdNotification.destroy(key);
  }

  private openByType(
    type: 'success' | 'error' | 'info' | 'warning',
    args: ArgsProps | string
  ) {
    this.antdNotification[type](
      typeof args === 'string' ? { message: args } : args
    );
  }
}
