import { OptionModal } from '../option-modal';
import imgFile from './images/slide-gates.jpg';

export const SlideGatesModal = ({
  visible,
  closeFn,
  approveFn,
  declineFn,
}: {
  visible: boolean;
  closeFn: () => void;
  approveFn?: () => void;
  declineFn?: () => void;
}) => {
  return (
    <OptionModal
      approveFn={approveFn}
      declineFn={declineFn}
      title="Гидравлические шиберные заслонки"
      closeFn={closeFn}
      visible={visible}>
      <p className="mb-[20px]">
        Шиберная заслонка включает в себя обязательный элемент –шибер подвижной
        пластины, выполненной из прочной стали, который полностью или частично
        перекрывает подающийся поток зерновой массы или других сыпучих фракций
        на горизонтальный шнек. <br /> В гидравлических шиберных заслонках зазор
        пластин шиберов изменяется с помощью гидроцилиндров, интегрированных с
        шиберами- заслонками <br /> Благодаря регулировке зазора шиберов
        гидроцилиндрами, нет необходимости выставлять зазор шиберов ключом
        вручную внутри бункера, а регулировка выгружаемого потока сыпучих
        фракций краном гидроспределителя возле наклонного шнека, дает
        возможность полностью контролировать процесс выгрузки и дозированно
        смешивать зерно по качеству и влажности, или создавать тукосмеси из
        минеральных гранулированных удобрений.
      </p>
      <img className="w-full" src={imgFile} />
    </OptionModal>
  );
};
