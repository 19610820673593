import { useEffect, useState } from 'react';

export const useReactPath = () => {
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let oldHref = document.location.href;

    const body = document.querySelector('body');
    const observer = new MutationObserver(() => {
      if (oldHref !== document.location.href) {
        oldHref = document.location.href;
        setPath(window.location.pathname);
      }
    });

    // Настраиваем observer на отслеживание изменений в DOM
    body && observer.observe(body, { childList: true, subtree: true });

    // Удаляем observer при размонтировании компонента
    return () => {
      observer.disconnect();
    };
  }, []);

  return path;
};
