import { ClientStorageService } from '@/common/services/client-storage.service';
import { ConfiguratorService } from '@/configurator/services/configurator.service';
import { DiContainer } from '@/di/di-container';
import { createEffect, createEvent, createStore, sample } from 'effector';
const configuratorService = DiContainer.get(ConfiguratorService);
const clientStorageService = DiContainer.get(ClientStorageService);

export const getConfigModules = createEvent();
export const $configurationModules = createStore({ data: [] }).on(
  getConfigModules,
  (_, newData) => {
    return newData;
  }
);

export const fetchConfigModules = createEffect(
  async (isAuthorized: boolean) => {
    const data = await configuratorService.getCategoriesList(
      clientStorageService.getUUID(),
      isAuthorized
    );
    return data?.data;
  }
);

export interface IFetchConfigModulesResponse {
  data: IConfigModule[];
}

export interface IConfigModule {
  id: number;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}

sample({
  clock: fetchConfigModules.doneData,
  target: getConfigModules,
});

// step store
export const setStepsCount = createEvent<number>();
export const setCurrentStep = createEvent<number>();
export const setSelectedStep = createEvent<number>();
export const resetStepData = createEvent();

export const initialStepState: IInitialStepState = {
  steps_count: 0,
  current_step: 1,
  selected_step: 1,
};

export interface IInitialStepState {
  steps_count: number;
  current_step: number;
  selected_step: number;
}

export const $stepStore = createStore(initialStepState);

$stepStore
  .on(setStepsCount, (state, count) => ({
    ...state,
    steps_count: count,
  }))
  .on(setCurrentStep, (state, currentStep) => ({
    ...state,
    current_step: currentStep,
  }))
  .on(setSelectedStep, (state, selectedStep) => ({
    ...state,
    selected_step: selectedStep,
    // :selectedStep > state.current_step ? state.selected_step : selectedStep,
  }))
  .on(resetStepData, () => ({
    ...initialStepState,
  }));
