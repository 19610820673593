export const ProfitCard = ({
  title,
  bunkersCount,
  profit,
  efficiency,
}: IProfitCard) => {

  function getSuffixForMillion(num: number) {
    if (num % 10 === 1 && num % 100 !== 11) {
      return 'миллион';
    } else if (
      num % 10 >= 2 &&
      num % 10 <= 4 &&
      (num % 100 < 10 || num % 100 >= 20)
    ) {
      return 'миллиона';
    } else {
      return 'миллионов';
    }
  }

  function getSuffixForBunker(num: number) {
    if (num % 10 === 1 && num % 100 !== 11) {
      return 'бункер';
    } else if (
      num % 10 >= 2 &&
      num % 10 <= 4 &&
      (num % 100 < 10 || num % 100 >= 20)
    ) {
      return 'бункера';
    } else {
      return 'бункеров';
    }
  }

  return (
    <div className="profit_card__body bg-[#EEF2FD] p-[10px] rounded-xl">
      <span className="heading block font-bold mb-[10px]">{title}</span>
      <div className="content mb-[10px]">
        <p className="text mb-[10px]">
          Урожайность – ${efficiency} ц/га <br />
          Стоимость 1й тонны урожая – 13000 ₽ <br />
          Себестоимость тонны – 25000 ₽(Все затраты понесенные на выращивание
          урожая на 1 га)
          <br />
          <span className="text-sm">
            Из расчёта, что 1 комбайн на 500 га и 1 БП на 2 комбайна
          </span>{' '}
          Плановый урожай – 15 000 т <br />
          Потери в двухзвенной технологии уборки – 1679 т <br />
          Потери в трехзвенной технологии уборки – 325 т
        </p>
        <p className="text">
          с учетом использования технологии трехзвенной уборки:
          <span className="text-sm block">
            15 000 т (Плановый урожай) – 325 т (потери) = 14 675 т * 13000 ₽
            (стоимость зерна) = 190 775 000 ₽ - 75 000 000 ₽ (затраты понесенные
            на выращивание урожая на 1 ГА) = 115 775 000 ₽
          </span>
        </p>
      </div>
      <div className="profit bg-[#B3D8C5] rounded-xl p-[10px]">
        <span className="profit__heading block text-[18px] font-semibold">
          {profit} {getSuffixForMillion(+profit)}
        </span>
        <span className="profit__subtext">
          прибыль за сезон сева ({bunkersCount} {getSuffixForBunker(+bunkersCount)})
        </span>
      </div>
    </div>
  );
};

interface IProfitCard {
  title: string;
  bunkersCount: number;
  profit: number | string;
  efficiency: number | string;
}
