import { IConfigModule, IInitialStepState } from "@/feed/model";
import { IStep } from "../index.component"; 
 
 // функция принимает данные полученные с сервера и
  // форматирует их под локальное состояние для использования в компонентах
export const reformSteps = (data: never[], stepData: IInitialStepState, setSteps: (value: React.SetStateAction<IStep[]>) => void) => {
    setSteps(
      data?.map((item: IConfigModule) => ({
        label: item.attributes.name,
        state:
          +item.id === stepData.selected_step
            ? 'current'
            : +item.id <= stepData.current_step
            ? 'completed'
            : 'next',
      }))
    );
  };