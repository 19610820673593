import { ICurrentConfigItem, IOptionsData } from '@/feed/ts';
import { AdvancedInfoWidget } from './advanced-info-widget.component';
import { BusinessWidget } from './business-widget.component';
import { useState } from 'react';
import { ProfitModal } from '../../profit-modal';

export const ConfiguratorBody = ({
  getCurrentConfigItem,
  setDisplayMatrix,
  activeConfigItem,
  setActiveConfigItem,
  optionsData,
}: {
  getCurrentConfigItem: () => ICurrentConfigItem;
  setDisplayMatrix: (val: boolean) => void;
  activeConfigItem: number;
  setActiveConfigItem: (val: number) => void;
  optionsData: IOptionsData;
}) => {
  const [displayProfitModal, setDisplayProfitModal] = useState(false);
  return (
    <>
      <div className="content__section flex gap-[50px]">
        <div className="business__info w-[27%]">
          { getCurrentConfigItem()?.configuration_item?.data && <BusinessWidget
            data={getCurrentConfigItem()?.configuration_item?.data}
            showModal={() => setDisplayProfitModal(true)}
            optionsData={optionsData}
          /> }
        </div>
        <div className="illustration__info">
          { getCurrentConfigItem()?.configuration_item?.data?.attributes.main_image_url && <img
            className="w-[662px] h-[496px] object-contain select-none"
            src={getCurrentConfigItem()?.configuration_item?.data?.attributes.main_image_url}
            alt="model"
          /> }
        </div>
        <div className="advanced__info w-[30%]">
          { optionsData?.data?.items?.length > 0 && <AdvancedInfoWidget
            optionsData={optionsData}
            data={getCurrentConfigItem()}
            setDisplayMatrix={setDisplayMatrix}
            activeConfigItem={activeConfigItem}
            setActiveConfigItem={setActiveConfigItem}
          />}
        </div>
      </div>
      <ProfitModal
        state={displayProfitModal}
        setDisplay={setDisplayProfitModal}
      />
    </>
  );
};
