import { IUserLegal } from '@/shared';
import LegalForm from '@/shared/components/forms/LegalForm';
import { Form, Modal, notification } from 'antd';
import { useState } from 'react';

interface IProps {
  isModalOpen?: boolean;
  onSave: (formValues: any) => void;
  onClose: () => void;
  legal?: IUserLegal;
}

export const AddLegalModal = ({ onSave, onClose, legal }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onSaveForm = async () => {
    setLoading(true);
    try {
      await form.validateFields();
    } catch (e: any) {
      notification.error({
        message: 'Заполните обязательные поля',
      });
      form.scrollToField(e.errorFields[0].name[0], {
        behavior: 'smooth',
        block: 'center',
      });
      setLoading(false);
      return;
    }
    const formValues = form.getFieldsValue();
    if (legal) {
      onSave({ ...legal.attributes, ...formValues });
    } else {
      onSave(formValues);
    }
  };

  return (
    <Modal
      title="Добавить юридические сведения"
      open
      onOk={onSaveForm}
      okButtonProps={{ loading }}
      cancelText="Закрыть"
      okText="Сохранить"
      onCancel={onClose}>
      <div className="flex gap-[10px]">
        <LegalForm form={form} legal={legal} />
      </div>
    </Modal>
  );
};
