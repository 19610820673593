import { Spin } from 'antd';
import plusSvg from '../images/plus.svg';
import successSvg from '../images/success.svg';

export const OptionSelector = ({
  checked,
  changeState,
  loading,
}: {
  checked: boolean;
  changeState?: (val: boolean) => void;
  loading?: boolean;
}) => {
  return (
    <div
      className={`option__selector w-full relative flex justify-center items-center ${!checked && 'opacity-10'}`}>
      {loading && (
        <Spin className="h-[38px] w-[38px] my-[15px] absolute flex justify-center items-center" />
      )}
      <img
        className={`h-[38px] w-[38px] my-[15px] mx-auto cursor-pointer active:opacity-75 transition ${loading && 'opacity-0'}`}
        onClick={() => (changeState ? changeState(!checked) : null)}
        src={checked ? successSvg : plusSvg}
      />
    </div>
  );
};
