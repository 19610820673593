import { injectable } from 'inversify';
import 'reflect-metadata';

function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

@injectable()
export class ClientStorageService implements IClientStorageService {
  get<T = string | object>(key: string): T | null {
    const value = localStorage.getItem(key);

    if (value === null) return null;
    try {
      return JSON.parse(value);
    } catch {
      return value as T;
    }
  }

  set(key: string, payload: object | string) {
    const value =
      typeof payload === 'object' ? JSON.stringify(payload) : payload;
    localStorage.setItem(key, value);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  getUUID(): string {
    let session: string = <string>this.get('session');
    if (!session) {
      session = uuid();
    }
    this.set('session', session);
    return session;
  }
}

export interface IClientStorageService {
  get<T = string | object>(key: string): T | null;
  set(key: string, payload: object | string): void;
  remove(key: string): void;
}
