import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BASE_URL,
  TEST_URL,
  // DEFAULT_HEADERS,
  // DEFAULT_TIMEOUT,
} from '@/api/config/api.config';
import { IUserProfile } from '@/shared';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: fetchBaseQuery({ baseUrl: TEST_URL }),
  endpoints: (builder) => ({
    getProfile: builder.query<IUserProfile, string>({
      query: (session) => ({
        url: '/profile',
        headers: { 'Guest-Uuid': session },
        credentials: 'include',
      }),
    }),
  }),
});

export const { useGetProfileQuery } = profileApi;
