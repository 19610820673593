import { Modal } from 'antd';
import { ProfitCard, TotalItem } from './components';
import image1 from '../../images/image-1.jpg';
import image2 from '../../images/image-2.jpg';
import image3 from '../../images/image-3.jpg';
import image4 from '../../images/image-4.jpg';

export const ProfitModal = ({ state, setDisplay }: IProfitModal) => {
  return (
    <Modal
      width={1440}
      footer={null}
      closable={true}
      open={state}
      onCancel={() => setDisplay(false)}>
      <div className="modal__heading">
        <h4 className='text-[20px] font-bold mb-[20px]'>Формула расчета выгоды конфигуратора</h4>
      </div>
      <div className="modal__body">
        <span className='mb-[10px] block'>Предприятие (исходные данные) :</span>
        <div className='total-entry flex gap-[20px] mb-[20px] items-start'>
            <TotalItem img={image1} title='3000 ГА полей'/>
            <TotalItem img={image2} title='6 комбайнов'/>
            <TotalItem img={image3} title='3 посевных комплекса'/>
            <TotalItem img={image4} title='3 разбрасывателя удобрений'/>
        </div>
        <span className='mb-[10px] block'>Выгоды от применения БП:</span>
        <div className='profit--wrap flex gap-[10px] mb-[20px]'>
            <ProfitCard title="сев" bunkersCount={1} efficiency={50} profit={3}/>
            <ProfitCard title="уборка" bunkersCount={2} efficiency={50} profit={6}/>
            <ProfitCard title="внесение удобрений" bunkersCount={4} efficiency={50} profit={1}/>
        </div>
      </div>
      <div className="modal__footer">
        <div className="label__widget w-full p-[10px] bg-[#66B28A] text-[18px] font-bold text-white">
          итого: один бункер-перегрузчик приносит прибыль 10 млн руб в год (по
          приведенным исходным данным)
        </div>
      </div>
    </Modal>
  );
};

interface IProfitModal {
  state: boolean;
  setDisplay: (val: boolean) => void;
}
