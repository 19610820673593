import { Modal } from 'antd';
import { useState } from 'react';
import { useDeferredPromise } from './use-deffered-promise';

export enum AreYouSureModalMode {
  DELETE_ADDRESS = 'deleteAddress',
  DELETE_LEGAL = 'deleteLegal',
}

const modalMode = {
  [AreYouSureModalMode.DELETE_ADDRESS]: {
    headerText: 'Удалить адрес',
    bodyText: 'Вы действительно хотите удалить этот адрес?',
    okText: 'Удалить',
  },
  [AreYouSureModalMode.DELETE_LEGAL]: {
    headerText: 'Удалить юридические сведения',
    bodyText: 'Вы действительно хотите удалить юридические сведения?',
    okText: 'Удалить',
  },
};

export const useAreYouSureModalHook = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modeTexts, setModeTexts] = useState(modalMode.deleteAddress);
  const { defer, deferredRef } = useDeferredPromise();
  const handleConfirm = () => {
    setIsModalOpen(false);
    deferredRef.resolve(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    deferredRef.resolve(false);
  };

  return {
    areUserSure: (mode: AreYouSureModalMode) => {
      setModeTexts(modalMode[mode]);
      setIsModalOpen(true);
      return defer().promise;
    },
    AreYouSureModal: () => (
      <Modal
        title={modeTexts.headerText}
        open={isModalOpen}
        onOk={handleConfirm}
        cancelText="Закрыть"
        okText={modeTexts.okText}
        onCancel={handleClose}>
        <div className="bg-[#ffffff] mt-[12px] p-[16px] rounded-[16px] border-solid border-[2px] border-[#DFE6FA]">
          <p className="text-[#000000] text-[16px] leading-[20px]">
            {modeTexts.bodyText}
          </p>
        </div>
      </Modal>
    ),
  };
};
