import { OptionModal } from '../option-modal';
import weight1 from './images/weight-1.jpg';
import weight from './images/weight.jpg';

export const WeightSystemOptionModal = ({
  visible,
  closeFn,
  approveFn,
  declineFn,
}: {
  visible: boolean;
  closeFn: () => void;
  approveFn?: () => void;
  declineFn?: () => void;
}) => {
  return (
    <OptionModal approveFn={approveFn} declineFn={declineFn} title="Весовая система" buttonLabel='весовую систему' closeFn={closeFn} visible={visible}>
      <div className="top-wrap flex justify-between mb-[40px]">
        <div className="top-item w-[40%]">
          <h3 className="font-bold mb-[10px] text-[20px]">
            Весовая система позволяет:
          </h3>
          <ul className="list-disc ml-[20px] font-light">
            <li>Фиксировать вес чеками</li>
            <li>
              Передавать данные о весе, точке совершённой операции и
              местонахождении бункера-перегрузчика на порталы телеметрии
              (Автограф, Агросигнал)
            </li>
            <li>
              Подробная аналитика:
              <ol className="list-decimal ml-[20px] text-sm">
                <li>количество собранного урожая каждым комбайном</li>
                <li>объём урожая, перевезённого за смену каждым зерновозом</li>
                <li>тоннаж собранного зёрна с каждого поля</li>
              </ol>
            </li>
          </ul>
        </div>
        <div className="top-item w-[40%] flex items-center">
          <img
            src={weight1}
            className="max-w-[345px] object-contain mr-[35px]"
          />
          <div className="">
            <div className="mb-[20px]">
              <span className="block font-bold text-[26px] leading-4">
                9000 кг
              </span>
              <span className="text-[#0870E2]">Загружено в БП</span>
            </div>
            <div>
              <span className="block font-bold text-[26px] leading-4">
                9000 кг
              </span>
              <span className="text-[#007E3C]">Выгружено из БП</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mid-wrap mb-[40px]">
          <h3 className="font-bold mb-[10px] text-[20px]">
            2 возможных варианта применения опции:
          </h3>
          <div className="mid-content flex">
            <div className="mid-item w-[60%]">
              <div className="text-block mb-[20px]">
                <span className='block font-bold mb-[0px]'>высокоточная весовая система с термопринтером</span>
                <p className='font-light'>
                  Весовая система с термопринтером, которыя позволяет
                  распечатывать данные взвешивания.
                </p>
              </div>
              <div className='text-block'>
              <span className='block font-bold mb-[0px]'>
                весовая система с термопринтером, передачей данных
                и GNSS с системой определения координат с точностью до 50см
              </span>
              <p className='font-light'>
                Весовая система с термопринтером, программным обеспечением
                и системой онлайн-передачи данных по GPRS, которые,
                помимо определения веса загруженного в бункер зёрна, записывают
                данные взвешивания в память устройства и передают на удалённый
                сервер информацию с привязкой к комбайнам, зерновозам, полям, 
                времени и убранной культуре, а также с возможностью применения 
                сменных заданий при сельскохозяйственных работах
              </p>
              </div>
            </div>
            <div className="mid-item w-[40%] flex justify-end">
              <div className='invoice bg-[#EEF2FD] p-[20px] mr-[40px]'>
                <span className='block font-bold text-[26px] border-solid border-b-[3px] border-b-black'>чек № 329</span>
                <span className='block font-bold text-[18px]'>бункер-перегрузчик 811</span>
                <span className='block '>RFID метка 13b25</span>
                <span className='block '>разгрузка</span>
                <span className='text-[20px] font-bold'>9000.25 кг</span>
              </div>
              <img src={weight} className='max-w-[150px] object-contain'/>
            </div>
          </div>
        </div>
    </OptionModal>
  );
};
