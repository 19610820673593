import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DiContainer } from '@/di/di-container';
import {
  useClearShoppingCartMutation,
  useDeleteStacksFromCartMutation,
  useGetShoppingCartDetailsQuery,
} from '@/shoppingCart/services/shopping-cart.service';
import { setOrderItems } from '@/services/slices/orderSlice';
import { ClientStorageService } from '@/common/services/client-storage.service';

import {
  Alert,
  Button,
  Collapse,
  CollapseProps,
  Spin,
  Steps,
  notification,
} from 'antd';
import clipboardIcon from './icons/clipboard.svg';
import Link from 'antd/es/typography/Link';
import { useNavigate } from 'react-router-dom';
import { addSpacesInNumbers } from '@/configurator/helpers/add-spaces-in-numbers';
import { LabelCollapse } from '../../components/labelCollapse/label-collapse';
import { ChildrenCollapse } from '../../components/childrenCollapse/children-collapse';
import { useNotAuthModal } from '@/shared/hooks';
import { OrdersView } from '@/order/views';

import './index.styles.scss';

const clientStorageService = DiContainer.get(ClientStorageService);

export const ShoppingCartView = () => {
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [activeStep, setActiveStep] = useState(1);
  const { data, isLoading, isFetching, refetch } =
    useGetShoppingCartDetailsQuery(clientStorageService.getUUID());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clearCart] = useClearShoppingCartMutation();
  const [clearSelectedStacks] = useDeleteStacksFromCartMutation();
  const [selectedStacks, setSelectedStacks] = useState<string[]>([]);
  const sharedUUID = localStorage.getItem('shared_uuid');
  const { openNotAuthModal, NotAuthModal, isAuthorized } = useNotAuthModal({
    titleAuth: 'Внимание',
    titleNotAuth: 'Требуется авторизация',
    okAuth: 'Продолжить',
    okNotAuth: 'Войти',
    redirectPath: '/orders',
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setBtnDisabled(!isAuthorized);
  }, [isAuthorized]);

  useEffect(() => {
    if (!selectedStacks.length && data?.modifications?.length) {
      setSelectedStacks(
        data.modifications.map((item) => String(item.picker_id))
      );
    }
  }, [data]);
  const selectedStacksQuantity = useMemo(
    () =>
      selectedStacks?.reduce(
        (acc, curr) =>
          acc +
          Number(
            data?.modifications?.find((el) => el.picker_id === Number(curr))
              ?.items?.length
          ),
        0
      ),
    [selectedStacks, data?.modifications]
  );

  const selectedStacksPrice = useMemo(
    () =>
      selectedStacks?.reduce(
        (acc, curr) =>
          acc +
          Number(
            data?.modifications?.find((el) => el.picker_id === Number(curr))
              ?.total
          ),
        0
      ),
    [selectedStacks]
  );

  const handleCheckStack = (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.stopPropagation();
    if (selectedStacks.includes(id)) {
      setSelectedStacks(selectedStacks.filter((stack) => stack !== id));
    } else {
      setSelectedStacks([...selectedStacks, id]);
    }
  };

  const items: CollapseProps['items'] = data?.modifications?.map((mod) => ({
    key: mod.picker_id,
    label: (
      <LabelCollapse
        modification={mod}
        quantity={mod.items.length}
        handleCheck={(e) => handleCheckStack(e, String(mod.picker_id))}
      />
    ),
    children: (
      <div className="collapse-child">
        {mod.items?.map((el) => (
          <ChildrenCollapse
            bunker={el}
            modification={mod}
            key={el.id}
            btnDisabled={btnDisabled}
          />
        ))}
      </div>
    ),
  }));
  const onMakeOrderClick = () => {
    if (!data || selectedStacks.length === 0 || btnDisabled) {
      return;
    }
    setBtnDisabled(true);

    dispatch(
      setOrderItems(
        data.modifications.filter((el) =>
          selectedStacks.includes(String(el.picker_id))
        )
      )
    );

    if (!isAuthorized) {
      openNotAuthModal();
    } else {
      // onClearCart();
      // navigate('/place_order');
      setActiveStep(2);
    }
    setBtnDisabled(false);
  };

  const onClearCart = () => {
    setBtnDisabled(true);
    clearCart(clientStorageService.getUUID())
      .then(() => setSelectedStacks([]))
      .then(() => {
        refetch();
        setBtnDisabled(false);
      });
  };

  const onClearSelectedStacks = () => {
    setBtnDisabled(true);
    clearSelectedStacks({
      ids: selectedStacks,
      session: clientStorageService.getUUID(),
    }).then(() => {
      setBtnDisabled(false);
      setSelectedStacks([]);
      refetch();
    });
  };

  // до демо 07.04.2024 не использовать
  const collapseButton = (isActive: boolean | undefined) => (
    <div
      style={{
        color: '#007E3C',
        position: 'relative',
        right: '20px',
        top: '20px',
      }}>
      {isActive ? 'Свернуть' : 'Развернуть'}
    </div>
  );

  const copyLinkToClipboard = () => {
    if (!isAuthorized) {
      return;
    }
    navigator.clipboard.writeText(
      `${window.location.href}?shared_uuid=${localStorage.getItem('owner_uuid')}`
    );
    notification.success({ message: 'Ссылка скопирована в буфер обмена' });
  };

  return (
    <>
      <div className="shopping-cart md:container md:mx-auto overflow-y-visible w-full">
        <div className="flex justify-center items-center">
          <Steps
            current={activeStep}
            style={{ marginBottom: '20px', width: '50%' }}
            items={[
              {
                title: (
                  <Link
                    strong
                    style={{ color: '#007E3C' }}
                    className="hover:opacity-80"
                    disabled={false}
                    onClick={() => {
                      setActiveStep(1);
                    }}>
                    Собрать корзину
                  </Link>
                ),
              },
              {
                title: (
                  <Link
                    strong
                    style={{ color: '#007E3C' }}
                    className="hover:opacity-80"
                    disabled={false}
                    onClick={onMakeOrderClick}>
                    Оформить заказ
                  </Link>
                ),
              },
            ]}
          />
        </div>
        {activeStep === 1 ? (
          isLoading || isFetching ? (
            <Spin
              className="flex h-full justify-center items-center"
              spinning={true}
              size="large"></Spin>
          ) : (
            <div className="overflow-y-visible grid grid-cols-3 gap-4 w-full">
              <div className="col-span-2">
                <div>
                  выбрано {selectedStacks.length} из{' '}
                  {data?.modifications?.length}
                </div>
                <Button
                  className="my-3 bg-[#007E3C] text-white hover:bg-white"
                  disabled={selectedStacks.length === 0 || btnDisabled}
                  onClick={onClearCart}>
                  Очистить корзину
                </Button>
                <Button
                  disabled={selectedStacks.length === 0 || btnDisabled}
                  className="my-3 ml-2 cursor-pointer bg-white text-[#007E3C]"
                  onClick={onClearSelectedStacks}>
                  Очистить выбранные
                </Button>
                {data?.modifications && data.modifications.length > 0 && (
                  <div
                    className={`ml-5 inline-flex items-center cursor-pointer hover:opacity-80 ${!isAuthorized && 'opacity-40'}`}
                    onClick={copyLinkToClipboard}>
                    <img src={clipboardIcon} />
                    <a className="pl-2 text-[#409BFF]">
                      Поделиться ссылкой на корзину
                    </a>
                  </div>
                )}
                {!isAuthorized && sharedUUID ? (
                  <Alert
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                    message="С вами поделились корзиной. Вы находитесь в режиме
                    просмотра. Для получения возможности редактирования
                    авторизируйтесь"
                    type="info"
                    showIcon
                  />
                ) : null}
                <div className="!min-h-[500px]">
                  {data?.modifications?.length ? (
                    <Collapse
                      style={{
                        boxSizing: 'border-box',
                        borderRadius: '24px',
                      }}
                      className="custom-collapse flex flex-col gap-[32px]"
                      expandIconPosition="end"
                      expandIcon={(props) => collapseButton(props!.isActive)}
                      defaultActiveKey={data?.modifications.map(
                        (p) => p.picker_id
                      )}
                      bordered={false}
                      items={items}
                    />
                  ) : (
                    <div className="text-center py-40">
                      {' '}
                      <h1 className="text-[#7E89A5]">
                        В данный момент товары в корзине отсутствуют.
                        <br />
                        Для добавления товаров перейдите в конифигуратор.
                      </h1>
                      <br />
                      <Button
                        className="my-3 bg-[#007E3C] text-white hover:bg-white"
                        disabled={btnDisabled}
                        onClick={() => navigate('/configurator')}>
                        Перейти в конфигуратор
                      </Button>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-span-1">
                <div
                  className="mt-[75px] bg-[#E1F3EA] p-5 rounded-[32px]"
                  style={{
                    border: '1px solid #AAB3CB',
                  }}>
                  <div
                    className="flex"
                    style={{ justifyContent: 'space-between' }}>
                    <h1>
                      {selectedStacks.length ? selectedStacksQuantity : '0'}{' '}
                      товаров на сумму
                    </h1>
                    {selectedStacksPrice ? (
                      <h1 className="font-bold">
                        {addSpacesInNumbers(selectedStacksPrice)} ₽
                      </h1>
                    ) : (
                      <h1>0 ₽</h1>
                    )}
                  </div>
                  <h2 className="pt-5">*сумма указана без учета скидки</h2>
                  <a className="text-[blue] mt-[10px] block">
                    Узнать об условиях предоставления скидки
                  </a>
                  <Button
                    style={{ width: '100%' }}
                    disabled={selectedStacks.length === 0 || btnDisabled}
                    className="my-5 py-[15px] h-[56px] font-bold fs-[20px] bg-[#007E3C] text-white hover:bg-[#E1F3EA]"
                    onClick={onMakeOrderClick}>
                    Перейти к оформлению
                  </Button>
                </div>
              </div>
            </div>
          )
        ) : (
          <OrdersView />
        )}
      </div>
      <NotAuthModal />
    </>
  );
};
