import { ICurrentConfigItem, IOptionsData } from '@/feed/ts';


interface IBottomBar {
  optionsData: IOptionsData;
  activeConfigItem: number;
  setActiveConfigItem: (value: React.SetStateAction<number>) => void;
  setDisplayMatrix: (val: boolean) => void;
  getCurrentConfigItem: () => ICurrentConfigItem;
}

export const BottomBar = ({
  optionsData,
  activeConfigItem,
  setActiveConfigItem,
  setDisplayMatrix,
  getCurrentConfigItem,
}: IBottomBar) => {
  return (
    <div className="controls__section w-full bg-white my-[35px] p-5 rounded-[16px] text-center">
      <div className="w-full text-start">
        <span
          onClick={() => {
            if (activeConfigItem === 1) {
              return;
            }
            setActiveConfigItem(activeConfigItem - 1);
          }}
          style={{ visibility: activeConfigItem === 1 ? 'hidden' : 'visible' }}
          className="inline-block mr-auto underline cursor-pointer active:opacity-[.75] select-none">
          на шаг назад
        </span>
      </div>
      {optionsData?.data?.items?.length > 0 ? (
        <>
          <span className="mb-[18px] block text-[18px]">
            использовать{' '}
            {
              getCurrentConfigItem()?.configuration_item?.data?.attributes
                ?.action_name
            }
            ?
          </span>
        </>
      ) : (
        <div className="mb-5">данная категория пуста</div>
      )}
      {optionsData?.data?.items?.length > 0 ? (
        <>
          <div className="buttons">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (activeConfigItem === optionsData.data.items.length) {
                  setDisplayMatrix(true);
                  return;
                }
                setActiveConfigItem(activeConfigItem + 1);
              }}
              className="p-[16px] inline-block rounded-[10px] hover:text-black border-solid border-[#DFE6FA] border-[2px] mr-[22px] transition active:opacity-50">
              не использовать{' '}
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setDisplayMatrix(true);
              }}
              className="p-[16px] inline-block rounded-[10px] bg-[#66B28A] text-white hover:text-white transition active:opacity-75">
              использовать{' '}
              {
                getCurrentConfigItem()?.configuration_item?.data?.attributes
                  ?.action_name
              }
            </a>
          </div>
          <div className="price mt-[16px]">
            <span className="font-light">
              стоимость :{' '}
              <strong className="font-bold text-[#007E3C]">
                {getCurrentConfigItem()?.configuration_item?.data?.attributes.cost?.toLocaleString(
                  'RU-ru'
                )}{' '}
                ₽/шт
              </strong>
            </span>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};
