import './index.styles.scss';
import logo from './assets/mini-logo.png';
import liliani from './assets/liliani.png';
import catalog1 from './assets/catalog1.svg';
import catalog2 from './assets/catalog2.svg';
import catalog3 from './assets/catalog3.svg';
import { useNavigate } from 'react-router-dom';

export const MainView = () => {
  const navigate = useNavigate();
  return (
    <div className="main-page">
      <div className="logo">
        <div className="flex flex-col text-white h-full justify-center items-center gap-5">
          <div className="flex items-center flex-col gap-4">
            <div className="flex items-center font-bold text-4xl gap-3">
              <img src={logo} alt="logo" />
              Конфигуратор
            </div>
            <div className="flex items-center text-base gap-3">
              powered by
              <img src={liliani} alt="Liliani" />
            </div>
          </div>
          <div className="flex flex-col items-center text-base">
            <div>
              сервис по подбору модификаций сельскохозяйственной техники ООО “Лилиани”
            </div>
            <div>и дополнительных опций к ней</div>
          </div>
          <div className="flex text-black flex-row gap-5 flex-wrap justify-center">
            <div className="flex flex-col items-center w-[400px] h-[310px] rounded-[16px] bg-white">
              <img className="p-2 rounded-[16px]" src={catalog1} alt="" />
              <div>Каталог сельскохозяйственной</div>
              <div>техники ООО “Лилиани” со спецификациями</div>
            </div>
            <div className="flex flex-col items-center w-[400px] h-[310px] rounded-[16px] bg-white">
              <img className="p-2 rounded-[16px]" src={catalog2} alt="" />
              <div>Конфигурирование сельскохозяйственной техники</div>
              <div>ООО “Лилиани” под ваши задачи</div>
              <div>в соответствии с вашими агропроцессами</div>
            </div>
            <div className="flex flex-col items-center w-[400px] h-[310px] rounded-[16px] bg-white">
              <img className="p-2 rounded-[16px]" src={catalog3} alt="" />
              <div>Оформление сделок по приобретению</div>
              <div>сельскохозяйственной техники ООО “Лилиани”</div>
              <div>и дополнительных опций к ней</div>
            </div>
          </div>
          <button
            style={{
              background: 'linear-gradient(259deg, #66B28A 0%, #007E3C 100%)',
            }}
            onClick={() => {
              navigate('/configurator');
            }}
            className="text-white rounded-[10px] cursor-pointer px-[24px] py-[8px] hover:opacity-90">
            Приступить к подбору сельскохозяйственной техники ООО “Лилиани”
          </button>
        </div>
      </div>
    </div>
  );
};
