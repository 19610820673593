import { Container } from 'inversify';
import { DiContainerInjectFn } from '@/di/ts';

const DiContainer = new Container();

const injectModules = import.meta.glob('@/**/*.di.ts', {
  eager: true,
});
Object.values(injectModules).forEach((module) => {
  // @ts-ignore
  if (!module.default) return;
  // @ts-ignore
  (module.default as DiContainerInjectFn)(DiContainer);
});

export { DiContainer };
