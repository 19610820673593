export const Button = ({ children, onClick, className, disabled, style }: IButton) => (
  <button
    disabled={disabled}
    style={style ?? {}}
    className={`bg-[#007E3C] select-none active:bg-[#016d35] text-white px-[24px] py-[8px] rounded-[10px] text-[16px] font-semibold transition ${
      disabled ? 'bg-[#AAB3CB] active:bg-[#AAB3CB]' : ''
    } ${className}`}
    onClick={onClick}>
    {children}
  </button>
);

interface IButton {
  disabled?: boolean;
  className?: string;
  children: string | JSX.Element;
  onClick: () => void;
  style?: React.CSSProperties
}
