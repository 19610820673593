import { OptionModal } from '../option-modal';
import imgFile from './images/wheels_add.png';

export const WheelsOptionModal = ({
  visible,
  closeFn,
  approveFn,
  declineFn,
}: {
  visible: boolean;
  closeFn: () => void;
  approveFn?: () => void;
  declineFn?: () => void;
}) => {
  return (
    <OptionModal
      approveFn={approveFn}
      declineFn={declineFn}
      title="увеличенный размер колес"
      closeFn={closeFn}
      visible={visible}>
      <p className="mb-[40px]">
        Недобор урожая от уплотнения почвы ежегодно по РФ составляет{' '}
        <span className='font-bold text-[#E73A42]'>до 30 000 000 тонн</span> , а перерасход топлива - <span className='font-bold text-[#E73A42]'>до 3 000 000 тонн</span>.
      </p>
      <img className="w-full mb-[30px]" src={imgFile} />
    </OptionModal>
  );
};
