import { FC, useState } from 'react';
import { Card, Col, Dropdown, Row, Spin, Switch, notification } from 'antd';

import { ClientStorageService } from '@/common/services/client-storage.service';
import { DiContainer } from '@/di/di-container';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useGetProfileQuery } from '../services/profile.service';
import './index.styles.scss';
import {
  IUserAddress,
  IUserLegal,
  isAddressFilter,
  isLegalFilter,
} from '@/shared';
import { AddLegalModal } from '../components/AddLegalModal';
import {
  useCreateNewLegalMutation,
  useDeleteLegalMutation,
  useSaveCurrentLegalMutation,
  useUpdateLegalMutation,
} from '@/services/legals.service';
import {
  useCreateNewAddressMutation,
  useDeleteAddressMutation,
  useSaveCurrentAddressMutation,
  useUpdateAddressMutation,
} from '@/services/adresses.service';
import { AddAddressModal } from '../components/AddAddressModal';
import { AreYouSureModalMode, useAreYouSureModalHook } from '@/shared/hooks';

const clientStorageService = DiContainer.get(ClientStorageService);

export const ProfileView: FC = () => {
  const [isLegalModalOpen, setIsLegalModalOpen] = useState<string | false>(
    false
  );
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<
    string | false | IUserAddress
  >(false);
  const [moneyMoney, setMoneyMoney] = useState(
    localStorage.getItem('moneyMoney') === 'true' ? true : false
  );
  const [createLegal] = useCreateNewLegalMutation();
  const [deleteLegal] = useDeleteLegalMutation();
  const [setCurrentLegal] = useSaveCurrentLegalMutation();
  const [updateLegal] = useUpdateLegalMutation();

  const [createAddress] = useCreateNewAddressMutation();
  const [deleteAddress] = useDeleteAddressMutation();
  const [updateAddress] = useUpdateAddressMutation();
  const [setCurrentAddress] = useSaveCurrentAddressMutation();

  const session = clientStorageService.getUUID();

  const { areUserSure, AreYouSureModal } = useAreYouSureModalHook();

  const { data, isLoading, refetch } = useGetProfileQuery(session);

  const [selectedItems, setSelectedItems] = useState({
    legal: 'string',
    address: 'string',
  });
  if (isLoading) {
    return (
      <Spin
        className="flex h-full justify-center items-center"
        spinning={true}
        size="large"></Spin>
    );
  }

  const userInfo = data?.data || null;
  const userLegals = (data?.included.filter(isLegalFilter) || []).sort(
    (a, b) => +a.id - +b.id
  );
  const userAddresses = (data?.included.filter(isAddressFilter) || []).sort(
    (a, b) => +a.id - +b.id
  );
  const currentLegal = userLegals.filter((a) => a.attributes.current)[0];

  const addressIdsByLegal = currentLegal?.relationships?.addresses?.data?.map(
    ({ id }) => id
  );

  const currentAddresses = userAddresses.filter((a) =>
    addressIdsByLegal?.includes(a.id)
  ) || [];

  const createNewLegal = async (legalInfo: IUserLegal['attributes']) => {
    const reqData = {
      data: {
        ...legalInfo,
        current: userLegals.length === 0 ? true : false,
      },
    };
    await createLegal({ data: reqData, session });
    setIsLegalModalOpen(false);
    refetch();
  };
  const deleteLegalById = async (id: IUserLegal['id']) => {
    if (await areUserSure(AreYouSureModalMode.DELETE_LEGAL)) {
      if (currentLegal && currentLegal.relationships?.addresses?.data?.length) {
        notification.warning({
          message: 'Ошибка при попытке удалить Юр.лицо с имеющимися адресами',
        });
        return;
      }
      await deleteLegal({ id, session });
      if (userLegals.length === 2) {
        const lastLegal = userLegals.filter((a) => a.id !== id)[0];
        await setCurrentLegal({ session, id: lastLegal.id });
      }
      refetch();
    }
  };
  const saveCurrentLegal = async (id: string) => {
    setSelectedItems((prev) => {
      return { ...prev, legal: id };
    });
    await setCurrentLegal({ session, id });
    refetch();
  };
  const updateLegalInfo = async (data: IUserLegal) => {
    await updateLegal({ session, data, id: currentLegal.id });
    setIsLegalModalOpen(false);
    refetch();
  };
  const updateAddressInfo = async (
    data: IUserAddress['attributes'],
    id: string
  ) => {
    await updateAddress({
      session,
      data: { data },
      id,
      legalId: currentLegal.id,
    });
    setIsAddressModalOpen(false);
    refetch();
  };

  const createNewAddress = async (addressInfo: IUserAddress['attributes']) => {
    const reqData = {
      data: {
        ...addressInfo,
        current: currentAddresses.length === 0 ? true : false,
      },
    };
    await createAddress({
      data: reqData,
      legalId: currentLegal.id,
      session,
    });
    setIsAddressModalOpen(false);
    refetch();
  };

  const deleteAddressById = async (id: IUserAddress['id']) => {
    if (await areUserSure(AreYouSureModalMode.DELETE_ADDRESS)) {
      await deleteAddress({ id, session, legalId: currentLegal.id });
      // const address = currentAddresses.find((a) => a.id === id);
      // if (currentAddresses.length === 2 || address?.attributes.current) {
      //   const lastAddress = currentAddresses.filter((a) => a.id !== id)[0];
      //   await setCurrentAddress({
      //     session,
      //     id: lastAddress.id,
      //     legalId: currentLegal.id,
      //   });
      // }
      refetch();
    }
  };
  const saveCurrentAddress = async (id: string) => {
    setSelectedItems((prev) => {
      return { ...prev, address: id };
    });
    await setCurrentAddress({ session, id, legalId: currentLegal.id });
    refetch();
  };

  return (
    <>
      <div
        className="flex justify-between rounded-[8px] p-[6px] border-solid border-[2px] border-[#aab3cb]"
        onClick={() => {
          setMoneyMoney(!moneyMoney);
          notification.success({
            message: 'Выставлено',
          });
          localStorage.setItem('moneyMoney', String(!moneyMoney));
        }}
        style={{
          position: 'absolute',
          top: '60px',
          right: '40px',
        }}>
        <Switch title={'Сменить режим подсчёта'} checked={moneyMoney} />
      </div>
      <div className="profile-page md:container md:mx-auto overflow-y-visible grid grid-cols-10 gap-[40px]">
        <div className="col-span-3 mt-[40px]">
          <div className="flex flex-col gap-[6px]">
            {userLegals.length > 0 &&
              userLegals.map((legal) => (
                <div
                  key={legal.id}
                  className="flex justify-between rounded-[8px] p-[22px] border-solid border-[2px] border-[#aab3cb] cursor-pointer"
                  onClick={() => {
                    if (legal.attributes.current) {
                      notification.warning({
                        message:
                          'Нельзя сделать всю юридическую информацию не активной',
                      });
                      return;
                    }
                    notification.success({
                      message: 'Юридическая информация выбрана активной',
                    });
                    saveCurrentLegal(legal.id);
                  }}
                  style={{
                    backgroundColor: legal.attributes.current
                      ? '#66B28A'
                      : '#ffffff',
                  }}>
                  <Switch
                    title={'Показать адреса'}
                    checked={
                      legal.attributes.current ||
                      selectedItems.legal === legal.id
                    }
                  />
                  <span>{legal.attributes.name}</span>
                </div>
              ))}
            <div
              onClick={() => setIsLegalModalOpen('create')}
              className="add-address text-[#000000]  flex justify-between bg-[#ffffff] rounded-[8px] p-[22px] border-solid border-[2px] border-[#aab3cb]">
              Добавить юридическую информацию
            </div>
          </div>
        </div>
        <div className="col-span-7">
          {currentLegal && (
            <div
              style={{ border: '1px solid #AAB3CB' }}
              className="mt-[40px] bg-[#ffffff] py-[24px] px-[32px] rounded-[16px]">
              <div
                className={`gap-[10px] bg-[#ffffff] p-[16px] pt-[0px] rounded-[16px]`}>
                <div className="flex justify-between">
                  <Col span={12}>{currentLegal.attributes.name}</Col>
                  <div>
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: '1',
                            label: (
                              <span>
                                <EditOutlined style={{ marginRight: '5px' }} />
                                Редактировать
                              </span>
                            ),
                            onClick: () => setIsLegalModalOpen('edit'),
                          },
                          {
                            key: '2',
                            label: (
                              <span>
                                <DeleteOutlined
                                  style={{ marginRight: '5px' }}
                                />
                                Удалить
                              </span>
                            ),
                            onClick: () => deleteLegalById(currentLegal.id),
                            danger: true,
                          },
                        ],
                      }}>
                      <div
                        style={{
                          cursor: 'pointer',
                          marginTop: '-16px',
                          marginRight: '-24px',
                          fontSize: '28px',
                          padding: '8px 16px',
                        }}>
                        ⁝
                      </div>
                    </Dropdown>
                  </div>
                </div>
                <Row gutter={[16, 24]} className="pt-[24px]">
                  <Col span={6}>
                    <div className="text-[#7E89A5]">Почтовый адрес</div>
                    <div>{currentLegal.attributes.actual_address}</div>
                  </Col>
                  <Col span={6}>
                    <div className="text-[#7E89A5]">ОГРН</div>
                    <div>{currentLegal.attributes.ogrn}</div>
                  </Col>
                  <Col span={6}>
                    <div className="text-[#7E89A5]">ИНН</div>
                    <div>{currentLegal.attributes.inn}</div>
                  </Col>
                  <Col span={6}>
                    <div className="text-[#7E89A5]">БИК</div>
                    <div>{currentLegal.attributes.bik}</div>
                  </Col>
                  <Col span={6}>
                    <div className="text-[#7E89A5]">КПП</div>
                    <div>{currentLegal.attributes.kpp}</div>
                  </Col>
                  <Col span={6}>
                    <div className="text-[#7E89A5]">К/С</div>
                    <div>{currentLegal.attributes.corr_account_number}</div>
                  </Col>
                  <Col span={6}>
                    <div className="text-[#7E89A5]">ОКПО</div>
                    <div>{currentLegal.attributes.okpo}</div>
                  </Col>
                </Row>
                <div className="text-[#7E89A5] pt-[24px]">Р/С</div>
                <div>{currentLegal.attributes.account_number}</div>
              </div>
              <div className="mt-[24px]">
                <span>Адреса доставки</span>
                <Row gutter={[16, 24]} className="pt-[24px]">
                  {currentAddresses.map((address) => (
                    <Col key={address.id} span={8}>
                      <Card
                        style={{ height: '100%' }}
                        className={
                          'border-solid border-[3px] border-' +
                          (address.attributes.current
                            ? '[#007E3C]'
                            : '[#DFE6FA]')
                        }>
                        <div
                          style={{
                            height: '100%',
                            wordBreak: 'break-all',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            rowGap: '10px',
                          }}>
                          <div className="flex justify-between">
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    key: '1',
                                    label: (
                                      <span>
                                        <EditOutlined
                                          style={{ marginRight: '5px' }}
                                        />
                                        Редактировать
                                      </span>
                                    ),
                                    onClick: () =>
                                      setIsAddressModalOpen(address),
                                  },
                                  {
                                    key: '2',
                                    label: (
                                      <span>
                                        <DeleteOutlined
                                          style={{ marginRight: '5px' }}
                                        />
                                        Удалить
                                      </span>
                                    ),
                                    onClick: () =>
                                      deleteAddressById(address.id),
                                    danger: true,
                                  },
                                ],
                              }}>
                              <div
                                style={{
                                  cursor: 'pointer',
                                  marginTop: '-8px',
                                  fontSize: '28px',
                                  padding: '0 8px 0 8px',
                                }}>
                                ⁝
                              </div>
                            </Dropdown>
                            {address.attributes.current ? (
                              <div>Адрес активен</div>
                            ) : (
                              <div className="text-[#7E89A5]">
                                Адрес не активен
                              </div>
                            )}
                            <Switch
                              onClick={() => {
                                if (address.attributes.current) {
                                  notification.warning({
                                    message:
                                      'Нельзя сделать все адреса не активными',
                                  });
                                  return;
                                }
                                notification.success({
                                  message: 'Адрес выбран активным',
                                });
                                saveCurrentAddress(address.id);
                              }}
                              checked={
                                address.attributes.current ||
                                selectedItems.address === address.id
                              }
                            />
                          </div>
                          <div className="gap-x-[10px] flex">
                            <div className="break-normal text-[#7E89A5]">
                              Область
                            </div>
                            <div>{address.attributes.region}</div>
                          </div>
                          <div className="gap-x-[10px] flex">
                            <div className="break-normal text-[#7E89A5]">
                              Город
                            </div>
                            <div>{address.attributes.city}</div>
                          </div>
                          <div className="gap-x-[10px] flex">
                            <div className="break-normal text-[#7E89A5]">
                              Адрес
                            </div>
                            <div>{address.attributes.address_line}</div>
                          </div>
                          <div className="gap-y-[10px]">
                            <div className="break-normal text-[#7E89A5]">
                              Описание
                            </div>
                            <div>{address.attributes.comments}</div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  ))}
                  <Col span={8}>
                    <Card
                      onClick={() => setIsAddressModalOpen('add')}
                      style={{ height: '100%', minHeight: '200px' }}
                      className="add-address flex justify-center border-solid border-[3px] border-[#DFE6FA]">
                      <div
                        className="flex items-center"
                        style={{ height: '100%' }}>
                        Добавить адрес
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </div>
      </div>
      <AreYouSureModal />
      {isLegalModalOpen && (
        <AddLegalModal
          onSave={
            isLegalModalOpen === 'edit' ? updateLegalInfo : createNewLegal
          }
          onClose={() => {
            setIsLegalModalOpen(false);
          }}
          legal={isLegalModalOpen === 'edit' ? currentLegal : undefined}
        />
      )}
      {isAddressModalOpen && (
        <AddAddressModal
          onCreate={createNewAddress}
          onUpdate={updateAddressInfo}
          address={
            isAddressModalOpen === 'add'
              ? undefined
              : (isAddressModalOpen as IUserAddress)
          }
          onClose={() => setIsAddressModalOpen(false)}
        />
      )}
    </>
  );
};
