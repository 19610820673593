import styles from './index.module.sass';

import classnames from 'classnames';
import { Layout } from 'antd';
import {
  DefaultLayout,
  IDefaultLayoutProps,
} from '@/common/components/DefaultLayout';
import { PrimaryLayoutHeader } from './components';
import { PrimaryLayoutFooter } from './components/PrimaryLayoutFooter';
import { useLocation } from 'react-router-dom';
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton';

const pathsWithoutHeader = ['/'];

export function PrimaryLayout(props: IPrimaryLayoutProps) {
  const location = useLocation();
  const { className, contentClassName, children: Children, ...rest } = props;
  const rootClassName = classnames(styles.root, className);
  const _contentClassName = classnames(styles.content, contentClassName);

  return (
    <>
      {pathsWithoutHeader.includes(location.pathname) ? (
        Children
      ) : (
        <DefaultLayout className={rootClassName} {...rest}>
          <PrimaryLayoutHeader />
          <Layout.Content className={_contentClassName}>
            {Children}
          </Layout.Content>
          <PrimaryLayoutFooter />
          <ScrollToTopButton />
        </DefaultLayout>
      )}
    </>
  );
}

export interface IPrimaryLayoutProps extends IDefaultLayoutProps {
  contentClassName?: string;
}
