import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NotFoundView } from '@/router/views/NotFound';
import { useSelector } from 'react-redux';
import { RootState } from '@/services/store';
import { FeedView } from '@/feed/views/FeedView';
import { OrdersView } from '@/order/views';
import { OrdersListView } from '@/orders-list/views';
import { ProfileView } from '@/profile/views';
import { ShoppingCartView } from '@/shoppingCart/components/shoppingCart/views/ShoppingCartView';
import { ConfiguratorView } from '@/configurator/views/ConfiguratorView';
import { PrimaryLayout } from '@/common/components/PrimaryLayout';
import { OrderInfoView } from '@/order-info/views';
import { MainView } from '@/main-page/views';
import { useEffect } from 'react';
import { useReactPath } from '@/shared/hooks/use-react-path';

const openLocations = ['/'];
const sharedLocations = ['/shopping-cart'];

const redirect = () =>
  window.location.assign(
    `https://sso.liliani.ru/login?client_id=99bee193-5fa9-4c51-8d79-4b62f2e1f74d&redirect_url=${window.location.href}`
  );

export const AppRouter = ({ isLoading }: { isLoading: boolean }) => {
  const { isAuthorized } = useSelector((state: RootState) => state.auth);
  const location = useReactPath();

  const sharedUUID =
    new URLSearchParams(window.location.search).get('shared_uuid') ||
    localStorage.getItem('shared_uuid');
  if (sharedUUID) {
    localStorage.setItem('shared_uuid', sharedUUID);
  }
  useEffect(() => {
    if (
      !isLoading &&
      !isAuthorized &&
      !openLocations.includes(location) &&
      !sharedUUID
    ) {
      redirect();
    }
  }, [isAuthorized, location]);

  if (
    !isLoading &&
    !isAuthorized &&
    !openLocations.includes(location) &&
    !sharedUUID
  ) {
    redirect();
    return null;
  }
  if (
    !isAuthorized &&
    !sharedLocations.includes(location) &&
    sharedUUID &&
    !openLocations.includes(location)
  ) {
    redirect();
    return null;
  }

  if (isLoading) return null;

  return (
    <BrowserRouter>
      <PrimaryLayout>
        <Routes>
          <Route index path="/" Component={MainView} />
          <Route
            id="CONFIGURATOR_ROUTE"
            path="/configurator"
            Component={ConfiguratorView}
          />
          <Route id="FEED_ROUTE" path="/feed/:id" Component={FeedView} />
          <Route id="ORDERS_ROUTE" path="/place_order" Component={OrdersView} />
          <Route
            id="ORDERS_LIST_ROUTE"
            path="/order_list"
            Component={OrdersListView}
          />
          <Route id="PROFILE_ROUTE" path="/profile" Component={ProfileView} />
          <Route
            id="SHOPPING_CART_ROUTE"
            path="/shopping-cart"
            Component={ShoppingCartView}
          />
          <Route
            id="ORDER_INFO_ROUTE"
            path="/order_list/:id"
            Component={OrderInfoView}
          />
          <Route path="/*" Component={NotFoundView} />
        </Routes>
      </PrimaryLayout>
    </BrowserRouter>
  );
};
