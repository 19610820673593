import { IUserAddress, IUserLegal, UserFieldType } from '../types';

const isLegalFilter = (
  property: IUserLegal | IUserAddress
): property is IUserLegal => {
  return property.type === UserFieldType.LEGAL;
};
const isAddressFilter = (
  property: IUserLegal | IUserAddress
): property is IUserAddress => {
  return property.type === UserFieldType.ADDRESS;
};

export { isAddressFilter, isLegalFilter };
