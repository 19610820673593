import { configureStore } from '@reduxjs/toolkit';
import { pickerApi } from './picker.service';
import { configuratorApi } from './configurator.service';
import { setupListeners } from '@reduxjs/toolkit/query';
import { shoppingCartApi } from '@/shoppingCart/services/shopping-cart.service';
import { authApi } from './auth.service';
import { profileApi } from '@/profile/services/profile.service';
import { default as authReducer } from '@/services/slices/authSlice';
import { default as orderReducer } from '@/services/slices/orderSlice';
import { addressApi } from './adresses.service';
import { legalsApi } from './legals.service';
import { ordersListApi } from '@/orders-list/services';
import { ordersApi } from '@/order/services';

export const store = configureStore({
  reducer: {
    [pickerApi.reducerPath]: pickerApi.reducer,
    [configuratorApi.reducerPath]: configuratorApi.reducer,
    [shoppingCartApi.reducerPath]: shoppingCartApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [addressApi.reducerPath]: addressApi.reducer,
    [legalsApi.reducerPath]: legalsApi.reducer,
    [ordersListApi.reducerPath]: ordersListApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    auth: authReducer,
    order: orderReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      pickerApi.middleware,
      configuratorApi.middleware,
      shoppingCartApi.middleware,
      addressApi.middleware,
      ordersListApi.middleware,
      legalsApi.middleware,
      profileApi.middleware,
      ordersApi.middleware,
      authApi.middleware
    ),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
