import { useEffect, useState } from 'react';
import { BottomBar, ConfiguratorBody, HeaderBar } from './components';
import { MatrixModal } from '../matrix-modal';
import { NotFoundView } from '@/router/views/NotFound';
import {
  $configurationModules,
  $stepStore,
  fetchConfigModules,
  resetStepData,
  setSelectedStep,
  setStepsCount,
} from '@/feed/model';
import { useStore } from 'effector-react';
import { useParams } from 'react-router-dom';
import { useGetConfigurationStepsQuery } from '@/services/configurator.service';
import { ClientStorageService } from '@/common/services/client-storage.service';
import { DiContainer } from '@/di/di-container';
import { useGetPickerDetailsQuery } from '@/services/picker.service';
import { Spin } from 'antd';
import { reformSteps, getCurrentItem } from './utils';
import { ICurrentConfigItem } from '@/feed/ts';
import { ExpertMode } from './components/expert-mode.component';
import { useSelector } from 'react-redux';
import { RootState } from '@/services/store';

const clientStorageService = DiContainer.get(ClientStorageService);

const stepsInitialState: IStep[] = [
  { label: 'уборка', state: 'current' },
  { label: 'cев и миксование удобрений', state: 'next' },
  { label: 'миксование зерновых культур', state: 'next' },
  { label: 'технические опции', state: 'next' },
];

export const Feed = () => {
  // stores
  const stepData = useStore($stepStore);
  const { data } = useStore($configurationModules);

  // api calls
  const { id } = useParams();
  const { data: pickerData, isSuccess: isPickerDetailsSuccess } =
    useGetPickerDetailsQuery(clientStorageService.getUUID());
  const {
    data: optionsData,
    isLoading,
    isSuccess,
    refetch,
  } = useGetConfigurationStepsQuery({
    session: clientStorageService.getUUID(),
    category_id: stepData.current_step,
    picker_id: id,
  });

  useEffect(() => {
    refetch && refetch();
  }, [stepData.current_step, refetch]);

  // local states
  const [steps, setSteps] = useState(stepsInitialState);
  const [activeConfigItem, setActiveConfigItem] = useState(1);
  const [displayMatrix, setDisplayMatrix] = useState(false);
  const [mode, setMode] = useState<'expert' | 'default'>('default');
  const [dataSource, setDataSource] = useState([]);
  const { isAuthorized } = useSelector((state: RootState) => state.auth);

  const getCurrentConfigItem = (): ICurrentConfigItem => {
    return optionsData?.data.items[
      activeConfigItem === 0 ? 0 : activeConfigItem - 1
    ];
  };

  useEffect(() => {
    const modeFromQuery =
      new URLSearchParams(location.search).get('mode') ?? 'default';
    setMode(modeFromQuery as 'expert' | 'default');
    return () => {
      setSelectedStep(0);
      setActiveConfigItem(1);
      resetStepData();
    };
  }, []);

  useEffect(() => {
    // получение шагов конфигурации от api
    if (stepData.steps_count === 0) fetchConfigModules(isAuthorized);
  }, [stepData.steps_count]);

  useEffect(() => {
    if (optionsData?.data?.items.length < 1) {
      console.log('empty cat');
    }
  }, [isSuccess, optionsData?.data?.items.length]);

  useEffect(() => {
    // обновление количества шагов после получения данных с сервера,
    // обновление локального стейта
    if (data) {
      setStepsCount(data?.length || 0);
      reformSteps(data, stepData, setSteps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    // обновление локального стейта при изменении состояния хранилища
    reformSteps(data, stepData, setSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepData]);

  return (
    <>
      {isPickerDetailsSuccess && getCurrentItem(pickerData, id) ? (
        <div className="relative">
          {isLoading && (
            <Spin
              className={`absolute w-full h-full flex items-center justify-center rounded-xl z-[100] bg-[#EEF2FD] transition`}
              size="large"
            />
          )}
          {mode === 'default' ? (
            <>
              <HeaderBar
                getCurrentItem={getCurrentItem}
                id={id}
                pickerData={pickerData}
                steps={steps}
                setActiveConfigItem={setActiveConfigItem}
                mode={mode}
                refetch={refetch}
              />
              <ConfiguratorBody
                getCurrentConfigItem={getCurrentConfigItem}
                setDisplayMatrix={setDisplayMatrix}
                activeConfigItem={activeConfigItem}
                setActiveConfigItem={setActiveConfigItem}
                optionsData={optionsData}
              />
              <BottomBar
                optionsData={optionsData}
                activeConfigItem={activeConfigItem}
                setActiveConfigItem={setActiveConfigItem}
                setDisplayMatrix={setDisplayMatrix}
                getCurrentConfigItem={getCurrentConfigItem}
              />
              <MatrixModal
                id={id}
                setMode={setMode}
                optionsData={optionsData}
                currentConfig={getCurrentConfigItem}
                dataSource={dataSource}
                setDataSource={setDataSource}
                stepData={stepData}
                steps={steps}
                open={displayMatrix}
                closeFn={() => setDisplayMatrix(false)}
                currentItem={getCurrentItem(pickerData, id)}
                activeConfigItem={activeConfigItem}
                setActiveConfigItem={setActiveConfigItem}
              />
            </>
          ) : (
            <ExpertMode
              getCurrentItem={getCurrentItem}
              pickerData={pickerData}
              mode={mode}
              refetchData={refetch}
              id={id}
              optionsData={optionsData}
              currentConfig={getCurrentConfigItem}
              dataSource={dataSource}
              setDataSource={setDataSource as (val: unknown) => void}
              stepData={stepData}
              steps={steps}
              open={displayMatrix}
              closeFn={() => setDisplayMatrix(false)}
              currentItem={getCurrentItem(pickerData, id)}
              activeConfigItem={activeConfigItem}
              setActiveConfigItem={setActiveConfigItem}
            />
          )}
        </div>
      ) : (
        <NotFoundView />
      )}
    </>
  );
};

export interface IStep {
  active?: boolean;
  label: string;
  state: 'completed' | 'current' | 'next';
}
