import { injectable, inject } from 'inversify';
import 'reflect-metadata';
import { ApiService } from '@/api/services/api.service';
import { AxiosResponse } from 'axios';

@injectable()
export class CrudService<T> implements ICrudService<T> {
  protected modelName = '';
  protected tableName = '';

  constructor(@inject(ApiService) protected apiService: ApiService) {}

  find<Res = T>() {
    return this.apiService.get<Res>(`/${this.tableName}`);
  }
  findOne<Res = T>(id: number) {
    return this.apiService.get<Res>(`/${this.tableName}/${id}`);
  }
  create<Res = T>(data: T) {
    return this.apiService.post<Res>(`/${this.tableName}`, data);
  }
  update<Res = T>(id: number, data: T) {
    return this.apiService.put<Res>(`/${this.tableName}/${id}`, data);
  }
  patchUpdate<Res = T>(id: number, data: T) {
    return this.apiService.patch<Res>(`/${this.tableName}/${id}`, data);
  }
  delete<Res = any>(id: number) {
    return this.apiService.delete<Res>(`/${this.tableName}/${id}`);
  }
}

export interface ICrudService<T> {
  find<Res = T>(): Promise<AxiosResponse<Res>>;
  findOne<Res = T>(id: number): Promise<AxiosResponse<Res>>;
  create<Res = T>(data: T): Promise<AxiosResponse<Res>>;
  update<Res = T>(id: number, data: T): Promise<AxiosResponse<Res>>;
  patchUpdate<Res = T>(id: number, data: T): Promise<AxiosResponse<Res>>;
  delete<Res = any>(id: number): Promise<AxiosResponse<Res>>;
}
