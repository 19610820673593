import { IUserLegal } from '@/shared';
import LegalForm from '@/shared/components/forms/LegalForm';
import { LabelCollapse } from '@/shoppingCart/components/shoppingCart/components/labelCollapse/label-collapse';
import { Row, Col, Input, Form, CollapseProps, FormInstance } from 'antd';

interface IProps {
  legal: IUserLegal;
  form: FormInstance<any>;
}

const getCollapseItems = ({ legal, form }: IProps): CollapseProps['items'] => {
  return [
    {
      key: 1,
      label: <LabelCollapse title={'Юридические сведения'} />,
      children: (
        <div className="flex gap-[10px]">
          <LegalForm form={form} legal={legal} />
        </div>
      ),
    },
  ];
};
export default getCollapseItems;
