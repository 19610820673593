import { IConfigurationData, IOptionsData } from '@/feed/ts';
import {
  SlideGatesModal,
  WeightSystemOptionModal,
  WheelsOptionModal,
} from '../../options';
import { useState } from 'react';

export const AdvancedInfoWidget = ({
  data,
  setDisplayMatrix,
  activeConfigItem,
  setActiveConfigItem,
  optionsData,
}: {
  data: IConfigurationData;
  setDisplayMatrix: (val: boolean) => void;
  activeConfigItem: number;
  setActiveConfigItem: (val: number) => void;
  optionsData: IOptionsData;
}) => {
  const [displayModal, setDisplayModal] = useState(false);
  const declineFn = () => {
    if (activeConfigItem === optionsData.data.items.length) {
      setDisplayMatrix(true);
      return;
    }
    setActiveConfigItem(activeConfigItem + 1);
  };

  const approveFn = () => {
    setDisplayModal(false);
    setDisplayMatrix(true);
  }

  return (
    <>
      <div className="widget text-[#292E3A] bg-white rounded-[16px] px-[22px] py-[26px] mb-[16px]">
        <h4 className=" font-bold mb-[10px]">
          {data?.configuration_item?.data?.attributes.display_name}
        </h4>
        <p className="font-light mb-[16px]">{data?.configuration_item?.data?.attributes.description}</p>
        {data?.configuration_item?.data?.attributes?.details && (
          <a
            href="#"
            className="font-bold text-[#409BFF]"
            onClick={() => setDisplayModal(true)}>
            подробнее
          </a>
        )}
      </div>
      {data?.configuration_item?.data?.attributes.name === 'increased_wheel_size' ? (
        <WheelsOptionModal
          declineFn={declineFn}
          approveFn={approveFn}
          visible={displayModal}
          closeFn={() => setDisplayModal(false)}
        />
      ) : data?.configuration_item?.data?.attributes.name === 'weight_system' ? (
        <WeightSystemOptionModal
          declineFn={declineFn}
          approveFn={approveFn}
          visible={displayModal}
          closeFn={() => setDisplayModal(false)}
        />
      ) : data?.configuration_item?.data?.attributes.name === 'hydraulic_slide_gates' ? (
        <SlideGatesModal
          declineFn={declineFn}
          approveFn={approveFn}
          visible={displayModal}
          closeFn={() => setDisplayModal(false)}
        />
      ) : null}
    </>
  );
};
