import { FC, useEffect, useRef, useState } from 'react';

import {
  Collapse,
  CollapseProps,
  Divider,
  Spin,
  Steps,
  Typography,
  notification,
} from 'antd';

import { IOrderIncluded, useGetOrderByIdQuery } from '@/order/services';
import { formatISOtoClassicDate } from '@/common/utils/formatISOtoClassicDate';
import { addSpacesInNumbers } from '@/configurator/helpers/add-spaces-in-numbers';
import bunkerImg from '@/common/images/bunkers/22-28_2.webp';
import placeholder from '@/common/images/placeholder.png';

import chat from './chat.png';
import manager from './manager.png';
import phone from './phone.png';
import mail from './mail.png';
import { OrderStatus } from './components/OrderStatus/OrderStatus';
import './index.styles.scss';

const PaymentStatus: { [key: string]: { color: string; text: string } } = {
  paid: { color: 'rgba(0, 126, 60, 1)', text: 'Оплачено' },
  unpaid: { color: 'rgba(231, 58, 66, 1)', text: 'Не оплачено' },
};

export const OrderInfoView: FC = () => {
  const orderIdRef = useRef(window.location.href.split('/').pop() as string);

  const { data, isLoading, error, isFetching } = useGetOrderByIdQuery(
    orderIdRef.current
  );

  const openCallibriCall = () => {
    const callibriIcon = document.getElementsByClassName(
      'callibri_wrap_pict_tab'
    )[0] as HTMLElement;
    if (callibriIcon) {
      callibriIcon.click();
      document.getElementById('switchForCall')?.click();
    } else {
      const callibriIcon2 = document.getElementsByClassName(
        'callibri_hook_avatar'
      )[0] as HTMLElement;
      if (callibriIcon2) {
        callibriIcon2.click();
        document.getElementById('switchForCall')?.click();
      }
    }
  };

  const openCallibriChat = () => {
    const callibriIcon = document.getElementsByClassName(
      'callibri_wrap_pict_tab'
    )[0] as HTMLElement;
    if (callibriIcon) {
      callibriIcon.click();
      document.getElementById('switchForChat')?.click();
    } else {
      const callibriIcon2 = document.getElementsByClassName(
        'callibri_hook_avatar'
      )[0] as HTMLElement;
      if (callibriIcon2) {
        callibriIcon2.click();
        document.getElementById('switchForChat')?.click();
      }
    }
  };

  useEffect(() => {
    const el = document.getElementsByClassName(
      'second-collapse-expand-icon'
    )[0];
    if (!el) return;
    const iconPos2 = document.getElementById('iconPos2');
    iconPos2?.appendChild(el);
  });

  useEffect(() => {
    const el = document.getElementsByClassName('ant-collapse-expand-icon')[0];
    if (!el) return;
    if (el.classList.contains('mt-9')) return;
    el.classList.add('mt-9');
  });

  if (isLoading || isFetching) {
    return (
      <Spin size="large" className="flex h-full justify-center items-center" />
    );
  }

  if (error || !data) {
    notification.error({
      message: 'Произошла ошибка при получении информации о заказе',
    });
    return <div>Заказ не найден</div>;
  }
  const bunkerNamesByModifications = data.included.reduce<{
    [key: string]: {
      items: {
        [key: string]: IOrderIncluded[];
      };
      itemsLength: number;
      itemsPrice: number;
      basePrice: number;
    };
  }>((acc, item) => {
    const bunkerInfo = item.attributes.name.split('-');
    const bunkerName = bunkerInfo[0] + '-' + bunkerInfo[1];
    const bunkerModification = bunkerInfo[2];
    if (!(bunkerName in acc)) {
      // @ts-ignore
      acc[bunkerName] = {};
      acc[bunkerName].items = {};
      acc[bunkerName].itemsLength = 0;
      acc[bunkerName].itemsPrice = 0;
    }
    acc[bunkerName].itemsLength += 1;
    acc[bunkerName].itemsPrice += Number(item.attributes.amount);
    try {
      acc[bunkerName].items[bunkerModification].push(item);
    } catch (e) {
      acc[bunkerName].items[bunkerModification] = [item];
    }

    return acc;
  }, {});

  const address = `${data.data.attributes.region}, ${data.data.attributes.city}, ${data.data.attributes.address_line}, ${data.data.attributes.actual_address}`;

  const collapseButton = (isActive: boolean | undefined) => (
    <div
      style={{
        color: '#007E3C',
        position: 'relative',
        right: '20px',
        top: '5px',
      }}>
      {isActive ? 'Свернуть' : 'Развернуть'}
    </div>
  );

  const items: CollapseProps['items'] = [
    {
      key: 1,
      label: (
        <div className="label-collapse">
          <div style={{ display: 'flex' }}>
            <h4 className="font-bold text-xl">{'Информация о заказе'}</h4>
          </div>
        </div>
      ),
      children: Object.keys(bunkerNamesByModifications).map(
        (bunkerName, index) => {
          const item = bunkerNamesByModifications[bunkerName];
          const options = {
            key: bunkerName,
            label: (
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-4">
                  <h4 className="font-bold mb-[16px]">
                    Бункер-перегрузчик {bunkerName}
                  </h4>
                  <div id="iconPos2"></div>
                  <p className="text-[#7E89A5]">{item.itemsLength} шт</p>
                </div>
                <div className="flex flex-col items-end">
                  <div>
                    <span>Итого с учетом опций: </span>
                    <span className="font-semibold">
                      {addSpacesInNumbers(item.itemsPrice)} ₽
                    </span>
                  </div>
                </div>
              </div>
            ),
            children: Object.keys(item.items).map((modName) => {
              const innerOptions2 = {
                key: modName,
                label: (
                  <p>
                    Модификация {modName} ({item.items[modName].length}шт)
                  </p>
                ),
                children: item.items[modName].map((mod, index) => {
                  const baseOptions = {
                    key: mod.id,
                    label: (
                      <div className="flex flex-row justify-between">
                        <div className="text-[#7E89A5]">
                          Опции включенные в базовую модификацию (
                          {mod.attributes.selected_steps?.length}шт)
                        </div>
                      </div>
                    ),
                    children: mod.attributes.selected_steps?.map((el) => {
                      return (
                        <div
                          key={el.name}
                          className="flex flex-row justify-between">
                          <p>{el.name}</p>
                        </div>
                      );
                    }),
                  };

                  const extraOptions = {
                    key: mod.id,
                    label: (
                      <div className="flex flex-row justify-between">
                        <div className="text-[#7E89A5]">
                          Дополнительные опции (
                          {mod?.attributes?.extra_items ? mod?.attributes?.extra_items?.length : 0}шт)
                        </div>
                        <div className="text-[#000000] font-semibold">
                          {mod.attributes.extra_items
                            ? addSpacesInNumbers(
                                mod.attributes.extra_items.reduce(
                                  (acc, el) => acc + el.cost,
                                  0
                                )
                              )
                            : 0}{' '}
                          ₽
                        </div>
                      </div>
                    ),
                    children: mod.attributes.extra_items ? (
                      mod.attributes.extra_items?.map((el) => {
                        return (
                          <div
                            key={el.name}
                            className="flex flex-row justify-between">
                            <p>{el.name}</p>
                            <p>{addSpacesInNumbers(el.cost)} ₽</p>
                          </div>
                        );
                      })
                    ) : (
                      <p className="text-[#7E89A5]">опции отсутствуют</p>
                    ),
                  };

                  return (
                    <div key={mod.id}>
                      <div key={1} className="children-collapse">
                        <div className="pt-12">{index + 1}.</div>
                        <img
                          src={mod.attributes.bunker_image_url || placeholder}
                          className="w-[212px] h-[156px] select-none bg-transparent object-contain"
                        />
                        <div className="description-bunker-container pb-7 mb-2">
                          <p className="font-bold">{mod.attributes.name}</p>
                          <Collapse
                            accordion
                            prefixCls="fourth-collapse"
                            bordered={false}
                            items={[baseOptions]}
                            defaultActiveKey={[baseOptions.key]}
                            expandIconPosition="end"
                          />
                          <Collapse
                            accordion
                            prefixCls="fourth-collapse"
                            bordered={false}
                            defaultActiveKey={[extraOptions.key]}
                            items={[extraOptions]}
                            expandIconPosition="end"
                          />
                          <Divider
                            style={{
                              margin: '0px',
                              borderColor: 'rgba(170, 179, 203, 1)',
                            }}
                          />
                          <div className="self-end pt-1">
                            <span>Итого: </span>
                            <span className="font-semibold">
                              {addSpacesInNumbers(mod.attributes.amount)} ₽
                            </span>
                          </div>
                          <div className="self-end pt-1">
                            <span>Итого с учётом скидки: </span>
                            <span className="font-semibold">
                              {addSpacesInNumbers(mod.attributes.amount)} ₽
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }),
              };
              return (
                <Collapse
                  key={modName}
                  accordion
                  bordered={false}
                  prefixCls="third-collapse"
                  defaultActiveKey={[innerOptions2.key]}
                  items={[innerOptions2]}
                  expandIconPosition="end"
                />
              );
            }),
          };
          return (
            <Collapse
              key={index}
              bordered={false}
              items={[options]}
              defaultActiveKey={[options.key]}
              prefixCls="second-collapse"
              expandIconPosition="end"
              className="bunker-collapse"
            />
          );
        }
      ),
    },
  ];

  return (
    <>
      <div className="order-list-page mx-auto max-w-[1800px] w-[90%]">
        <Steps
          current={3}
          style={{ marginBottom: '20px', width: '70%', margin: '0 auto' }}
          items={[
            {
              title: (
                <Typography style={{ color: '#007E3C' }}>
                  Собрать корзину
                </Typography>
              ),
            },
            {
              title: (
                <Typography style={{ color: '#007E3C' }}>
                  Оформить заказ
                </Typography>
              ),
            },
            {
              title: (
                <Typography style={{ color: '#007E3C' }}>
                  Заказ оформлен
                </Typography>
              ),
            },
          ]}
        />

        <div className="py-[16px] grid grid-cols-10 gap-[40px]">
          <div className="col-span-4">
            <div className="flex">
              <Typography.Title level={5}>
                Заказ №{data.data.id} от {formatISOtoClassicDate(new Date())}
              </Typography.Title>
              <Typography
                style={{
                  color: '#007E3C',
                  fontWeight: 'bold',
                  fontSize: '18px',
                }}
                className="ml-[26px]">
                Заказ доставлен
              </Typography>
            </div>
          </div>
          <div className="col-span-10">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-7">
                <Collapse
                  onChange={() => {
                    setTimeout(() => {
                      const el = document.getElementsByClassName(
                        'second-collapse-expand-icon'
                      )[0];
                      if (!el) return;
                      const iconPos2 = document.getElementById('iconPos2');
                      iconPos2?.appendChild(el);
                    });
                  }}
                  defaultActiveKey={[1]}
                  expandIconPosition="end"
                  className="pretty-collapse-border flex flex-col gap-[32px]"
                  bordered={false}
                  items={items}
                  expandIcon={(props) => collapseButton(props!.isActive)}
                />
                <div className="bg-[#ffffff] pretty-border rounded-[24px] p-8 mt-8 flex flex-row justify-between items-center">
                  <div className="w-full">
                    <div className="font-bold text-xl">
                      Ваш персональный менеджер
                    </div>
                    <div className="pt-8 grid grid-cols-3 w-full">
                      <div className="col col-span-2 w-full">
                        <div className="flex flex-row gap-4">
                          <div>
                            <img src={manager} alt="" />
                          </div>
                          <div className="flex flex-col gap-2">
                            <div className="font-semibold text-lg">Ярослав</div>
                            <div className="flex flex-row gap-2 font-semibold text-lg  items-center">
                              <img
                                className="w-[24px] h-[16px]"
                                src={mail}
                                alt=""
                              />{' '}
                              <a href="https://t.me/liliani_zavod">
                                @liliani_zavod
                              </a>
                            </div>
                            <div>09:00 - 18:00 (пн-пт)</div>
                          </div>
                          <div className="flex flex-row gap-2 items-center">
                            <img
                              className="w-[24px] h-[24px]"
                              src={phone}
                              alt=""
                            />
                            <div>{'+ 7 863 322 02 31'}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col col-span-1 w-full">
                        <div className="flex flex-col items-end text-white">
                          <button
                            className="mt-2 p-3 pt-2 pb-2 hover:opacity-80 cursor-pointer rounded-[12px] callback-button text-white font-semibold"
                            onClick={openCallibriCall}>
                            Обратный звонок
                          </button>
                          <button
                            className="mt-3 p-3 pt-2 pb-2 hover:opacity-80 cursor-pointer rounded-[12px] bg-blue-600 text-white font-semibold flex align-center"
                            onClick={openCallibriChat}>
                            <img className="mr-2" src={chat} alt="" />
                            Чат с менеджером
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-[#ffffff] pretty-border rounded-[24px] p-8 pb-4 mt-8 flex flex-row justify-between items-center">
                  <div className="w-full">
                    <div className="font-bold text-xl">Документы</div>
                    <div className="pt-8 w-full">
                      <div
                        style={{
                          borderBottom: '1px solid rgba(223, 230, 250, 1)',
                        }}
                        className="pt-4 pb-4">
                        <div className="grid grid-cols-3 text-lg">
                          <div className="col-span-1 font-semibold">
                            счёт фактура
                          </div>
                          <div className="col-span-1 flex justify-center">
                            27.12.2022
                          </div>
                          <div className="col-span-1 flex justify-end">
                            <span className="font-semibold text-gradient">
                              скачать
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          borderBottom: '1px solid rgba(223, 230, 250, 1)',
                        }}
                        className="pt-3 pb-3">
                        <div className="grid grid-cols-3 text-lg">
                          <div className="col-span-1 font-semibold">
                            акт об оказании услуг
                          </div>
                          <div className="col-span-1 flex justify-center">
                            27.12.2022
                          </div>
                          <div className="col-span-1 flex justify-end">
                            <span className="font-semibold text-gradient">
                              скачать
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          borderBottom: '1px solid rgba(223, 230, 250, 1)',
                        }}
                        className="pt-3 pb-3">
                        <div className="grid grid-cols-3 text-lg">
                          <div className="col-span-1 font-semibold">
                            товарно-транспортная накладная
                          </div>
                          <div className="col-span-1 flex justify-center">
                            27.12.2022
                          </div>
                          <div className="col-span-1 flex justify-end">
                            <span className="font-semibold text-gradient">
                              скачать
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          borderBottom: '1px solid rgba(223, 230, 250, 1)',
                        }}
                        className="pt-3 pb-3">
                        <div className="grid grid-cols-3 text-lg">
                          <div className="col-span-1 font-semibold">счет</div>
                          <div className="col-span-1 flex justify-center">
                            27.12.2022
                          </div>
                          <div className="col-span-1 flex justify-end">
                            <span className="font-semibold text-gradient">
                              скачать
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="pt-3 pb-3">
                        <div className="grid grid-cols-3 text-lg">
                          <div className="col-span-1 font-semibold">
                            спецификация
                          </div>
                          <div className="col-span-1 flex justify-center">
                            27.12.2022
                          </div>
                          <div className="col-span-1 flex justify-end">
                            <span className="font-semibold text-gradient">
                              скачать
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-5">
                <div className="bg-[#ffffff] pretty-border p-6 rounded-[24px] pl-8 pr-24 flex flex-row justify-between items-center">
                  <div className="font-bold text-xl">Оплата</div>
                  <div className="flex flex-col pr-8 text-lg">
                    <div className={`text-[#7E89A5]`}>Статус оплаты</div>
                    <div
                      style={{
                        color:
                          PaymentStatus[data.data.attributes.payment_status]
                            .color,
                      }}>
                      {PaymentStatus[data.data.attributes.payment_status].text}
                    </div>
                  </div>
                </div>
                <div
                  className="bg-[#ffffff] pretty-border rounded-[24px] p-8 mt-8 flex items-center"
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                  <div className="font-bold text-xl">Доставка</div>
                  <div className="grid grid-cols-2 gap-3">
                    <div>Вид доставки:</div>
                    <div>Доставка производителем</div>
                    <div>Адрес</div>
                    <div>{address}</div>
                    <div>Дата доставки</div>
                    <div>10-13 января 2024</div>
                    <div>Время доставки</div>
                    <div>11:00-22:00</div>
                    <div>Стоимость доставки</div>
                    <div className="font-bold"> 3 680 ₽</div>
                  </div>
                </div>
                <div className="bg-[#ffffff] pretty-border rounded-[24px] p-8 pb-0 mt-8 flex flex-row">
                  <div className="w-full">
                    <div className="font-bold text-xl">Статус</div>
                    <OrderStatus status={data.data.attributes.status} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
