import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BASE_URL,
  TEST_URL,
  // DEFAULT_HEADERS,
  // DEFAULT_TIMEOUT,
} from '@/api/config/api.config';
import { IShoppingCartResponse } from '../../shared/types/shopping-cart.types';
import { RootState } from '@/services/store';

export const shoppingCartApi = createApi({
  reducerPath: 'shoppingCartApi',
  baseQuery: fetchBaseQuery({
    baseUrl: TEST_URL,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    getShoppingCartDetails: builder.query<IShoppingCartResponse, string>({
      query: (session) => ({
        url: '/shopping_cart',
        headers: { 'Guest-Uuid': session },
        params: localStorage.getItem('shared_uuid')
          ? {
              shared: localStorage.getItem('shared_uuid'),
            }
          : {},
      }),
    }),
    addToShoppingCart: builder.mutation({
      query: ({ id, session }: { id: string; session: string }) => ({
        url: `/shopping_cart/add${id ? '?id=' + id : ''}`,
        headers: { 'Guest-Uuid': session },
        method: 'POST',
      }),
    }),
    clearShoppingCart: builder.mutation({
      query: (session) => ({
        url: '/shopping_cart/clear_cart',
        headers: { 'Guest-Uuid': session },
        method: 'DELETE',
      }),
    }),
    deleteStackFromCart: builder.mutation({
      query: ({ id, session }: { id: string; session?: string }) => ({
        url: `/shopping_cart/remove_picker${id ? '?id=' + id : ''}`,
        headers: session ? { 'Guest-Uuid': session } : {},
        method: 'DELETE',
      }),
    }),
    deleteStacksFromCart: builder.mutation({
      query: ({ ids, session }: { ids: string[]; session?: string }) => ({
        url: `/shopping_cart/remove_pickers${
          ids.length ? '?id=' + ids.toString() : ''
        }`,
        headers: session ? { 'Guest-Uuid': session } : {},
        method: 'DELETE',
      }),
    }),
    deleteBunkerFromStack: builder.mutation({
      query: ({ id, session }: { id: string; session: string }) => ({
        url: `/shopping_cart/remove_item${id ? '?item_id=' + id : ''}`,
        headers: { 'Guest-Uuid': session },
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useClearShoppingCartMutation,
  useAddToShoppingCartMutation,
  useDeleteBunkerFromStackMutation,
  useDeleteStacksFromCartMutation,
  useDeleteStackFromCartMutation,
  useGetShoppingCartDetailsQuery,
} = shoppingCartApi;
