import { DiContainerInjectFn } from '@/di/ts';
import { CrudService } from './services/crud.service';
import { ClientStorageService } from '@/common/services/client-storage.service';

export default ((container) => {
  container
    .bind(ClientStorageService)
    .to(ClientStorageService)
    .inSingletonScope();
  container.bind(CrudService).to(CrudService).inSingletonScope();
}) as DiContainerInjectFn;
