export const generateContent = (configurationData: any, data: any, currentItem: any, cols: any[]) => {
  const configSteps = configurationData?.data?.items[0]?.steps.length || 0;
  // собирает контент для таблицы, вычисляя кол-во опций и создает итоговый массив объектов
  const result = [];
  for (let i = 1; i <= configSteps; i++) {
    const rowData: Record<string, unknown> = {};
    cols.forEach((column) => {
      // берем из апи нужную нам опцию и в ней выбираем текущий бункер
      const currentBunker = data?.data.items.find(
        (item: any) => item.name === column.dataIndex
      )?.steps[i - 1];
      rowData['picker_option_id'] = currentBunker?.picker_option_id;
      if (column.dataIndex === 'product') {
        rowData[column.dataIndex] = `${currentItem?.bunker.bunker_model
          }`;
        return;
      }
      // Динамически создаем ключи объекта на основе dataIndex из структуры столбцов
      rowData[column.dataIndex + '_val'] = currentBunker?.enabled
        ? true
        : false;
    });
    result.push(rowData);
  }
  return result;
};
