import { ClientStorageService } from '@/common/services/client-storage.service';
import { DiContainer } from '@/di/di-container';
import {
  useAuthQuery,
  useCheckAuthUserMutation,
  useMakeAuthUserMutation,
} from '@/services/auth.service';
import { useEffect, useLayoutEffect, useState } from 'react';

const clientStorageService = DiContainer.get(ClientStorageService);
export const useAuth = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const uuid = clientStorageService.getUUID();
  const [checkAuth] = useCheckAuthUserMutation();
  const [makeAuth] = useMakeAuthUserMutation();

  const checkAuthenticated = async () => {
    try {
      const res = await checkAuth(uuid).unwrap();

      if (res.isValid) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    } catch (e) {
      const authRes = await makeAuth(uuid).unwrap();

      console.log(authRes);

      setAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    checkAuthenticated();
  }, []);
  return [authenticated, loading];
};
