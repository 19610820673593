import { injectable } from 'inversify';
import 'reflect-metadata';

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  BASE_URL,
  DEFAULT_HEADERS,
  DEFAULT_TIMEOUT,
} from '@/api/config/api.config';

@injectable()
export class ApiService {
  axios: AxiosInstance;
  constructor() {
    // console.log('env', import.meta.env);
    const options = {
      baseURL: import.meta.env.VITE_LOCAL_SERVER == 'true' ? '/api' : BASE_URL,
      timeout: DEFAULT_TIMEOUT,
      headers: DEFAULT_HEADERS,
    };
    this.axios = axios.create(options);
  }

  setToken(token: string) {
    this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  removeToken() {
    delete this.axios.defaults.headers.common['Authorization'];
  }

  // axios.AxiosRequestConfig - без 'axios.' почему-то ошибка типов
  get<T>(endpoint: string, config?: AxiosRequestConfig) {
    return this.axios.get<T>(endpoint, config);
  }
  post<T>(endpoint: string, data: any, config?: AxiosRequestConfig) {
    return this.axios.post<T>(endpoint, data, config);
  }
  put<T>(endpoint: string, data: any, config?: AxiosRequestConfig) {
    return this.axios.put<T>(endpoint, data, config);
  }
  patch<T>(endpoint: string, data: any, config?: AxiosRequestConfig) {
    return this.axios.patch<T>(endpoint, data, config);
  }
  delete<T>(endpoint: string, config?: AxiosRequestConfig) {
    return this.axios.delete<T>(endpoint, config);
  }
}
