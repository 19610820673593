import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ArrowLeftOutlined } from '@ant-design/icons';

const ScrollToRightButton = () => {
  const [direction, setDirection] = useState<'left' | 'right'>('left');
  const tableEl = useRef(document.getElementsByClassName('ant-table-body')!);

  const toggleVisible = () => {
    const scrolled = tableEl?.current[0]?.scrollLeft;
    if (scrolled === 0) {
      setDirection('right');
    } else if (scrolled !== 0) {
      setDirection('left');
    }
  };

  tableEl?.current[0]?.addEventListener('scroll', toggleVisible);
  useEffect(() => {
      toggleVisible();
  }, []);
  const scrollToRight = () => {
    tableEl?.current[0]?.scrollTo({
      left: tableEl?.current[0]?.scrollWidth,
      behavior: 'smooth',
    });
  };
  const scrollToLeft = () => {
    tableEl?.current[0]?.scrollTo({
      left: 0,
      behavior: 'smooth',
    });
  };

  console.log(direction);

  return (
    <>
      {direction === 'left' && (
        <Button
          style={{
            position: 'fixed',
            width: '40px',
            right: '2%',
            borderRadius: '50%',
            bottom: '75px',
            height: '40px',
            zIndex: '1000',
            cursor: 'pointer',
            color: 'green',
            display: 'block',
          }}
          onClick={scrollToLeft}>
          <ArrowRightOutlined
            style={{
              fontSize: '20px',
              marginLeft: '-8px',
              marginTop: '3px',
              transform: 'rotate(180deg)',
              translate: '0 0',
            }}
          />
        </Button>
      )}
      {direction === 'right' && (
        <Button
          style={{
            position: 'fixed',
            width: '40px',
            right: '2%',
            borderRadius: '50%',
            bottom: '75px',
            height: '40px',
            zIndex: '1000',
            cursor: 'pointer',
            color: 'green',
            display: 'block',
          }}
          onClick={scrollToRight}>
          <ArrowRightOutlined
            style={{
              fontSize: '20px',
              marginLeft: '-8px',
              marginTop: '3px',
            }}
          />
        </Button>
      )}
    </>
  );
};

export default ScrollToRightButton;
