import { useState } from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
type MenuItem = Required<MenuProps>['items'][number];
enum MenuItems {
  orderContacts = 'orderContacts',
  deliveryInfo = 'deliveryInfo',
  legalInfo = 'legalInfo',
}
const items: MenuItem[] = [
  {
    label: <span className="flex items-center gap-2">Контактные данные</span>,
    key: MenuItems.orderContacts, //'orderContacts',
  },
  {
    label: (
      <span className="flex items-center gap-2">Доставка и получение</span>
    ),
    key: MenuItems.deliveryInfo, //'deliveryInfo',
  },
  {
    label: (
      <span className="flex items-center gap-2">Юридические сведения</span>
    ),
    key: MenuItems.legalInfo, //'legalInfo',
  },
];

type Props = {
  scrollToInput: (menuItem: MenuItems) => void;
  setActiveKeys: React.Dispatch<React.SetStateAction<object>>;
};

const useMenu = ({ scrollToInput, setActiveKeys }: Props) => {
  const [current] = useState<MenuItems[]>([]);

  const onClick: MenuProps['onClick'] = ({ key }) => {
    setActiveKeys((prev) => {
      return { ...prev, [key]: true };
    });
    setTimeout(() => scrollToInput(key as MenuItems), 300);
  };
  return {
    Menu: (
      <Menu
        onClick={onClick}
        selectedKeys={current}
        mode="horizontal"
        items={items}
        style={{
          background: '#EEF2FD',
          paddingBottom: '30px',
          borderBottom: 'none',
        }}
      />
    ),
    selectedOptions: current,
  };
};

export { useMenu, MenuItems };
