import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BASE_URL,
  TEST_URL,
  //   DEFAULT_HEADERS,
  //   DEFAULT_TIMEOUT,
} from '@/api/config/api.config';
import { RootState } from './store';

export const configuratorApi = createApi({
  reducerPath: 'configuratorApi',
  baseQuery: fetchBaseQuery({
    baseUrl: TEST_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      const isAuthorized = (getState() as RootState).auth.isAuthorized;
      if (isAuthorized) {
        headers.delete('Guest-Uuid');
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAvailableBunkers: builder.query({
      query: () => `/bunker_prices`,
    }),
    getCategories: builder.query({
      query: () => `/categories`,
    }),
    getConfigurationItems: builder.query({
      query: (id) => `/configuration_items?category_id=${id}`,
    }),
    getConfigurationSteps: builder.query({
      query: ({ session, category_id, picker_id }) => ({
        url: `/configuration_steps?category_id=${category_id}&picker_id=${picker_id}`,
        headers: { 'Guest-Uuid': session },
        keepUnusedDataFor: 0,
      }),
    }),
    getConfigurationCost: builder.mutation({
      query: ({ session, picker_id }) => ({
        url: `/configuration_steps?picker_id=${picker_id}`,
        headers: { 'Guest-Uuid': session },
        keepUnusedDataFor: 0,
        method: 'GET',
      }),
    }),
    getNextStepItems: builder.mutation({
      query: ({ session, category_id, picker_id }) => ({
        url: `/configuration_steps?category_id=${category_id}&picker_id=${picker_id}`,
        headers: { 'Guest-Uuid': session },
      }),
    }),
  }),
});

export const {
  useGetAvailableBunkersQuery,
  useGetCategoriesQuery,
  useGetConfigurationItemsQuery,
  useGetConfigurationStepsQuery,
  useGetNextStepItemsMutation,
  useGetConfigurationCostMutation,
} = configuratorApi;
