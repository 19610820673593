import { createEvent, createStore, UnitValue } from 'effector';
import { IGlobalModalProps } from '@/modals/ts';

// Не использовать напрямую, только через ModalService иначе не будет правильной типизации
export const openModal = createEvent<UnitValue<typeof $modal>>('openModal');
export const closeModal = createEvent('closeModal');

export const $modal = createStore<{
  component: ((props: ModalProps) => JSX.Element) | null;
  props: Omit<ModalProps, 'open' | 'onCancel'>;
}>({ component: null, props: {} }, { name: 'modal' });
$modal.on(openModal, (state, payload) => payload);
$modal.on(closeModal, () => ({ component: null, props: {} }));

type ModalProps = IGlobalModalProps & object;
