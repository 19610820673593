export function PrimaryLayoutFooter() {
  return (
    <>
      <div
        style={{
          height: '100px',
          borderTop: '1px solid #66B28A',
          background: '#C8E7FF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        Здесь будет футер
      </div>
    </>
  );
}
