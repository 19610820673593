import { addSpacesInNumbers } from '@/configurator/helpers/add-spaces-in-numbers';
import { ConfigurationItem, IOptionsData } from '@/feed/ts';

export const BusinessWidget = ({ data, showModal, optionsData }: { data: {
  attributes: ConfigurationItem;
  id: string;
  type: string;
}, showModal: () => void; optionsData: IOptionsData; }) => {
  return (
    <>
      <div className="widget bg-white rounded-[16px] px-[22px] py-[26px] mb-[16px]">
        <div className="row mb-[8px]">
          <span className="text-[#292E3A] block text-[15px] mb-[5px]">
            стоимость базового бункера (без учета опций)
          </span>
          <span className="mb-[11px] font-bold text-[#7E89A5]">
            {addSpacesInNumbers(optionsData.data.initial_cost)} <span>₽</span>
          </span>
        </div>
        <div className="row mb-[8px]">
          <span className="text-[#292E3A] block text-[15px] mb-[5px]">
            стоимость ранее выбранных опций
          </span>
          {
            <span className="mb-[11px] text-green-main font-bold">
              {' '}
              + {optionsData?.data?.actual_cost?.toLocaleString('RU-ru')}{' '}
              <span>₽</span>
            </span>
          }
        </div>
        <div className="row">
        </div>
      </div>
      <div className="options">
      {data?.attributes?.profit_items?.map((item, i) => (
        <div key={i} className="option__item text-[16px] rounded-[16px] bg-white px-[10px] py-[16px] mb-[12px]">
          {item}
        </div>
      ))}
        
      </div>
    </>
  );
};
