import { createEvent, createStore } from 'effector';
import { ISelectedBunker } from '@/configurator/ts/catalog.types';

export const openSourceModal = createEvent();
export const closeSourceModal = createEvent();
export const attentionDialogState = createEvent<boolean>();
export const catalogDialogState = createEvent<boolean>();

export const $sourceDialogModal = createStore<boolean>(false)
  .on(openSourceModal, () => true)
  .on(closeSourceModal, () => false);

export const $attentionDialogModal = createStore<boolean>(false).on(
  attentionDialogState,
  (prev, state) => {
    return state;
  }
);

export const $catalogDialogModal = createStore<boolean>(false).on(
  catalogDialogState,
  (prev, state) => state
);

export const changeTechniqueCount = createEvent<ISelectedBunker>();

export const startConfigure = createEvent();

export const $preSelectedTechnique = createStore<ISelectedBunker[]>([]).on(
  changeTechniqueCount,
  (state, data) => {
    const bunker = state.find((b) => b.bunker.id == data.bunker.id);
    if (!bunker) state.push(data);
    else bunker.count = data.count;
    return state;
  }
);

export const $selectedTechnique = createStore<ISelectedBunker[]>([]).on(
  startConfigure,
  () => {
    return $preSelectedTechnique.getState();
  }
);
