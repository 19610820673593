import { useRef } from 'react';

type DeferredPromise = {
  resolve: (value: boolean) => void;
  reject: (value: boolean) => void;
  promise: Promise<boolean>;
};

export const useDeferredPromise = () => {
  const deferredRef = useRef<DeferredPromise | null>(null);

  const defer = () => {
    const deferred = {} as DeferredPromise;

    const promise = new Promise<boolean>((resolve, reject) => {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });

    deferred.promise = promise;
    deferredRef.current = deferred;
    return deferredRef.current as DeferredPromise;
  };

  return { defer, deferredRef: deferredRef.current as DeferredPromise };
};
