import { AntdConfigProvider } from '@/antd/components/AntdConfigProvider';
import { GlobalModalController } from '@/modals/component/GlobalModalController';
import {
  useCheckAuthUserMutation,
  useMakeAuthUserMutation,
} from './services/auth.service';
import { DiContainer } from './di/di-container';
import { ClientStorageService } from './common/services/client-storage.service';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuthorized } from './services/slices/authSlice';
import { RootState } from './services/store';
import { AppRouter } from './router/index.router';

export function App() {
  console.log('Front deployed');
  const dispatch = useDispatch();
  const { isAuthorized } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(true);
  const clientStorageService = DiContainer.get(ClientStorageService);

  const uuid = clientStorageService.getUUID();

  const [auth] = useCheckAuthUserMutation();
  const [makeAuth] = useMakeAuthUserMutation();

  useEffect(() => {
    const checkAuthenticated = async () => {
      try {
        const res = await auth(uuid).unwrap();
        if (res.isValid) {
          dispatch(setIsAuthorized(true));
        } else {
          dispatch(setIsAuthorized(false));
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        try {
          await makeAuth(uuid).unwrap();
        } catch (e) {
          console.log(e);
        }
        dispatch(setIsAuthorized(false));
        setLoading(false);
      }
    };
    if (!isAuthorized) {
      checkAuthenticated();
    }
  }, []);

  return (
    <AntdConfigProvider>
      <AppRouter isLoading={loading} />
      <GlobalModalController />
    </AntdConfigProvider>
  );
}
