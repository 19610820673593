import { IInitialStepState } from '@/feed/model';
import cornerCompleted from '../../../images/corner-complete.svg';
import cornerCurrent from '../../../images/corner-current.svg';
import cornerNext from '../../../images/corner-next.svg';
import { IStep } from '../index.component';
import { notification } from 'antd';

export const StepsItem = ({
  item,
  i,
  steps,
  onChange,
  stepData,
  mode,
}: IStepsItem) => {
  return (
    <li
      onClick={
        onChange
          ? () => {
              if (mode === 'default')
                return notification.warning({
                  message:
                    'Для перехода на следующий шаг требуется завершить настройку на текущем этапе',
                });
              onChange(i || 0);
            }
          : () => null
      }
      className={`step__item flex items-center w-1/4 cursor-pointer ${
        item.state === 'completed'
          ? 'bg-[#E1F3EA]'
          : item.state === 'current'
            ? 'bg-[#66B28A]'
            : 'transparent'
      }`}>
      <span
        className={`block w-full text-center text-[16px] select-none ${
          item.state === 'completed'
            ? 'font-bold text-[#007E3C]'
            : item.state === 'current'
              ? 'font-bold text-white'
              : 'font-light text-[#292E3A]'
        }`}>
        {item.label}
      </span>
      {steps.length !== i + 1 && (
        <img
          src={
            item.state === 'completed'
              ? cornerCompleted
              : item.state === 'current'
                ? cornerCurrent
                : cornerNext
          }
          className={`translate-x-[1px] select-none ${
            steps[i + 1].state === 'completed'
              ? 'bg-[#E1F3EA]'
              : steps[i + 1].state === 'current'
                ? 'bg-[#66b28a]'
                : steps[i + 1].state === 'next'
                  ? 'bg-[#fff]'
                  : 'bg-transparent'
          }`}
          alt="corner"
        />
      )}
    </li>
  );
};

interface IStepsItem {
  item: IStep;
  i: number;
  steps: IStep[];
  onChange?: (i: number) => void;
  stepData: IInitialStepState;
  mode: 'expert' | 'default';
}
