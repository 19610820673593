import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IStep, StepsBar } from '../../steps-bar';
import { ISelectedBunker } from '@/configurator/ts/catalog.types';
import { IPickerData } from '@/feed/ts/picker.types';
import { setCurrentStep, setSelectedStep } from '@/feed/model';
import { Modal } from 'antd';
import { $stepStore } from '@/feed/model';
import { useStore } from 'effector-react';
import { useEffect } from 'react';

export const HeaderBar = ({
  getCurrentItem,
  id,
  pickerData,
  steps,
  setActiveConfigItem,
  mode,
  refetch,
  handleApply,
}: IHeaderBar) => {
  const location = useLocation();
  const navigate = useNavigate();

  const configId = location.pathname.split('/')[2];

  const currentConfig = pickerData.data.find(
    (item) => item.id.toString() === configId
  );

  const onNavigateClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (configId && currentConfig?.previously_configured) {
      Modal.confirm({
        title: 'Подтвердите действие',
        content: 'Для перехода в конфигуратор, Вам нужно сохранить изменения.',
        okText: 'продолжить',
        cancelText: 'назад',
      });
    } else {
      navigate('/configurator');
    }
  };

  const stepData = useStore($stepStore);

  const setStep = (i: number) => {
    const tableStep = i + 1;
    mode === 'expert'
      ? stepData.selected_step !== tableStep
        ? (() => {
            setActiveConfigItem(1);
            setSelectedStep(tableStep);
            setCurrentStep(tableStep);
          })()
        : null
      : (() => {
          setActiveConfigItem(1);
          setSelectedStep(tableStep);
          setCurrentStep(
            stepData.current_step > tableStep
              ? stepData.current_step
              : tableStep
          );
        })();
    handleApply && handleApply(false, false, i);
  };
  return (
    <div className="header py-[26px]">
      <div className="flex items-center justify-between mb-[26px]">
        <h3 className="text-[20px] font-light">
          Выбор дополнительных опций{' '}
          <span className="model__name font-bold">
            {getCurrentItem(pickerData, id)?.bunker?.modification}
          </span>{' '}
          <span className="qty">
            ({getCurrentItem(pickerData, id)?.quantity} шт.)
          </span>
        </h3>
        <div>
          <Link
            to="/configurator"
            className="hover:text-green-main active:text-green-main/[.9] underline text-sm"
            onClick={(e) => onNavigateClick(e)}>
            Вернуться на главную
          </Link>
        </div>
      </div>

      <div className="header__steps rounded-lg overflow-hidden">
        <StepsBar onChange={setStep} steps={steps} mode={mode} />
      </div>
    </div>
  );
};

interface IHeaderBar {
  getCurrentItem: (
    pickerData: IPickerData,
    id: string | number | undefined
  ) => ISelectedBunker | null;
  id: number | string | undefined;
  pickerData: IPickerData;
  steps: IStep[];
  setActiveConfigItem: (val: number) => void;
  mode: 'default' | 'expert';
  refetch?: () => void;
  handleApply?: (
    save?: boolean,
    switchToNextStep?: boolean,
    step?: number
  ) => void;
}
