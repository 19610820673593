/**
 * Доступ к переменнам из JS необходм для настройки сторонних библиотек, например 'antd'
 *
 * При добавлении, редактирование или удалении переменных,
 * необходимо аналогично изменить файл @/common/styles/_variables.style
 */

export const PRIMARY_COLOR = '#007E3C';
export const BG_BASE_COLOR = '#ffffff';
export const BG_CONTAINER_COLOR = '#ffffff';
export const BG_SECONDARY_COLOR = '#F7F6F7';

export const FONT_SIZE = 16;

export const TEXT_COLOR = '#000000';
export const TEXT_SECONDARY_COLOR = '#9892A0';
export const TEXT_DESCRIPTION_COLOR = '#5C5762';

export const PADDING_CONTENT_HORIZONTAL = 16;
export const PADDING_CONTENT_VERTICAL = 18;

export const BORDER_RADIUS = 10;
export const BORDER_COLOR = '#AAB3CB';
export const BORDER_SECONDARY_COLOR = '#f0f0f0';
export const GREY = "#7E89A5"