import { Spin } from 'antd';
import { useRef } from 'react';

interface ICounter {
  count: number;
  setCount: (num: number) => void;
  loading: boolean;
  minValue?: number;
  idx: number;
}

export const Counter = ({
  count,
  setCount,
  loading,
  minValue = 1,
}: ICounter) => {
  const inputRef = useRef(null);
  return (
    <div className="counter__wrap flex items-center">
      <div className="counter inline-flex p-[5px] items-center rounded-[12px] overflow-hidden border-solid border-[2px] border-[#007E3C]">
        <span
          onClick={() => {
            count > minValue && setCount(count - 1);
          }}
          className="minus__button cursor-pointer flex items-center h-[12px] w-[12px] active:opacity-75">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="2"
            viewBox="0 0 16 2"
            fill="none">
            <path
              d="M1 1H15"
              stroke={loading ? '#FFF' : '#292E3A'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <div className="relative">
          {loading ? (
            <Spin
              className="min-w-[82px] bg-white"
              size={'default'}
            />
          ) : (
            <input
              ref={inputRef}
              // onChange={(e) => {
              //   const resultVal = +e.target.value.replace(/\D/g, '');
              //   resultVal !== 0 && setCount(resultVal);
              // }}
              type="text"
              max={10}
              className="outline-none mx-[8px] w-[66px] bg-transparent text-center select-none"
              value={count}
              onChange={(e) => setCount(Number(e.target.value))}
              disabled={true}
            />
          )}
        </div>
        <span
          onClick={() => {
            count < 10 && setCount(count + 1);
          }}
          className="plus__button cursor-pointer flex items-center h-[12px] w-[12px] active:opacity-75 block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none">
            <path
              d="M7 1V13M13 7L1 7"
              stroke={loading ? '#FFF' : '#292E3A'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <span className="ml-[10px] font-bold text-[16px] select-none">шт</span>
    </div>
  );
};
