import React from 'react';
import { ConfigProvider } from 'antd';
import { ANTD_CONFIG_PROVIDER_THEME } from '@/antd/config/index.config';

export function AntdConfigProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ConfigProvider theme={ANTD_CONFIG_PROVIDER_THEME}>
      {children}
    </ConfigProvider>
  );
}
