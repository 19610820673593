import { Checkbox, Typography } from 'antd';
import { IShoppingCartItem } from '@/shared/types/shopping-cart.types';
import { addSpacesInNumbers } from '@/configurator/helpers/add-spaces-in-numbers';

import './label-collapse.styles.scss';

interface IProps {
  title?: string;
  modification?: IShoppingCartItem;
  quantity?: number;
  handleCheck?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const LabelCollapse = ({
  title,
  modification,
  quantity,
  handleCheck,
}: IProps) => {
  return (
    <div className="label-collapse">
      <div style={{ display: 'flex' }}>
        {handleCheck && (
          <Checkbox
            className="checkbox-green"
            defaultChecked={true}
            onClick={(e: React.MouseEvent<HTMLElement>) => handleCheck(e)}
          />
        )}
        <div className="label-main-info">
          <h4 className="font-bold mb-[16px]">
            {title || 'Информация о заказе'}
          </h4>
        </div>
        {quantity && (
          <p style={{ paddingLeft: '25px' }}>&nbsp;&nbsp;({quantity}шт)</p>
        )}
      </div>
      {modification && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '16px',
            width: '280px',
          }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            итого с учетом опций:{' '}
            <Typography style={{ fontWeight: 'bold' }}>
              &nbsp;{addSpacesInNumbers(modification.total)} ₽
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};
