import { inject, injectable } from 'inversify';
import { ApiService } from '@/api/services/api.service';
import { IConfiguratorData } from '@/configurator/ts';
import { ISelectedBunker } from '@/configurator/ts/catalog.types';

@injectable()
export class ConfiguratorService {
  constructor(@inject(ApiService) private apiService: ApiService) {}

  async getBunkers() {
    return this.apiService.get<IConfiguratorData>('/bunker_prices');
  }

  async setBulkBunkers(bunkers: ISelectedBunker[], session: string) {
    const data = bunkers.map((b) => ({
      bunker_id: Number(b.bunker.id),
      quantity: Number(b.count),
    }));
    return this.apiService.post(
      '/pickers/bulk_add',
      {
        data,
      },
      { headers: { 'Guest-Uuid': session } }
    );
  }

  async getCategoriesList(session: string, isAuthorized: boolean) {
    return this.apiService.get('/categories', {
      headers: isAuthorized ? {} : { 'Guest-Uuid': session },
    });
  }

  async getPicker(session: string) {
    return this.apiService.get('/pickers', {
      headers: { 'Guest-Uuid': session },
    });
  }
}
