import { IInitialStepState } from '@/feed/model';
import questionSvg from './../images/question.svg';
import { IConfigurationData } from '@/feed/ts';
import { Tooltip } from 'antd';
import Modal from 'antd/es/modal/Modal';

export const generateCols = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  configurationData: any,
  currentConfig: () => IConfigurationData,
  stepData: IInitialStepState,
  activeConfigItem: number,
  setActiveConfigItem: (val: number) => void,
  applyChanges: () => void,
  showImages: boolean,
  allowExpertLevel: boolean,
  setDetailsModalOpen: (val: { isOpen: boolean , name: string, description: string, image: string}) => void,
  setOptionAdvanced?: (val: string) => void,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return configurationData?.data?.items?.map((item: any, i: number) => ({
    title: (
      <div
        key={i}
        onClick={
          allowExpertLevel
            ? () => setDetailsModalOpen({
              isOpen: true,
              name: item.configuration_item?.data?.attributes?.display_name,
              description: item.configuration_item?.data?.attributes?.description,
              image: item.configuration_item?.data?.attributes?.main_image_url
            })
            : () => {
                return;
              }
        }
        className="cell__inner flex justify-between items-center overflow-hidden cursor-pointer select-none h-full">
        <span
          className="flex items-center"
          style={{ width: 'calc(100% - 20px)' }}>
          {item.display_name}
        </span>
        {showImages && (
          <img
            style={{ maxWidth: 100 }}
            src={item.configuration_item?.data?.attributes?.main_image_url}
          />
        )}
        {item.configuration_item?.data?.attributes?.details && (
          <Tooltip title="Нажмите для дополнительной информации">
            <img
              src={questionSvg}
              onClick={() =>
                setOptionAdvanced &&
                setOptionAdvanced(
                  item.configuration_item?.data?.attributes.name
                )
              }
              className="question w-[20px] h-[20px] cursor-pointer hover:opacity-75 active:opacity-50 self-start"
              alt="info"
            />
          </Tooltip>
        )}
      </div>
    ),
    className:
      !allowExpertLevel && item?.name === currentConfig()?.name
        ? // ||
          //   configurationData?.data?.items[
          //     configurationData?.data?.items.length - 1
          //   ]?.configuration_item_id === +currentConfig()?.configuration_item_id
          // || stepData.current_step > stepData.selected_step
          'active option-cell'
        : 'option-cell',
    dataIndex: item.name,
    //width: '10%',

    children: [
      {
        className:
          !allowExpertLevel && item.name === currentConfig()?.name
            ? //  ||
              //   configurationData?.data?.items[
              //     configurationData?.data?.items.length - 1
              //   ]?.configuration_item_id ===
              //     +currentConfig()?.configuration_item_id
              // || stepData.current_step > stepData.selected_step
              'active__child'
            : '', //'active__child',
        title: (
          <strong className="font-bold block text-center">
            {item.current_cost?.toLocaleString('RU-ru')} ₽/шт
          </strong>
        ),
        dataIndex: `${item.name}_val`,
      },
    ],
  }));
};
