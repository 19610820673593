//@ts-nocheck
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BASE_URL,
  TEST_URL,
  // DEFAULT_HEADERS,
  // DEFAULT_TIMEOUT,
} from '@/api/config/api.config';
import { IOrder, IUserAddress, IUserLegal, IUserProfile } from '@/shared';
import { RootState } from '@/services/store';

interface IOrdersResponse {
  data: IOrder[];
}

export interface IOrderIncluded {
  id: string;
  type: string;
  attributes: {
    bunker_image_url: string;
    amount: number;
    name: string;
    options: {
      steps: {
        cost: number;
        name: string;
        internal_name: string;
      }[];
    };
    extra_items: {
      cost: number;
      name: string;
      internal_name: string;
    }[];
    selected_steps: {
      cost: number;
      name: string;
      selected: boolean;
      internal_name: string;
    }[];
  };
}

export interface IOrderByIdResponse {
  data: {
    id: string;
    type: string;
    attributes: IOrder;
    relationships: {
      order_items: {
        data: {
          id: string;
          type: string;
        }[];
      };
      order_histories: {
        data: {
          id: string;
          type: string;
        }[];
      };
    };
  };
  included: IOrderIncluded[];
}
interface IOrderPlaceRequest {
  ids: string;
  address: IUserAddress;
  legal: IUserLegal;
  profile: IUserProfile;
  formItems: IUserLegal['attributes'] & IUserAddress['attributes'];
}

export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: TEST_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      const isAuthorized = (getState() as RootState).auth.isAuthorized;
      if (isAuthorized) {
        headers.delete('Guest-Uuid');
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    placeNewOrder: builder.mutation<IOrderByIdResponse, IOrderPlaceRequest>({
      query: ({ ids, address, legal, profile, formItems }) => {
        const data = {
          region: address.attributes.region,
          city: address.attributes.city,
          address_line: address.attributes.address_line,
          comments: address.attributes.comments,
          name: profile.data.attributes.company,
          actual_address: legal.attributes.actual_address,
          ogrn: legal.attributes.ogrn,
          bik: legal.attributes.bik,
          inn: legal.attributes.inn,
          kpp: legal.attributes.kpp,
          corr_account_number: legal.attributes.corr_account_number,
          okpo: legal.attributes.okpo,
          account_number: legal.attributes.account_number,
          ...formItems,
        };
        Object.keys(data).forEach(
          (key) =>
            (data[key] =
              data[key] === undefined || (data[key] === null ? '' : data[key]))
        );
        return {
          url: `/orders/place?shopping_cart_item_ids=${ids}`,
          credentials: 'include',
          method: 'POST',
          body: {
            data,
          },
        };
      },
    }),
    getOrders: builder.query<IOrdersResponse, string>({
      query: (session) => ({
        // url: `/orders/place?shopping_cart_item_ids=${ids}`,
        url: `/orders`,
        headers: { 'Guest-Uuid': session },
        credentials: 'include',
      }),
    }),
    getOrderById: builder.query<IOrderByIdResponse, string>({
      query: (id) => ({
        url: `/orders/${id}`,
        credentials: 'include',
      }),
    }),
  }),
});

export const {
  usePlaceNewOrderMutation,
  useGetOrdersQuery,
  useGetOrderByIdQuery,
} = ordersApi;
