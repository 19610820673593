import { Table } from 'antd';
import { OptionSelector } from './option-selector';
import { useEffect, useState } from 'react';
import { IInitialStepState } from '@/feed/model';
import { ISelectedBunker } from '@/configurator/ts/catalog.types';
// import { useSetConfigurationMutation } from '@/services/picker.service';
import { generateCols } from '../helpers';
import { ICurrentConfigItem, IOptionItem, IOptionsData } from '@/feed/ts';
import { generateContent } from '../helpers/generateContent';
import { useSetConfigurationMutation } from '@/services/picker.service';
import { ClientStorageService } from '@/common/services/client-storage.service';
import { DiContainer } from '@/di/di-container';
import {
  SlideGatesModal,
  WeightSystemOptionModal,
  WheelsOptionModal,
} from '../../options';
import Modal from 'antd/es/modal/Modal';
const clientStorageService = DiContainer.get(ClientStorageService);

export const MatrixTable = ({
  data,
  stepData,
  currentItem,
  currentConfig,
  dataSource,
  setDataSource,
  activeConfigItem,
  setActiveConfigItem,
  optionsData,
  refetch,
  showOptionImages,
  allowExpertLevel,
  setConfigurationProfit,
}: IMatrixTable) => {
  const [setConfiguration] = useSetConfigurationMutation();
  const [cols, setCols] = useState<
    {
      className: string;
      title: string;
      dataIndex: string;
      key: string;
      rowSpan: number;
    }[]
  >([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [configurationData, setConfigurationData] = useState<any>({});
  const [optionAdvanced, setOptionAdvanced] = useState('');
  const [detailsModalOpen, setDetailsModalOpen] = useState({
    isOpen: false,
    name: '',
    description: '',
    image: '',
  });

  const setConfigLocal = (val: boolean, itemId: number, configName: string) => {
    if (
      currentConfig().name === configName.replace('_val', '') ||
      allowExpertLevel
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newState: unknown = (dataSource as any).map(
        (item: any, i: number) => {
          if (i === itemId)
            return {
              ...item,
              [configName]: val,
            };
          return item;
        }
      );
      setConfigurationProfit &&
        setConfigurationProfit((prevVal: number) => {
          const currentOptionCost = data?.data.items.find(
            (item) => item.name === configName.replace('_val', '')
          )?.current_cost;

          return val
            ? prevVal + Number(currentOptionCost)
            : prevVal - Number(currentOptionCost);
        });
      setDataSource(newState);
      return;
    } else {
      //notification.warning({ message: 'Настройка другой опции будет доступна на финальном шаге'})
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reformForTable = (dataSource: any) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataSource.map((item: any, i: number) => {
      const updatedItem = { ...item };
      for (const key in updatedItem) {
        if (typeof updatedItem[key] === 'boolean') {
          updatedItem[key] = (
            <OptionSelector
              checked={updatedItem[key]}
              changeState={(val) => {
                setConfigLocal(val, i, key);
              }}
            />
          );
        }
      }
      updatedItem.product = `${i + 1}. ${updatedItem.product}`;

      return updatedItem;
    });

  const applyChanges = async () => {
    let actualState: unknown[] = [];

    setDataSource((currentState: unknown[]) => {
      actualState = currentState;
      return currentState;
    });
    let dataToSend: {
      picker_option_id: number;
      enabled: boolean;
      configuration_item_id: number;
    }[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = actualState.map((item: any) => {
      const selectedKeys = Object.keys(item).filter((key) =>
        key.endsWith('val')
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const selectedObject = selectedKeys.reduce((obj: any, key: string) => {
        obj[key.replaceAll('_val', '')] = item[key];
        return obj;
      }, {});

      return Object.keys(selectedObject).map((key) => ({
        picker_option_id: item.picker_option_id,
        configuration_item_id: optionsData?.data?.items?.find(
          (subItem: IOptionItem) => subItem.name === key
        )?.configuration_item_id,
        enabled: selectedObject[key],
      }));
    });
    dataToSend = result.flat() as {
      picker_option_id: number;
      enabled: boolean;
      configuration_item_id: number;
    }[];
    if (dataToSend?.length > 0) {
      const finalRes = await setConfiguration({
        session: clientStorageService.getUUID(),
        data: dataToSend,
      });
      if ('error' in finalRes) return;
    }
    refetch();
  };

  useEffect(() => {
    if (data) {
      setConfigurationData(data);
      setDataSource(
        generateContent(configurationData, data, currentItem, cols)
      );
    }
  }, [cols, configurationData, currentItem, data, setDataSource]);

  useEffect(() => {
    const newCols = generateCols(
      configurationData,
      currentConfig,
      stepData,
      activeConfigItem,
      setActiveConfigItem,
      applyChanges,
      showOptionImages as boolean,
      allowExpertLevel,
      setDetailsModalOpen,
      setOptionAdvanced
    );
    newCols &&
      setCols([
        {
          title: <div className="items-center">Техника</div>,
          dataIndex: 'product',
          key: 'product',
          rowSpan: 2,
          className: 'font-light align-middle z-100 custom-col',
          fixed: 'left',
        },
        ...newCols,
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    currentConfig,
    configurationData?.data?.items,
    configurationData,
    activeConfigItem,
    setActiveConfigItem,
  ]);

  return (
    <div className="matrix-table overflow-y">
      <Table
        id="matrix-table"
        sticky={true}
        pagination={false}
        className={`mb-3 custom-scrollbar ${allowExpertLevel ? 'expert__table' : null}`}
        columns={cols}
        // rowClassName={ (record, index) => {  console.log(record, index) }   }
        dataSource={reformForTable(dataSource)}
        bordered
        loading={false}
        scroll={
          showOptionImages ? { y: 500, x: cols.length * 240 } : { y: 500 }
        }
        rowClassName="text-center hover:bg-transparent"
        onHeaderRow={() => ({
          className: 'font-[300]',
        })}
        locale={{ emptyText: 'Нет данных' }}
      />
      {optionAdvanced === 'increased_wheel_size' ? (
        <WheelsOptionModal
          visible={true}
          closeFn={() => setOptionAdvanced('')}
        />
      ) : optionAdvanced === 'weight_system' ? (
        <WeightSystemOptionModal
          visible={true}
          closeFn={() => setOptionAdvanced('')}
        />
      ) : optionAdvanced === 'hydraulic_slide_gates' ? (
        <SlideGatesModal visible={true} closeFn={() => setOptionAdvanced('')} />
      ) : null}
      <Modal
        closable={false}
        onCancel={() =>
          setDetailsModalOpen({
            isOpen: false,
            name: '',
            description: '',
            image: '',
          })
        }
        width={800}
        footer={null}
        title={detailsModalOpen.name}
        open={detailsModalOpen.isOpen}>
        <div>
          <div className="flex gap-2">
            <img
              style={{ maxWidth: 400, maxHeight: 400, objectFit: 'contain' }}
              src={detailsModalOpen.image}
            />
            <p>{detailsModalOpen.description}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

interface IMatrixTable {
  data: IOptionsData;
  stepData: IInitialStepState;
  currentItem: ISelectedBunker | null;
  currentConfig: () => ICurrentConfigItem;
  refetch: () => void;
  isApplyOptionLoading: boolean;
  dataSource: unknown;
  setDataSource: (newVal: unknown) => void;
  optionsData: IOptionsData;
  activeConfigItem: number;
  setActiveConfigItem: (value: React.SetStateAction<number>) => void;
  showOptionImages?: boolean;
  allowExpertLevel: boolean;
  setConfigurationProfit?: (val: any) => void;
}
