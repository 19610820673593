import { formatISOtoClassicDate } from '@/common/utils/formatISOtoClassicDate';
import { addSpacesInNumbers } from '@/configurator/helpers/add-spaces-in-numbers';
import { useGetOrderByIdQuery } from '@/order/services';
import { IOrder } from '@/shared';
import { Card, Divider, Button, Collapse } from 'antd';
import { useNavigate } from 'react-router-dom';

interface IProps {
  order: IOrder;
}
export const Order = ({ order }: IProps) => {
  const navigate = useNavigate();
  const modificationOptions = {
    key: 1,
    label: <span className="text-[#7E89A5]">модификация 2</span>,
    children: <div className="addons-prices">модификация 2</div>,
  };
  const options = {
    key: 1,
    label: (
      <p className="text-base font-semibold">Бункер-перегрузчик БП - 22/28</p>
    ),
    children: (
      <Collapse
        className="order-collapse"
        accordion
        bordered={false}
        items={[modificationOptions]}
        expandIconPosition="end"
      />
    ),
  };

  return (
    <Card className="mx-auto mt-5 rounded-lg shadow-lg">
      <div className="mb-4">
        <div className="text-lg font-semibold">
          Заказ №{order.id} от {formatISOtoClassicDate(order.created_at)}
        </div>
        <div className="text-sm">{order.name}</div>
      </div>
      <div className="mb-4 grid grid-cols-2">
        <div className="text-sm flex flex-col">
          <span className="text-[#7E89A5]">статус оплаты</span>
          {order.payment_status === 'unpaid' ? (
            <span className="text-red-500">не оплачен</span>
          ) : (
            <span className="text-green-500">оплачен</span>
          )}
        </div>
      </div>
      {/* <div className="mb-4 grid grid-cols-2">
        <Collapse
          className="order-collapse"
          accordion
          bordered={false}
          items={[options]}
          expandIconPosition="end"
        />
        <div>
          <span>4 шт</span>
          <div className="float-right">
            <div className="text-sm">
              <span>Базовая цена за штуку: 4 305 000 ₽</span>
            </div>
            <div className="text-sm">
              <span>Итого с учетом опций: 15 111 000 ₽</span>
            </div>
          </div>
        </div>
      </div> */}
      <Divider />
      <div className="mb-4">
        <div className="text-sm">
          <span>итого: {addSpacesInNumbers(order.total_amount)} ₽</span>
        </div>
      </div>
      <div className="grid grid-cols-2">
        {/* <div className="mb-4">
          <div className="mb-1 text-sm text-[#7E89A5]">
            <span>доставка</span>
          </div>
          <div className="mb-1 text-sm font-semibold">
            <span>доставка производителем</span>
          </div>
          <div className="mb-1 text-sm">
            <span>25 - 28 февраля 2023</span>
          </div>
        </div> */}
        <div className="mb-4">
          <div className="mb-1 text-sm">
            <span>адрес</span>:
          </div>
          <div className="mb-1 text-sm">
            <span>
              {order.actual_address}, {order.region}, {order.city},{' '}
              {order.address_line}
            </span>
          </div>
        </div>
      </div>
      <Button
        size="large"
        type="primary"
        onClick={() => navigate(`/order_list/${order.id}`)}>
        Открыть заказ
      </Button>
    </Card>
  );
};
