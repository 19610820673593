import { DiContainerInjectFn } from '@/di/ts';
import { ConfiguratorService } from '@/configurator/services/configurator.service';

const injectFn: DiContainerInjectFn = (container) => {
  container
    .bind(ConfiguratorService)
    .to(ConfiguratorService)
    .inSingletonScope();
};
export default injectFn;
